import {
	DefaultButton,
	IDropdownOption,
	Panel,
	PrimaryButton,
} from '@fluentui/react';
import {ControlledDropdown} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	useGetRegulationsFormDataQuery,
	useGetRegulatoryDocumentsFormDataLazyQuery,
} from 'features/RegulatoryDocuments/hooks/useGetCompareVersionFormData.generated';
import {useCommand} from 'hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {UserRole} from 'types';
import {CompareVersionFormTooltipTranslationProvider} from './CompareVersionFormTooltipTranslationProvider';
import {ProviderThatEnablesGettingTooltipsFromContext} from 'features/localizedTooltips';
import {RegulatoryDetailsPageFormMode} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.types';
import {ParagraphFieldsSortingService} from 'features/RegulatoryDocuments/RegDocDetailsPage/ParagraphFieldsSorting.service';
import {RegDocOfRegDocsFormData} from './CompareVersionForm.types';

export const CompareversionForm = (props: any) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionForm',
	});

	const [_, setValue] = React.useState('');

	const handleSideBySideChange = (value: any) => {
		const newValue = value;
		setValue(newValue);
		props.onValueChange(newValue);
	};

	const {data: regulations, loading: loadingRegulations} =
		useGetRegulationsFormDataQuery();

	const [loadRegDocs, {data, loading}] =
		useGetRegulatoryDocumentsFormDataLazyQuery();

	const regulationItems = React.useMemo(
		() =>
			regulations?.regulations.map(r => {
				return {
					key: r.id,
					text: r.name,
				} as IDropdownOption;
			}) || [],
		[regulations],
	);

	const regulatoryDocumentItems = React.useMemo(
		() =>
			data?.regulation?.regulatoryDocuments?.map(r => {
				return {
					key: r.id,
					text: r.name,
				} as IDropdownOption;
			}) || [],
		[data],
	);

	const [mode, setMode] = React.useState(RegulatoryDetailsPageFormMode.None);

	useCommand(
		{
			key: 'compareVersion',
			text: t('CompareVersionButton'),
			priority: 4,
			iconProps: {
				iconName: 'Add',
			},
			onClick: () => setMode(RegulatoryDetailsPageFormMode.Compare),
			roles: [UserRole.SystemAdministrator],
		},
		[mode],
	);

	const handleCancelClick = React.useCallback(() => {
		setMode(RegulatoryDetailsPageFormMode.None);
		reset({});
	}, [mode]);

	interface FormFields {
		regulatoryDocuments: unknown;
	}

	const {handleSubmit, control, reset} = useForm<FormFields>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});

	const renderFooterContent: any = () => (
		<form>
			<PrimaryButton onClick={handleClick} styles={buttonStyles}>
				{t('Compare')}
			</PrimaryButton>
			<DefaultButton onClick={handleCancelClick}>{t('Cancel')}</DefaultButton>
		</form>
	);

	type PossibleRegDoc = RegDocOfRegDocsFormData | undefined;

	const findRegDoc = (item: FormFields): PossibleRegDoc => {
		return data?.regulation?.regulatoryDocuments?.find(
			e => e.id === item.regulatoryDocuments,
		);
	};

	const findRegDocAndSortIt = (item: FormFields): PossibleRegDoc => {
		const regDocMatch: PossibleRegDoc = findRegDoc(item);
		const sortingService = new ParagraphFieldsSortingService();
		if (regDocMatch) return sortingService.sortParagraphsInRegDoc(regDocMatch);
		return regDocMatch;
	};

	const handleClick = React.useCallback(() => {
		handleSubmit((item: FormFields) => {
			const regulatoryDocument: PossibleRegDoc = findRegDocAndSortIt(item);
			handleSideBySideChange(regulatoryDocument);
		})();

		setMode(RegulatoryDetailsPageFormMode.None);
	}, [mode, data]);

	return (
		<Panel
			isLightDismiss
			isFooterAtBottom={true}
			onDismiss={handleCancelClick}
			isOpen={mode === RegulatoryDetailsPageFormMode.Compare}
			onRenderFooterContent={renderFooterContent}
			headerText={t('CompareVersionPanelHeader')}
		>
			<LoadWrapper loading={loadingRegulations || loading}>
				<CompareVersionFormTooltipTranslationProvider>
					<ProviderThatEnablesGettingTooltipsFromContext>
						<ControlledDropdown
							label={t('Regulation')}
							name={'regulations'}
							control={control}
							options={regulationItems}
							onChange={(_, item) => {
								if (item && item.key) {
									loadRegDocs({variables: {regulationId: item.key as string}});
								}
							}}
						/>
						<ControlledDropdown
							label={t('RegulatoryDocumentVersion')}
							name={'regulatoryDocuments'}
							control={control}
							options={regulatoryDocumentItems}
						/>
					</ProviderThatEnablesGettingTooltipsFromContext>
				</CompareVersionFormTooltipTranslationProvider>
			</LoadWrapper>
		</Panel>
	);
};

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};
