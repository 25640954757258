import {Link, List, Stack, Text, useTheme} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getListClassNames} from 'styles';
import {NewsItemsHeader} from './Headers';

type NewsItemsListProps = {halfHeight?: boolean; title?: string};

export const NewsItemsList: React.FC<NewsItemsListProps> = ({
	halfHeight = false,
	title,
}) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});
	const link =
		'https://teams.microsoft.com/l/team/19%3AFAorKuRPa7aFv8u7TaCNSQuM_UJ0Euf0wlUYuSptuGY1%40thread.tacv2/conversations?groupId=acaa0e4a-8ed6-4aff-95bc-b8c70f8bf164&tenantId=56564e0f-83d3-4b52-92e8-a6bb9ea36564';

	const onRenderCell = (): JSX.Element => {
		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Stack horizontal>
						<Text variant='large'>
							{t('LinkToTeams')}
							<br></br>
							<Link href={link} target='_blank'>
								Link
							</Link>
						</Text>
					</Stack>
				</div>
			</div>
		);
	};

	return (
		<div className={classNames.activityContainer}>
			<NewsItemsHeader halfHeight title={title} />
			<div className={classNames.container} data-is-scrollable>
				{onRenderCell()}
			</div>
		</div>
	);
};
