import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllMarketsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllMarketsQuery = { __typename?: 'Query', markets?: Array<{ __typename?: 'Market', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, sortPriority?: number | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, translations?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null, regulationNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }>, regulatoryDocumentNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }>, countries: Array<{ __typename?: 'Country', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> }> | null };

export type CreateMarketMutationVariables = Types.Exact<{
  input: Types.CreateMarketInput;
}>;


export type CreateMarketMutation = { __typename?: 'Mutation', createMarket: { __typename?: 'CreateMarketPayload', market?: { __typename?: 'Market', id: string } | null } };

export type UpdateMarketMutationVariables = Types.Exact<{
  input: Types.UpdateMarketInput;
}>;


export type UpdateMarketMutation = { __typename?: 'Mutation', updateMarket: { __typename?: 'UpdateMarketPayload', market?: { __typename?: 'Market', id: string } | null } };

export type DeleteMarketMutationVariables = Types.Exact<{
  input: Types.DeleteMarketInput;
}>;


export type DeleteMarketMutation = { __typename?: 'Mutation', deleteMarket: { __typename?: 'DeleteMarketPayload', market?: { __typename?: 'Market', id: string } | null } };


export const GetAllMarketsDocument = gql`
    query GetAllMarkets {
  markets {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    translations {
      key
      value
    }
    regulationNamingTemplates {
      template
      description
    }
    regulatoryDocumentNamingTemplates {
      template
      description
    }
    countries {
      id
      name
      createdAt
      createdBy {
        givenName
        id
        mail
        name
        surname
        userPrincipalName
      }
      modifiedAt
      modifiedBy {
        givenName
        id
        mail
        name
        surname
        userPrincipalName
      }
    }
    sortPriority
  }
}
    `;

/**
 * __useGetAllMarketsQuery__
 *
 * To run a query within a React component, call `useGetAllMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMarketsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMarketsQuery, GetAllMarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMarketsQuery, GetAllMarketsQueryVariables>(GetAllMarketsDocument, options);
      }
export function useGetAllMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMarketsQuery, GetAllMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMarketsQuery, GetAllMarketsQueryVariables>(GetAllMarketsDocument, options);
        }
export type GetAllMarketsQueryHookResult = ReturnType<typeof useGetAllMarketsQuery>;
export type GetAllMarketsLazyQueryHookResult = ReturnType<typeof useGetAllMarketsLazyQuery>;
export type GetAllMarketsQueryResult = Apollo.QueryResult<GetAllMarketsQuery, GetAllMarketsQueryVariables>;
export const CreateMarketDocument = gql`
    mutation CreateMarket($input: CreateMarketInput!) {
  createMarket(input: $input) {
    market {
      id
    }
  }
}
    `;
export type CreateMarketMutationFn = Apollo.MutationFunction<CreateMarketMutation, CreateMarketMutationVariables>;

/**
 * __useCreateMarketMutation__
 *
 * To run a mutation, you first call `useCreateMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketMutation, { data, loading, error }] = useCreateMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarketMutation, CreateMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarketMutation, CreateMarketMutationVariables>(CreateMarketDocument, options);
      }
export type CreateMarketMutationHookResult = ReturnType<typeof useCreateMarketMutation>;
export type CreateMarketMutationResult = Apollo.MutationResult<CreateMarketMutation>;
export type CreateMarketMutationOptions = Apollo.BaseMutationOptions<CreateMarketMutation, CreateMarketMutationVariables>;
export const UpdateMarketDocument = gql`
    mutation UpdateMarket($input: UpdateMarketInput!) {
  updateMarket(input: $input) {
    market {
      id
    }
  }
}
    `;
export type UpdateMarketMutationFn = Apollo.MutationFunction<UpdateMarketMutation, UpdateMarketMutationVariables>;

/**
 * __useUpdateMarketMutation__
 *
 * To run a mutation, you first call `useUpdateMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketMutation, { data, loading, error }] = useUpdateMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMarketMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketMutation, UpdateMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketMutation, UpdateMarketMutationVariables>(UpdateMarketDocument, options);
      }
export type UpdateMarketMutationHookResult = ReturnType<typeof useUpdateMarketMutation>;
export type UpdateMarketMutationResult = Apollo.MutationResult<UpdateMarketMutation>;
export type UpdateMarketMutationOptions = Apollo.BaseMutationOptions<UpdateMarketMutation, UpdateMarketMutationVariables>;
export const DeleteMarketDocument = gql`
    mutation DeleteMarket($input: DeleteMarketInput!) {
  deleteMarket(input: $input) {
    market {
      id
    }
  }
}
    `;
export type DeleteMarketMutationFn = Apollo.MutationFunction<DeleteMarketMutation, DeleteMarketMutationVariables>;

/**
 * __useDeleteMarketMutation__
 *
 * To run a mutation, you first call `useDeleteMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMarketMutation, { data, loading, error }] = useDeleteMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMarketMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMarketMutation, DeleteMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMarketMutation, DeleteMarketMutationVariables>(DeleteMarketDocument, options);
      }
export type DeleteMarketMutationHookResult = ReturnType<typeof useDeleteMarketMutation>;
export type DeleteMarketMutationResult = Apollo.MutationResult<DeleteMarketMutation>;
export type DeleteMarketMutationOptions = Apollo.BaseMutationOptions<DeleteMarketMutation, DeleteMarketMutationVariables>;