import {Theme, useTheme} from '@fluentui/react';

import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
	RegDocsListDetailsRow,
	RegDocsListDetailsRowProps,
	RegDocsListDetailsRowStyles,
} from './RegDocsList.DetailsRow';

type Props = Pick<RegDocsListDetailsRowProps, 'propsFromRenderer'>;

export function RegDocsListDisabledDetailsRow(props: Props) {
	const theme: Theme = useTheme();

	const {t} = useTranslation('features/RegDocs/regDocsList');

	const extraStyles: RegDocsListDetailsRowStyles = useMemo(() => {
		return {
			root: {
				background: theme.palette.neutralLighterAlt,
			},
		};
	}, [theme.palette.neutralLighterAlt]);

	return (
		<div title={t('TooltipsText')}>
			<RegDocsListDetailsRow {...props} extraStyles={extraStyles} />
		</div>
	);
}
