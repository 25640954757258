import {useUserContext} from 'authentication/UserContext';
import {GetHeadlessRegulatoryDocumentsDocument} from 'features/HeadlessRegulatoryDocuments/hooks/headlessRegulatoryDocuments.generated';
import {GetRegulationDocument} from 'features/Regulations/hooks';
import {useCommand} from 'hooks';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Market, UserRole, WorkflowStatus} from 'types';
import {useRegulatoryDocumentsContext} from '../context';
import {GetWorkbenchItemsDocument} from 'features/Dashboard/hooks/workbenchItems.generated';
import {
	RegulatoryDocumentsForm,
	RegulatoryDocumentsFormProps,
} from './RegulatoryDocumentsForm/RegulatoryDocumentsForm';
import {
	RegulatoryDocumentFormInfo,
	useRegulatoryDocumentForm,
} from './RegulatoryDocumentsForm/useRegulatoryDocumentForm';
import {RegulatoryDocumentFormMode} from './RegulatoryDocumentsForm/useRegulatoryDocumentFormMode';
import {
	EditRegulatoryDocCmdOptions,
	getIfDocHasUneditableStatus,
	useEditRegulatoryDocCmd,
} from '../useEditRegulatoryDocCmd';
import {
	isRegDocEditDisabled,
	getDisabledEditionForMOdifiedVersionTooltip,
	isPogisDocument,
} from '../regulatoryUtils';

export enum RegulatoryDocumentFormOperation {
	Create,
	Update,
	View,
	Delete,
	Copy,
}

const operationsAsArray: RegulatoryDocumentFormOperation[] = Object.keys(
	RegulatoryDocumentFormOperation,
)
	.filter(key =>
		isNaN(
			Number(
				RegulatoryDocumentFormOperation[
					key as keyof typeof RegulatoryDocumentFormOperation
				],
			),
		),
	)
	.map(k => Number(k));

export interface RegulatoryDocumentsListDocumentFormProps
	extends Pick<
			RegulatoryDocumentsFormProps,
			'startPolling' | 'regulationId' | 'regulatoryDocuments'
		>,
		Pick<EditRegulatoryDocCmdOptions, 'stopPolling'> {
	operations?: RegulatoryDocumentFormOperation[];
	dashboard?: boolean;
	markets: Market[];
	regulationNumber?: string;
}

export const RegulatoryDocumentsListDocumentForm = ({
	regulationId,
	operations = operationsAsArray,
	dashboard = false,
	markets,
	regulationNumber,
	stopPolling,
	...other
}: RegulatoryDocumentsListDocumentFormProps) => {
	const formInfo: RegulatoryDocumentFormInfo = useRegulatoryDocumentForm();
	const {
		modeStateInfo: {setMode, mode},
		documentChangeStateInfo: {setDidDocumentChange},
		dialogStateInfo: {showDialog},
	} = formInfo;

	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'RegulatoryDocumentsForm',
	});

	const {selectedRegulatoryDocument, allSelectedRegulatoryDocuments} =
		useRegulatoryDocumentsContext();

	const {isAdmin, username, encodedUserId} = useUserContext();

	/**
	 * Commands
	 */
	useCommand(
		{
			hidden: !operations.includes(RegulatoryDocumentFormOperation.Create),
			key: 'add',
			text:
				selectedRegulatoryDocument &&
				operations.includes(RegulatoryDocumentFormOperation.Copy)
					? t('Copy')
					: t('New'),
			priority: 1,
			iconProps: {
				iconName: 'Add',
			},
			onClick() {
				if (selectedRegulatoryDocument) {
					setMode(RegulatoryDocumentFormMode.Copy);
				} else {
					setMode(RegulatoryDocumentFormMode.Create);
				}

				stopPolling?.();
			},
			roles: dashboard
				? [UserRole.SystemAdministrator]
				: [UserRole.SystemAdministrator, UserRole.Vko, UserRole.VkoExternal],
			disabled:
				(allSelectedRegulatoryDocuments ?? []).length > 1 ||
				isPogisDocument(selectedRegulatoryDocument) ||
				(Boolean(selectedRegulatoryDocument) &&
					!operations.includes(RegulatoryDocumentFormOperation.Copy)),
		},
		[
			stopPolling,
			mode,
			selectedRegulatoryDocument,
			allSelectedRegulatoryDocuments,
		],
	);

	const getIfSelectedMultipleDocs = (): boolean => {
		return (allSelectedRegulatoryDocuments ?? []).length > 1;
	};

	const isRegDocDisabled = useMemo(() => {
		if (!selectedRegulatoryDocument) return true;
		const getHasInvalidStatus = getIfDocHasUneditableStatus;
		const hasInvalidStatus: boolean = getHasInvalidStatus(
			selectedRegulatoryDocument,
			isAdmin,
			username,
		);
		const selectedMultiple: boolean = getIfSelectedMultipleDocs();
		return (
			selectedMultiple ||
			hasInvalidStatus ||
			isPogisDocument(selectedRegulatoryDocument) ||
			isRegDocEditDisabled(selectedRegulatoryDocument, encodedUserId)
		);
	}, [
		encodedUserId,
		getIfSelectedMultipleDocs,
		isAdmin,
		selectedRegulatoryDocument,
		username,
	]);

	useEditRegulatoryDocCmd({
		setDidDocumentChange,
		setMode,
		stopPolling,
		hidden: !operations.includes(RegulatoryDocumentFormOperation.Update),
		key: 'edit',
		text: t('Edit'),
		title: getDisabledEditionForMOdifiedVersionTooltip(
			selectedRegulatoryDocument,
			t,
		),
		priority: 2,
		disabled: isRegDocDisabled,
		extraDependencies: [
			mode,
			selectedRegulatoryDocument,
			allSelectedRegulatoryDocuments,
			isAdmin,
		],
	});
	useCommand(
		{
			hidden:
				!operations.includes(RegulatoryDocumentFormOperation.Delete) ||
				(!isAdmin &&
					!(
						selectedRegulatoryDocument?.workflow?.status ===
							WorkflowStatus.Modified &&
						(selectedRegulatoryDocument?.createdBy?.id === encodedUserId ||
							selectedRegulatoryDocument?.createdBy?.id === encodedUserId)
					)),
			key: 'delete',
			text: t('Delete'),
			priority: 3,
			iconProps: {
				iconName: 'Delete',
			},
			onClick: () => showDialog(),
			disabled:
				!allSelectedRegulatoryDocuments?.length ||
				allSelectedRegulatoryDocuments?.length > 1,
			roles: [UserRole.SystemAdministrator, UserRole.Vko],
		},
		[allSelectedRegulatoryDocuments],
	);

	const refetchQueries = React.useMemo(
		() =>
			regulationId
				? [GetRegulationDocument, GetWorkbenchItemsDocument]
				: [GetHeadlessRegulatoryDocumentsDocument],
		[],
	);

	return (
		<RegulatoryDocumentsForm
			regulatoryDocument={selectedRegulatoryDocument}
			queriesToRefetchAfterSave={refetchQueries}
			regulationId={regulationId}
			markets={markets}
			regulationNumber={regulationNumber ?? ''}
			{...other}
			{...formInfo}
		/>
	);
};
