import {
	Breadcrumb,
	IBreadcrumbItem,
	IDropdownOption,
	IRawStyle,
	ITextStyles,
	Icon,
	IconButton,
	SelectionMode,
	Stack,
	Sticky,
	StickyPositionType,
	Text,
	mergeStyles,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {useUserContext} from 'authentication/UserContext';
import {
	DetailsField,
	DetailsSection,
	HEIGHT_OF_LIST_HEADER,
	PdfViewer,
} from 'components';
import {AuditLogsLoadingStatusProvider} from 'components/ChangeHistory/AuditLogsLoadingStatusProvider';
import {CommentForm} from 'components/CommentForm';
import {CommentLevel} from 'components/CommentList';
import {FAQForm} from 'components/FAQForm';
import {FAQLevel} from 'components/FAQList';
import {useSortRegulatoryDocs} from 'features/Regulations/components/useSortRegulatoryDocs';
import {RegDocParagraphTooltipTranslationProvider} from 'features/localizedTooltips';
import {mapToRef} from 'helpers';
import {
	StateForShouldShowAudit,
	useCommand,
	useFavoriteCommand,
	useShouldShowAudit,
} from 'hooks';
import {useToggleAuditCmd} from 'hooks/audit/useToggleAuditCmd';
import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {TFunction, useTranslation} from 'react-i18next';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
	CorrectStickyType,
	DocumentStatus,
	Market,
	RegulatoryDocument,
	RegulatoryDocumentParagraph,
	UserRole,
	Workflow,
	WorkflowStatus,
} from 'types';
import ParagraphsComparisonPanel from '../ParagraphsComparisonPanel';
import {SidePaneForParagraphs} from '../SidePaneForParagraphs';
import {CompareVersionPanel} from '../components/DocumentCompare';
import {
	CompareversionForm,
	EditParagraphsForm,
	KeywordAssignmentForm,
	ParagraphTableOfContents,
	RemoveRequirementsForm,
	ViewParagraphsForm,
	WorkflowDialog,
} from '../components/DocumentDetails';
import {AssignRequirementsForm} from '../components/DocumentDetails/AssignRequirementsForm';
import {
	ParagraphsContextProvider,
	useParagraphsContext,
} from '../components/DocumentDetails/ParagraphsContext';
import {RegulatoryDocumentsForm} from '../components/RegulatoryDocumentsForm/RegulatoryDocumentsForm';
import {
	RegulatoryDocumentFormInfo,
	useRegulatoryDocumentForm,
} from '../components/RegulatoryDocumentsForm/useRegulatoryDocumentForm';
import {
	EditorSelectionContextProvider,
	RegulatoryDocumentsContextProvider,
} from '../context';
import {useAssignRegulatoryDocumentParagraphsTagsMutation} from '../hooks/useAssginRegulatoryDocumentParagraphsTag.generated';
import {useAssignRegulatoryDocumentParagraphsRequirementsMutation} from '../hooks/useAssignRegulatoryDocumentParagraphsRequirement.generated';
import {
	GetRegulatoryDocumentDetailsDocument,
	GetRegulatoryDocumentDetailsQuery,
	GetVexRegulatoryDocumentParagraphsQuery,
	useGetRegulatoryDocumentDetailsQuery,
	useGetVexRegulatoryDocumentParagraphsQuery,
} from '../hooks/useGetRegulatoryDocumentDetails.generated';
import {useUpdateRegulatoryDocumentWorkflowStatusMutation} from '../hooks/useUpdateRegulatoryDocumentWorkflowStatus.generated';
import {useCreateInterimRequirementMutation} from '../hooks/useCreateInterimRequirment.generated';
import {getStackHeight} from '../paragraphsComparisonStyles';
import {
	getIfDocHasUneditableStatus,
	useEditRegulatoryDocCmd,
} from '../useEditRegulatoryDocCmd';
import {
	EditParagraphsFormSubmissionStatusContext,
	EditParagraphsFormSubmissionStatusInfo,
	EditParagraphsFormSubmissionStatusProvider,
} from './EditParagraphsFormSubmissionStatusProvider';
import {RegDocDetailsPageLoadWrapper} from './LoadWrapper/RegDocDetailsPage.LoadWrapper';
import {ParagraphFieldsSortingService} from './ParagraphFieldsSorting.service';
import {RegDocDetailsPageParagraphsList} from './RegDocDetailsPage.ParagraphsList';
import {useRegDocDetailsPageTranslations} from './RegDocDetailsPage.commonHooks';
import {
	CLASS_TO_ADD_TO_PARAGRAPHS_SECTION_HEADER_WHEN_STICKY,
	HEIGHT_OF_PARAGRAPHS_SECTION_HEADER,
} from './RegDocDetailsPage.constants';
import {RegDocDetailsPageInfoProvider} from './RegDocDetailsPage.context';
import {DocOfRegDocDetailsPageQuery} from './RegDocDetailsPage.queryTypes';
import {RefToContainerOfMainParagraphs} from './RegDocDetailsPage.types';
import {RegDocSidebarInfoProvider} from './RegDocSidebarInfoProvider';
import {ViewPdfButton} from './ViewPdfButton';
import {
	RegDocAuditLogsInfo,
	useGetRegDocAuditLogs,
} from './useGetRegDocAuditLogs';
import {
	getCorrectStatusName,
	getDisabledEditionForMOdifiedVersionTooltip,
	isRegDocEditVersionDataDisabled,
} from '../regulatoryUtils';
import {DocumentSourceEnum} from 'features/Regulations/RegulationDetail/RegulationDetail.types';
import {EntityListContextProvider} from 'components/EntityList/EntityListContext';
import {ParagraphGridView} from '../components/GridView/ParagraphGridView';

export const RegulatoryDocumentDetailsPage: React.FC = () => {
	return (
		<ParagraphsContextProvider>
			<EditorSelectionContextProvider>
				<EditParagraphsFormSubmissionStatusProvider>
					<RegulatoryDocumentDetailsPageComponent />
				</EditParagraphsFormSubmissionStatusProvider>
			</EditorSelectionContextProvider>
		</ParagraphsContextProvider>
	);
};

const useEnumTranslation = (keyPrefix: string) => {
	return useTranslation('common/enums', {keyPrefix});
};

// eslint-disable-next-line complexity
const RegulatoryDocumentDetailsPageComponent: React.FC = () => {
	/**
	 * Translations
	 */
	const {t} = useRegDocDetailsPageTranslations();
	const {t: getWorkflowStatus} = useEnumTranslation('WorkflowStatus');
	const {t: getStatusTranslation} = useEnumTranslation(
		'RegulatoryDocumentStatus',
	);

	const [updateRegulatoryDocumentWorkflowStatus] =
		useUpdateRegulatoryDocumentWorkflowStatusMutation();
	const [createInterimRequirement] = useCreateInterimRequirementMutation();
	/**
	 * Other hooks
	 */
	const [isFormRefetchingRegDoc, setIsFormRefetchingRegDoc] =
		useState<boolean>(false);
	const navigate = useNavigate();
	const {isVex, isVko, myVexClusters, isAdmin, username, encodedUserId} =
		useUserContext();

	const myVexClusterIds = myVexClusters.flatMap(c => c.id);

	const [sideBySide, setSideBySide] = React.useState<RegulatoryDocument>();
	const handleSideBySideChange = (newValue: RegulatoryDocument) => {
		setSideBySide(newValue);
	};

	const [viewPdf, {toggle: toggleViewPdf}] = useBoolean(false);
	const [viewGrid, setViewGrid] = useState(false);

	const [tocIsOpen, {setFalse: hideToc, toggle: toggleToc}] = useBoolean(false);
	const {
		textStyles,
		stackStyles,
		stackshit,
		mainParagraphsWrapper,
		pdfParagraphsListWrapper,
	} = getStyles(Boolean(sideBySide) || viewPdf);

	const stateForShouldShowAudit: StateForShouldShowAudit = useShouldShowAudit();
	const [showAudit] = stateForShouldShowAudit;

	const {regulatoryDocumentId: docIdWithoutType} = useParams();
	const docId = docIdWithoutType as string;

	const {
		loading: isRegDocLoading,
		data,
		networkStatus: regDocNetworkStatus,
	} = useGetRegulatoryDocumentDetailsQuery({
		variables: {regulatoryDocumentId: docId},
		notifyOnNetworkStatusChange: true,
	});

	const [searchParams] = useSearchParams();

	const filter = searchParams.get('filter') ?? '';

	const refToContainerOfMainParagraphs: RefToContainerOfMainParagraphs =
		useRef(null);

	/**
	 * Queries
	 */

	const [assignRegulatoryDocumentParagraphsRequirements] =
		useAssignRegulatoryDocumentParagraphsRequirementsMutation();

	const [assignRegulatoryDocumentParagraphsTags] =
		useAssignRegulatoryDocumentParagraphsTagsMutation();

	const {
		data: vexItems,
		loading: vexItemsLoading,
		networkStatus: vexItemsNetworkStatus,
	} = useGetVexRegulatoryDocumentParagraphsQuery({
		variables: {regulatoryDocumentId: docId, inProcess: filter === 'inProcess'},
		notifyOnNetworkStatusChange: true,
	});

	const {
		auditLogs,
		refetchAuditLogsAndUpdateIsLoading,
		isLoading: areAuditLogsLoading,
		setAreAuditLogsLoading,
	}: RegDocAuditLogsInfo = useGetRegDocAuditLogs(docId);

	/**
	 * Other
	 */
	const {sortParagraphsInRegDoc, sortParagraphs} =
		new ParagraphFieldsSortingService();

	type VexRegDocParagraphs =
		GetVexRegulatoryDocumentParagraphsQuery['vexRegulatoryDocumentParagraphs'];

	const getVexWorkbenchItems = (): VexRegDocParagraphs => {
		const paragraphs: VexRegDocParagraphs =
			vexItems?.vexRegulatoryDocumentParagraphs ?? [];
		return sortParagraphs(paragraphs);
	};

	const vexWorkbenchItems: VexRegDocParagraphs = React.useMemo(
		getVexWorkbenchItems,
		[sortParagraphs?.length, vexItems?.vexRegulatoryDocumentParagraphs],
	);

	const [showWorkbenchItems, setShowWorkbenchItems] = React.useState(
		(isVex && vexWorkbenchItems.length > 0) ?? false,
	);

	const getRegulatoryDocumentDetails = () => {
		type RegDoc = GetRegulatoryDocumentDetailsQuery['regulatoryDocument'];
		const regDoc: RegDoc = data?.regulatoryDocument;
		if (regDoc) return sortParagraphsInRegDoc(regDoc);
		return {} as RegulatoryDocument;
	};

	const regulatoryDocumentDetails = React.useMemo(
		getRegulatoryDocumentDetails,
		[data],
	);

	const comments = React.useMemo(
		() => regulatoryDocumentDetails.comments,
		[regulatoryDocumentDetails],
	);

	const faqs = React.useMemo(
		() => regulatoryDocumentDetails.faqs,
		[regulatoryDocumentDetails],
	);

	const isPogisDocument = React.useMemo(
		() =>
			regulatoryDocumentDetails.documentSource?.name?.toLowerCase() ===
			DocumentSourceEnum.Pogis.toLowerCase(),
		[regulatoryDocumentDetails],
	);

	const {
		selectedParagraphs,
		setSelectedParagraphs,
		secondSelectedParagraphs,
		filteredParagraphs,
		setIndexToScrollTo,
	} = useParagraphsContext();

	React.useEffect(() => {
		setShowWorkbenchItems(isVex && vexWorkbenchItems.length > 0);
	}, [isVex, vexWorkbenchItems]);

	const paragraphs = React.useMemo(
		() =>
			showWorkbenchItems
				? (vexWorkbenchItems as RegulatoryDocumentParagraph[])
				: (regulatoryDocumentDetails.paragraphs as RegulatoryDocumentParagraph[]),
		[
			regulatoryDocumentDetails.paragraphs,
			showWorkbenchItems,
			vexWorkbenchItems,
		],
	);

	const paragraphListItems = React.useMemo(
		() => (filteredParagraphs.length === 0 ? paragraphs : filteredParagraphs),
		[filteredParagraphs, paragraphs],
	);

	// We need this in order to update the SelectedParagraphs if there are changes in the data
	React.useEffect(() => {
		if (!paragraphListItems) return;
		const selectedParagraphsIds = selectedParagraphs.map(x => x.id);
		const selectedParagraphsUpdatedValues = paragraphListItems.filter(
			x => selectedParagraphsIds.indexOf(x.id) >= 0,
		);
		setSelectedParagraphs(selectedParagraphsUpdatedValues);
	}, [paragraphListItems]);

	const allBreadCrumbItems: IBreadcrumbItem[] = [
		{
			text: t('Regulations'),
			key: 'regulations',
			href: '/regulations',
		},
		{
			text: `${regulatoryDocumentDetails?.regulation?.regulationNumber}`,
			key: 'regulatoryDocument',
			href: `/regulations/${regulatoryDocumentDetails?.regulation?.id}`,
		},
		{
			text: `${regulatoryDocumentDetails?.name}`,
			key: 'name',
		},
	];

	const headlessBreadCrumbItems: IBreadcrumbItem[] = [
		{
			text: t('RegulatoryDocuments'),
			key: 'regulatoryDocuments',
		},
		{
			text: `${regulatoryDocumentDetails?.name}`,
			key: 'name',
		},
	];

	const findDocumentValue = <
		Key extends keyof typeof regulatoryDocumentDetails,
	>(
		key: Key,
	): RegulatoryDocument[Key] | undefined => {
		return regulatoryDocumentDetails?.[key];
	};

	const getStatusValue = (): DetailsField['data'] => {
		const status: RegulatoryDocument['status'] | undefined =
			findDocumentValue('status');
		return status ? getStatusTranslation(status) : null;
	};

	interface FieldThatSupportsHistory extends DetailsField {
		auditFieldName: string;
		audit: boolean;
	}

	const createFieldThatSupportsHistory = (
		field: Omit<FieldThatSupportsHistory, 'audit'>,
	): FieldThatSupportsHistory => {
		return {...field, audit: showAudit};
	};

	const {control: WorkflowStatusControl} = useForm<Workflow>({
		reValidateMode: 'onSubmit',
		mode: 'onBlur',
	});

	const handleWorkflowStatusChange = useCallback(
		async (
			_event: React.FormEvent<HTMLDivElement>,
			option?: IDropdownOption,
		) => {
			if (option === undefined) {
				return;
			}

			await updateRegulatoryDocumentWorkflowStatus({
				variables: {
					regulatoryDocumentId: regulatoryDocumentDetails.id,
					workflowStatus: option.key as WorkflowStatus,
				},
			})
				.then(() => {
					if (option.key === WorkflowStatus.Finalized) {
						createInterimRequirement({
							variables: {regulatoryDocumentId: regulatoryDocumentDetails.id},
						});
					}
				})
				.then(() => window.location.reload());
		},
		[updateRegulatoryDocumentWorkflowStatus, regulatoryDocumentDetails.id],
	);

	const reapprovingHistoryMessages = React.useMemo(
		() =>
			auditLogs
				.filter(
					log =>
						log.change.key === 'reapprovingMessage' &&
						log.change.value &&
						['ADD', 'UPDATE'].indexOf(log.change.type) !== -1,
				)
				.sort((log1, log2) =>
					new Date(log1.createdAt).getTime() <
					new Date(log2.createdAt).getTime()
						? 1
						: -1,
				)
				.map(log => {
					return {
						date: new Date(log.createdAt),
						value: log.change.value,
					};
				}),
		[auditLogs],
	);

	const initialFieldRows: DetailsField[][] = React.useMemo(() => {
		return [
			[
				createFieldThatSupportsHistory({
					data: regulatoryDocumentDetails?.summary,
					type: 'richtext',
					label: t('ShortSummaryLabel'),
					auditFieldName: '$.summary',
				}),
				{
					data: reapprovingHistoryMessages,
					type: 'history',
					label: t('ChangeHistoryLabel'),
					isLoading: areAuditLogsLoading,
				},
			],
			[
				isAdmin
					? createFieldThatSupportsHistory({
							label: t('WorkflowStatus'),
							type: 'enumDropdown',
							data: regulatoryDocumentDetails?.workflow?.status,
							enumProps: {
								name: 'WorkflowStatus',
								enumName: 'WorkflowStatus',
								dropdownWidth: 'auto',
								control: WorkflowStatusControl,
								enumType: WorkflowStatus,
								onChange: handleWorkflowStatusChange,
							},

							auditFieldName: 'workflow.status',
					  })
					: createFieldThatSupportsHistory({
							label: t('WorkflowStatus'),
							type: 'text',
							data: getCorrectStatusName(
								regulatoryDocumentDetails,
								t,
								getWorkflowStatus,
							),
							auditFieldName: 'workflow.status',
					  }),
				createFieldThatSupportsHistory({
					data: getStatusValue(),
					type: 'text',
					label: t('StatusLabel'),
					auditFieldName: '$.status',
				}),
				{
					data: regulatoryDocumentDetails?.documentSource?.name,
					type: 'text',
					label: t('DocumentSourceLabel'),
				},
			],

			[
				{
					data: regulatoryDocumentDetails.attachments,
					type: 'attachments',
					label: t('AttachmentsLabel'),
				},
			],

			[
				{
					data: regulatoryDocumentDetails?.createdAt,
					type: 'date',
					label: t('CreationDateLabel'),
				},
				{
					data: regulatoryDocumentDetails?.modifiedAt,
					type: 'date',
					label: t('ChangeDateLabel'),
				},
				createFieldThatSupportsHistory({
					data: regulatoryDocumentDetails?.modelYear,
					type: 'text',
					label: t('ModelYearLabel'),
					auditFieldName: '$.modelYear',
				}),
				{
					data: regulatoryDocumentDetails?.dateNewTypes,
					type: 'date',
					label: t('NewTypesLabel'),
					disclaimer: t('Disclaimer'),
				},
				{
					data: regulatoryDocumentDetails?.dateNewRegistration,
					type: 'date',
					label: t('NewRegistrationLabel'),
					disclaimer: t('Disclaimer'),
				},
				createFieldThatSupportsHistory({
					data: regulatoryDocumentDetails?.dateEffective,
					type: 'date',
					label: t('EffectiveDateLabel'),
					auditFieldName: 'dateEffective',
				}),
				createFieldThatSupportsHistory({
					data: regulatoryDocumentDetails?.dateExpiration,
					type: 'date',
					label: t('ExpirationDateLabel'),
					auditFieldName: 'dateExpiration',
				}),
			],
		];
	}, [
		regulatoryDocumentDetails,
		showAudit,
		t,
		getWorkflowStatus,
		auditLogs,
		areAuditLogsLoading,
	]);

	const viewMoreFieldRows: DetailsField[][] = React.useMemo(
		() =>
			[
				[
					{
						data: regulatoryDocumentDetails?.phaseIn,
						type: 'phase',
						label: t('PhaseInLabel'),
					},
				],
				[
					{
						data: regulatoryDocumentDetails?.phaseOut,
						type: 'phase',
						label: t('PhaseOutLabel'),
					},
				],
				[
					{
						data: regulatoryDocumentDetails.keywords,
						type: 'array',
						label: t('KeywordsLabel'),
					},
				],
				[
					{
						data: regulatoryDocumentDetails?.documentReferences,
						type: 'references',
						label: t('DocumentReferences'),
					},
				],
			] as any,
		[regulatoryDocumentDetails, t],
	);

	/**
	 * * Commands
	 */
	const {isSubmitting} = useContext(
		EditParagraphsFormSubmissionStatusContext,
	) as EditParagraphsFormSubmissionStatusInfo;

	useCommand(
		{
			key: 'close',
			text: t('CancelSecondVersion'),
			farCommand: true,
			onClick() {
				setSideBySide(undefined);
			},
			iconProps: {
				iconName: 'Cancel',
			},
			hidden: !sideBySide,
		},
		[sideBySide],
	);

	/**
	 * If you wish to pass any props to the button, edit the btn component
	 * directly. If you define props here, they might not be passed
	 */

	useCommand(
		{
			key: 'pdfView',
			farCommand: true,
			priority: 1,
			hidden:
				!regulatoryDocumentDetails.document ||
				isPogisDocument ||
				regulatoryDocumentDetails.documentStatus === DocumentStatus.NotSplit ||
				viewGrid,
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.Vex,
				UserRole.ServiceProvider,
				UserRole.ShApprover,
				UserRole.Readers,
				UserRole.RegulationReader,
			],
			commandBarButtonAs(): JSX.Element {
				return (
					<ViewPdfButton
						viewPdf={viewPdf}
						togglePdf={toggleViewPdf}
						sideBySide={sideBySide}
						doc={regulatoryDocumentDetails.document}
						refToContainerOfMainParagraphs={refToContainerOfMainParagraphs}
						setIndexToScrollTo={setIndexToScrollTo}
					/>
				);
			},
		},
		[
			viewPdf,
			viewGrid,
			sideBySide,
			regulatoryDocumentDetails.document,
			toggleViewPdf,
			refToContainerOfMainParagraphs,
			setIndexToScrollTo,
		],
	);

	useCommand(
		{
			key: 'copyRequirements',
			text: t('CopyRequirements'),
			priority: 7,
			onClick() {
				const myVexClusterRequirements =
					secondSelectedParagraphs[0]?.requirements?.filter(r =>
						r.vexClusters?.find(v => myVexClusterIds?.includes(v.id)),
					);

				assignRegulatoryDocumentParagraphsRequirements({
					variables: {
						input: {
							paragraphIds: selectedParagraphs?.map(sp => sp.id),
							regulatoryDocumentId: docId,
							requirementRefs: mapToRef(
								isVex
									? myVexClusterRequirements
									: secondSelectedParagraphs[0]?.requirements,
							),
						},
					},
					refetchQueries: [GetRegulatoryDocumentDetailsDocument],
				});
			},
			hidden: !sideBySide || isPogisDocument,
			disabled: !(
				selectedParagraphs.length > 0 && secondSelectedParagraphs.length === 1
			),
			iconProps: {
				iconName: 'Copy',
			},
		},
		[sideBySide, selectedParagraphs, secondSelectedParagraphs, isVex],
	);

	useCommand(
		{
			key: 'copyTags',
			text: t('CopyTags'),
			priority: 8,
			onClick() {
				assignRegulatoryDocumentParagraphsTags({
					variables: {
						input: {
							paragraphIds: selectedParagraphs?.map(sp => sp.id),
							regulatoryDocumentId: docId,
							tagRefs: mapToRef(secondSelectedParagraphs[0].tags),
						},
					},
					refetchQueries: [GetRegulatoryDocumentDetailsDocument],
				});
			},
			hidden: !sideBySide,
			disabled:
				!(
					selectedParagraphs.length > 0 && secondSelectedParagraphs.length === 1
				) || isPogisDocument,
			iconProps: {
				iconName: 'Copy',
			},
		},
		[sideBySide, selectedParagraphs, secondSelectedParagraphs],
	);

	useCommand(
		{
			key: 'gridView',
			text: 'in Rasteransicht bearbeiten',
			hidden:
				viewGrid ||
				[
					WorkflowStatus.InProgressInternal,
					WorkflowStatus.InProgressExternal,
					WorkflowStatus.Examination,
					WorkflowStatus.Modified,
				].indexOf(
					regulatoryDocumentDetails.workflow &&
						regulatoryDocumentDetails.workflow.status
						? regulatoryDocumentDetails.workflow.status
						: WorkflowStatus.New,
				) === -1,
			onClick() {
				setViewGrid(true);
			},
			farCommand: true,
			priority: -1,
			roles: [UserRole.SystemAdministrator, UserRole.Vko],
			iconProps: {
				iconName: 'GridViewSmall',
			},
		},
		[viewGrid, regulatoryDocumentDetails],
	);

	useFavoriteCommand(docId, []);

	useCommand(
		{
			key: 'workbenchItems',
			farCommand: true,
			priority: 0,
			onClick() {
				setShowWorkbenchItems(!showWorkbenchItems);
			},
			hidden: !isVex || isPogisDocument,
			iconProps: {iconName: 'Workflow'},
			title: showWorkbenchItems ? t('ShowAllParagraphsTooltip') : '',
		},
		[showWorkbenchItems, isVex],
	);

	useCommand(
		{
			key: 'openDocument',
			text: t('OpenEditor'),
			priority: 5,
			iconProps: {
				iconName: 'OpenFile',
			},
			onClick: () =>
				navigate(
					`/regulations/${regulatoryDocumentDetails?.regulation?.id}/${docIdWithoutType}`,
				),
			hidden:
				(regulatoryDocumentDetails?.workflow?.status !== WorkflowStatus.New &&
					regulatoryDocumentDetails?.workflow?.status !==
						WorkflowStatus.QualityControlInternal &&
					regulatoryDocumentDetails?.workflow?.status !==
						WorkflowStatus.QualityControlExternal) ||
				isPogisDocument ||
				isVko,
			disabled:
				regulatoryDocumentDetails?.workflow?.status ===
				WorkflowStatus.Finalized,
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.ServiceProvider,
			],
		},
		[docIdWithoutType, regulatoryDocumentDetails, navigate],
	);

	useToggleAuditCmd(stateForShouldShowAudit);

	/**
	 * Edit form
	 */
	const formInfo: RegulatoryDocumentFormInfo = useRegulatoryDocumentForm();
	const {
		modeStateInfo: {setMode},
		documentChangeStateInfo: {setDidDocumentChange},
	} = formInfo;

	const {regulation} = regulatoryDocumentDetails;

	const isEditVersionDataDisabled = useMemo(() => {
		return (
			getIfDocHasUneditableStatus(
				regulatoryDocumentDetails,
				isAdmin,
				username,
			) ||
			isRegDocEditVersionDataDisabled(
				regulatoryDocumentDetails,
				encodedUserId,
			) ||
			isSubmitting
		);
	}, [
		regulatoryDocumentDetails,
		isAdmin,
		username,
		encodedUserId,
		isSubmitting,
	]);

	useEditRegulatoryDocCmd({
		setDidDocumentChange,
		setMode,
		stopPolling: null,
		title: getDisabledEditionForMOdifiedVersionTooltip(
			regulatoryDocumentDetails,
			t,
		),
		key: 'editDoc',
		text: t('EditBtnLabel'),
		priority: 2,
		disabled: isEditVersionDataDisabled,
		hidden: isPogisDocument || viewGrid,
		extraDependencies: [isEditVersionDataDisabled, viewGrid],
	});

	type DocRegulation = NonNullable<DocOfRegDocDetailsPageQuery['regulation']>;

	type PageRegulatoryDocs = DocRegulation['regulatoryDocuments'];

	type DocOfPageRegulatoryDocs = PageRegulatoryDocs[number];

	const sortedRegulatoryDocs: PageRegulatoryDocs =
		useSortRegulatoryDocs<DocOfPageRegulatoryDocs>({
			docs: regulatoryDocumentDetails.regulation?.regulatoryDocuments,
			extraDependencies: [regulatoryDocumentDetails],
		});

	/*
	 * Other
	 */
	const isLoading: boolean = isRegDocLoading || vexItemsLoading;

	const renderBaseParagraphSectionHeader = (): JSX.Element => {
		return (
			<ParagraphsSectionHeader
				{...{
					filteredParagraphs,
					showWorkbenchItems,
					t,
					textStyles,
					toggleToc,
				}}
			/>
		);
	};

	const renderDefaultParagraphSectionHeader = (
		paragraphsHeader: JSX.Element,
	): JSX.Element => {
		const StickyWithCorrectType = Sticky as unknown as CorrectStickyType;

		return (
			<StickyWithCorrectType
				stickyPosition={StickyPositionType.Header}
				stickyClassName={CLASS_TO_ADD_TO_PARAGRAPHS_SECTION_HEADER_WHEN_STICKY}
			>
				{paragraphsHeader}
			</StickyWithCorrectType>
		);
	};

	const renderPdfOrDefaultSectionHeader = (): JSX.Element => {
		const sectionHeader: JSX.Element = renderBaseParagraphSectionHeader();
		/**
		 * We show the base paragraphs header when we are loading. Otherwise, it
		 * will show on top of the load wrapper, which looks weird.
		 */
		return isLoading || viewPdf
			? sectionHeader
			: renderDefaultParagraphSectionHeader(sectionHeader);
	};

	const getAmountToScrollBackBy = (): number => {
		if (viewPdf) return HEIGHT_OF_LIST_HEADER;
		return HEIGHT_OF_LIST_HEADER + HEIGHT_OF_PARAGRAPHS_SECTION_HEADER;
	};

	const renderSectionHeaderAndParagraphs = (): JSX.Element => {
		return (
			<>
				{renderPdfOrDefaultSectionHeader()}
				<RegDocParagraphTooltipTranslationProvider>
					<EntityListContextProvider pageDetails={regulatoryDocumentDetails}>
						{viewGrid && (
							<ParagraphGridView
								regulatoryDocumentId={docId}
								paragraphsAll={
									regulatoryDocumentDetails.paragraphs as RegulatoryDocumentParagraph[]
								}
								paragraphsFiltered={paragraphListItems}
								setViewGrid={setViewGrid}
							/>
						)}
						{!viewGrid && (
							<RegDocDetailsPageParagraphsList
								tableId='regDocDetailsParagraphsList'
								paragraphs={paragraphListItems}
								regulationId={regulatoryDocumentDetails?.regulation?.id ?? ''}
								regulatoryDocumentId={docId}
								clearingAudits={regulatoryDocumentDetails.clearingAuditLogs}
								workflowStatus={regulatoryDocumentDetails.workflow?.status}
								onShouldVirtualize={() => !viewPdf}
								amountToScrollBackByAfterScrollingToParagraph={getAmountToScrollBackBy()}
							/>
						)}
					</EntityListContextProvider>
				</RegDocParagraphTooltipTranslationProvider>
			</>
		);
	};

	const renderParagraphsPane = (): JSX.Element => {
		return (
			<SidePaneForParagraphs extraStyle={pdfParagraphsListWrapper}>
				{renderSectionHeaderAndParagraphs()}
			</SidePaneForParagraphs>
		);
	};

	const renderMainParagraphs = (): JSX.Element => {
		return (
			<div
				className={mergeStyles(mainParagraphsWrapper)}
				ref={refToContainerOfMainParagraphs}
			>
				{renderSectionHeaderAndParagraphs()}
			</div>
		);
	};

	if (!isRegDocLoading && !data?.regulatoryDocument) {
		return (
			<h4 style={{marginTop: '50px', marginLeft: '20px'}}>
				{t('DocumentNotFound')}
			</h4>
		);
	}

	return (
		<RegDocDetailsPageInfoProvider
			isFormRefetchingRegDoc={isFormRefetchingRegDoc}
			isRegDocOrVexItemsLoading={isLoading}
			regDocNetworkStatus={regDocNetworkStatus}
			vexItemsNetworkStatus={vexItemsNetworkStatus}
			areVexItemsLoading={vexItemsLoading}
			regDoc={data}
			vexItems={vexItems}
		>
			<RegDocDetailsPageLoadWrapper>
				<RegulatoryDocumentsContextProvider
					regulatoryDocument={regulatoryDocumentDetails as any}
					sMode={SelectionMode.none}
				>
					<RegDocSidebarInfoProvider>
						{!sideBySide && !viewPdf && !viewGrid && (
							<>
								<Breadcrumb
									items={
										regulatoryDocumentDetails.regulation
											? allBreadCrumbItems
											: headlessBreadCrumbItems
									}
								/>
								<AuditLogsLoadingStatusProvider
									areAuditLogsLoading={areAuditLogsLoading}
								>
									<DetailsSection
										title={regulatoryDocumentDetails?.name}
										titleWithAudit={showAudit}
										initialFieldRows={initialFieldRows}
										viewMoreFieldRows={viewMoreFieldRows}
										auditLogData={auditLogs}
									/>
								</AuditLogsLoadingStatusProvider>
							</>
						)}
						{!isPogisDocument && sideBySide ? (
							<Stack horizontal>
								<RegDocParagraphTooltipTranslationProvider>
									<ParagraphsComparisonPanel
										listId='firstList'
										paragraphs={
											showWorkbenchItems
												? vexWorkbenchItems
												: regulatoryDocumentDetails.paragraphs
										}
										tableId='regDocDetailsParagraphsList'
									/>
									<hr style={{margin: 10}} />
									<ParagraphsComparisonPanel
										listId='secondList'
										paragraphs={sideBySide.paragraphs}
										isSecondaryList={true}
										selectionMode={SelectionMode.single}
									/>
								</RegDocParagraphTooltipTranslationProvider>
							</Stack>
						) : (
							<Stack horizontal styles={viewPdf ? stackStyles : stackshit}>
								{viewPdf ? renderParagraphsPane() : renderMainParagraphs()}
								{viewPdf && regulatoryDocumentDetails.document?.uri && (
									<div style={{width: '50%'}}>
										<PdfViewer url={regulatoryDocumentDetails.document?.uri} />
									</div>
								)}
							</Stack>
						)}
						{!isPogisDocument && !viewGrid && (
							<>
								<WorkflowDialog
									regulatoryDocument={regulatoryDocumentDetails as any}
									refetchAuditLogs={refetchAuditLogsAndUpdateIsLoading}
								/>
								{regulatoryDocumentDetails?.workflow?.status !==
									WorkflowStatus.New && (
									<>
										<ViewParagraphsForm
											regulatoryDocument={regulatoryDocumentDetails}
										/>
										<EditParagraphsForm
											refetchAuditLogsAndUpdateIsLoading={
												refetchAuditLogsAndUpdateIsLoading
											}
											setAreAuditLogsLoading={setAreAuditLogsLoading}
											vexItems={vexItems}
											setIsFormRefetchingRegDoc={setIsFormRefetchingRegDoc}
											regDoc={regulatoryDocumentDetails}
											refToContainerOfMainParagraphs={
												refToContainerOfMainParagraphs
											}
											setIndexToScrollTo={setIndexToScrollTo}
										/>
										<AssignRequirementsForm />
										<RemoveRequirementsForm />
									</>
								)}
								<CommentForm
									comments={comments ?? []}
									level={CommentLevel.RegulatoryDocument}
								/>
								<FAQForm
									faqs={faqs ?? []}
									level={FAQLevel.RegulatoryDocument}
								/>
								<KeywordAssignmentForm />
								<CompareversionForm onValueChange={handleSideBySideChange} />
								<CompareVersionPanel />

								<RegulatoryDocumentsForm
									{...formInfo}
									regulatoryDocument={regulatoryDocumentDetails}
									regulationId={regulation?.id ?? null}
									regulatoryDocuments={sortedRegulatoryDocs}
									markets={
										(regulatoryDocumentDetails?.regulation?.markets ??
											[]) as Market[]
									}
									regulationNumber={regulation?.regulationNumber ?? ''}
									startPolling={null}
									queriesToRefetchAfterSave={[
										GetRegulatoryDocumentDetailsDocument,
									]}
									fetchAndSetAuditLogs={refetchAuditLogsAndUpdateIsLoading}
								/>
							</>
						)}
						{!isPogisDocument && (
							<ParagraphTableOfContents
								isOpen={tocIsOpen}
								onDismiss={hideToc}
								visibleParagraphs={paragraphs || []}
								allParagraphs={regulatoryDocumentDetails.paragraphs}
							/>
						)}
					</RegDocSidebarInfoProvider>
				</RegulatoryDocumentsContextProvider>
			</RegDocDetailsPageLoadWrapper>
		</RegDocDetailsPageInfoProvider>
	);
};

interface HeaderProps {
	toggleToc: () => void;
	t: TFunction;
	showWorkbenchItems: boolean;
	textStyles: ITextStyles;
	filteredParagraphs: RegulatoryDocumentParagraph[];
}

const ParagraphsSectionHeader: React.FC<HeaderProps> = ({
	filteredParagraphs,
	showWorkbenchItems,
	t,
	textStyles,
	toggleToc,
}) => {
	return (
		<Stack horizontal styles={{root: {marginBottom: -5}}}>
			<IconButton
				iconProps={{iconName: 'BulletedTreeList'}}
				onClick={toggleToc}
				title={t('TableOfContents')}
			/>
			<Text styles={textStyles} variant={'xLarge'}>
				{showWorkbenchItems ? t('MyParagraphsHeader') : t('ParagraphsHeader')}
			</Text>
			{filteredParagraphs.length > 0 && (
				<Icon
					iconName='Filter'
					title={t('ParagraphsFiltered')}
					styles={{root: {paddingTop: 8, paddingLeft: 8}}}
				/>
			)}
		</Stack>
	);
};

const getStyles = (fullHeight: boolean) => {
	const stackHeight: IRawStyle['height'] = getStackHeight(fullHeight);

	return {
		pdfParagraphsListWrapper: {width: '50%'},
		mainParagraphsWrapper: {},
		textStyles: {
			root: {
				paddingLeft: 15,
			},
		},
		stackStyles: {root: {height: stackHeight}},
		stackshit: {
			root: {display: 'block'},
		},
	};
};

export default RegulatoryDocumentDetailsPage;
