import {RegulatoryDocumentParagraph} from 'types';

export interface IRow {
	rowId: number;
	paragraph: RegulatoryDocumentParagraph;
}

export function createRows(
	paragraphsFiltered: RegulatoryDocumentParagraph[],
	blockSize: number,
): IRow[] {
	const rows: IRow[] = [];
	paragraphsFiltered.slice(0, blockSize).forEach((p, ii) => {
		rows.push({
			rowId: ii,
			paragraph: p,
		});
	});

	return rows;
}
