import {
	Breadcrumb,
	CommandButton,
	IBreadcrumbItem,
	IIconProps,
	Label,
	mergeStyleSets,
	Separator,
	Stack,
	Theme,
	useTheme,
} from '@fluentui/react';
import {DetailsField, DetailsSection} from 'components';
import {CommentForm} from 'components/CommentForm';
import {CommentLevel} from 'components/CommentList';
import {FAQForm} from 'components/FAQForm';
import {FAQLevel} from 'components/FAQList';
import {LoadWrapper} from 'components/LoadWrapper';
import {RegDocsList} from 'features/RegulatoryDocuments/components/RegDocsList/RegDocsList';
import {RegulatoryDocumentsListDocumentForm} from 'features/RegulatoryDocuments/components/RegulatoryDocumentsListDocumentForm';
import {CompareVersionPanel} from 'features/RegulatoryDocuments/components/DocumentCompare';
import {RegulatoryDocumentsContextProvider} from 'features/RegulatoryDocuments/context';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Market, Regulation, UserRole, WorkflowStatus} from 'types';
import {useGetRegulationQuery} from '../hooks';
import TimelineComponent from 'react-document-timeline';
import 'react-document-timeline/lib/style.css';
import {useEditRegulationCmd} from '../components/useEditRegulationCmd';
import {
	RegulationFormModeStateInfo,
	useRegulationFormMode,
} from '../components/useRegulationFormMode';
import {useSortRegulatoryDocs} from '../components/useSortRegulatoryDocs';
import RegulationDetailRegulationForm from '../components/RegulationDetailRegulationForm';
import {
	StateForShouldShowAudit,
	useShouldShowAudit,
	useToggleAuditCmd,
	useFavoriteCommand,
} from 'hooks';
import {RegDocsTooltipTranslationProvider} from 'features/RegulatoryDocuments/components/RegDocTooltipTranslationProvider';
import {KeywordsField} from 'components/DetailsSection/KeywordsField';
import {DocumentSourceEnum, PageRegulatoryDocs} from './RegulationDetail.types';
import {PogisDocsList} from 'features/Archive/components/PogisDocumentsList/PogisDocsList';
import {EntityListContextProvider} from '../../../components/EntityList/EntityListContext';
import {parseTooltipNewlines} from 'helpers/tooltips';

interface Version {
	id: string;
	name: string;
	dateNewRegistration?: Date;
	dateNewTypes?: Date;
	dateExpiration?: Date;
	dateEffective?: Date;
	dateAllVehicles?: Date;
	phaseIn: Date[];
	phaseOut: Date[];
	createdAt: Date;
	predecessor?: string;
	successor?: string;
}

type Locales = 'en' | 'de';
const defaultLocale = 'de';
const defaultZoom = 4;

export const RegulationDetail = () => {
	const {t} = useTranslation('features/regulations', {
		keyPrefix: 'RegulationDetail',
	});
	const {t: tt} = useTranslation('features/regulations', {
		keyPrefix: 'TooltipsText',
	});
	const stateForShouldShowAudit: StateForShouldShowAudit = useShouldShowAudit();
	const [showAudit] = stateForShouldShowAudit;

	const {regulationId}: any = useParams();
	const [viewMore, setViewMore] = React.useState<boolean>(true);

	const downIcon: IIconProps = {iconName: 'ChevronDown'};
	const upIcon: IIconProps = {iconName: 'ChevronUp'};

	const theme = useTheme();
	const classnames = getClassnames(theme);

	const {
		loading,
		data: regulation,
		startPolling,
		stopPolling,
	} = useGetRegulationQuery({
		variables: {regulationId},
		pollInterval: 10000,
	});

	const auditLogs = React.useMemo(
		() => regulation?.regulation?.auditLog || [],
		[regulation],
	);

	const regulationData = React.useMemo(
		() => regulation?.regulation || ({} as Regulation),
		[regulation],
	);

	const regulatoryDocuments: PageRegulatoryDocs = useSortRegulatoryDocs({
		docs: regulationData.regulatoryDocuments as any,
		extraDependencies: [regulationData],
	});

	const comments = React.useMemo(
		() => regulationData.comments || [],
		[regulationData],
	);

	const faqs = React.useMemo(() => regulationData.faqs || [], [regulationData]);

	const navigate = useNavigate();
	const onBreadcrumbClick = React.useCallback(
		(
			ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
			item?: IBreadcrumbItem | undefined,
		) => {
			ev?.preventDefault();
			if (item && item.href) {
				navigate(item.href);
			}
		},
		[],
	);

	const itemsWithHref: IBreadcrumbItem[] = [
		{
			text: t('Regulations'),
			key: 'regulations',
			href: '/regulations',
			onClick: onBreadcrumbClick,
		},
		{
			text: `${regulationData?.regulationNumber}`,
			key: 'version',
		},
	];

	const initialFieldRows: DetailsField[][] = React.useMemo(
		() => [
			[
				{
					data: regulationData?.summary,
					type: 'richtext',
					label: t('ShortSummaryLabel'),
					audit: showAudit,
					auditFieldName: 'summary',
				},
			],
			[
				{
					data: regulationData?.attachments,
					type: 'attachments',
					label: t('AttachmentsLabel'),
				},
			],
			[
				{
					anonymous: regulationData?.anonymous,
					data: regulationData?.contactPersons,
					type: 'personas',
					label: t('ContactPersonLabel'),
				},
			],
			[
				{
					data: regulationData?.markets,
					type: 'array',
					label: t('MarketsLabel'),
					audit: showAudit,
					auditFieldName: 'marketRefs',
				},
			],
			[
				{
					data: regulationData?.vehicleCategories,
					type: 'array',
					label: t('VehicleCategoriesLabel'),
				},
				{
					data: regulationData?.driveVariants,
					type: 'array',
					label: t('DriveVariantsLabel'),
				},
			],
			[
				{
					data: regulationData?.mainKeywords,
					type: 'array',
					label: t('MainKeywordsLabel'),
					audit: showAudit,
					auditFieldName: 'mainKeywordRefs',
				},
				{
					data: regulationData?.keywords,
					label: t('KeyWordsLabel'),
					render: KeywordsField,
				},
			],
			[
				{
					data: regulationData?.regulationClusters,
					type: 'array',
					label: t('RegulationClustersLabel'),
					tooltipContent: tt('regulationClusters'),
				},
				{
					data: regulationData?.standardPlusPcmsClusters,
					type: 'array',
					label: t('StandardPlusPcmsClustersLabel'),
					audit: showAudit,
					auditFieldName: 'standardPlusPcmsClusterRefs',
					tooltipContent: tt('standardPlusPcmsClusters'),
				},
			],
		],
		[regulationData, t, showAudit],
	);

	useToggleAuditCmd(stateForShouldShowAudit, {
		roles: [
			UserRole.SystemAdministrator,
			UserRole.Vko,
			UserRole.Vex,
			UserRole.ShApprover,
			UserRole.Readers,
			UserRole.RegulationReader,
		],
	});

	useFavoriteCommand(regulationData?.id ?? '', [], {
		roles: [
			UserRole.SystemAdministrator,
			UserRole.Vko,
			UserRole.Vex,
			UserRole.ShApprover,
			UserRole.Readers,
			UserRole.RegulationReader,
		],
	});

	const [mode, setMode]: RegulationFormModeStateInfo = useRegulationFormMode();

	useEditRegulationCmd({
		key: 'edit-regulation-data',
		text: t('EditRegulationBtnLabel'),
		priority: 1,
		setMode,
		stopPolling,
	});

	const versions: Version[] = React.useMemo(
		() =>
			regulatoryDocuments
				.filter(
					r =>
						r.documentSource?.name !== DocumentSourceEnum.Pogis &&
						r.workflow.status !== WorkflowStatus.Modified,
				)
				.map(r => ({
					id: r.id,
					name: r.name,
					predecessor: r.predecessor?.id,
					successor: r.successor?.id,
					dateNewRegistration: r.dateNewRegistration
						? new Date(r.dateNewRegistration)
						: undefined,
					dateNewTypes: r.dateNewTypes ? new Date(r.dateNewTypes) : undefined,
					dateExpiration: r.dateExpiration
						? new Date(r.dateExpiration)
						: undefined,
					dateEffective: r.dateEffective
						? new Date(r.dateEffective)
						: undefined,
					dateAllVehicles: undefined, // NOTE: not sure what it should be
					phaseIn: r.phaseIn.map(pi => new Date(pi.date)),
					phaseOut: r.phaseOut.map(po => new Date(po.date)),
					createdAt: new Date(r.createdAt),
				})),
		[regulatoryDocuments],
	);

	const pogisDocuments = React.useMemo(() => {
		return regulatoryDocuments.filter(
			doc => doc.documentSource?.name === DocumentSourceEnum.Pogis,
		);
	}, [regulatoryDocuments]);

	const [tracks, setTracks] = React.useState<Version[][]>([]);

	React.useEffect(
		() => setTracks(arrangeVersions(versions)),
		[versions, regulatoryDocuments, regulationData, regulation],
	);

	const [locale] = React.useState<Locales>(defaultLocale);

	const handleViewBtnClick = () => setViewMore(!viewMore);

	const filteredRegulatoryDocuments = React.useMemo(() => {
		return regulatoryDocuments.filter(r => !pogisDocuments.includes(r));
	}, [regulatoryDocuments, pogisDocuments]);

	return (
		<LoadWrapper loading={loading}>
			<div className={classnames.header}>
				<Breadcrumb
					items={itemsWithHref}
					ariaLabel='Breadcrumb'
					overflowAriaLabel='More links'
				/>
			</div>
			<DetailsSection
				auditLogData={auditLogs}
				title={regulationData?.name}
				titleWithAudit={showAudit}
				initialFieldRows={initialFieldRows}
			/>
			{tracks.length > 0 ? (
				<div>
					<div className={classnames.header}>
						<Stack horizontal>
							<Label
								className={classnames.headerText}
								onClick={handleViewBtnClick}
							>
								{t('Timeline')}
							</Label>
							<CommandButton
								iconProps={viewMore ? upIcon : downIcon}
								onClick={handleViewBtnClick}
							/>
						</Stack>
					</div>
					{viewMore && (
						<TimelineComponent
							locale={locale}
							zoom={defaultZoom}
							tracks={tracks}
							enableSticky
							scrollToNow
							lineStyle={{
								newRegistration: theme.palette.themePrimary,
								newTypes: theme.palette.blueDark,
								phaseIn: theme.palette.green,
								phaseOut: theme.palette.yellow,
							}}
						/>
					)}
				</div>
			) : (
				<div></div>
			)}
			<div className={classnames.header}>
				<Label className={classnames.headerText}>{t('Documents')}</Label>
			</div>
			<RegulatoryDocumentsContextProvider>
				<RegDocsTooltipTranslationProvider>
					<RegulatoryDocumentsListDocumentForm
						regulationId={regulationId}
						regulatoryDocuments={filteredRegulatoryDocuments}
						markets={(regulationData?.markets ?? []) as Market[]}
						regulationNumber={regulationData?.regulationNumber}
						startPolling={startPolling}
						stopPolling={stopPolling}
					/>
					<EntityListContextProvider pageDetails={regulationData}>
						<RegDocsList
							regulationId={regulationId}
							regulatoryDocuments={filteredRegulatoryDocuments}
							startPolling={startPolling}
							stopPolling={stopPolling}
							sticky={true}
						/>
					</EntityListContextProvider>
					{pogisDocuments.length > 0 && (
						<>
							<Separator />
							<div className={classnames.header}>
								<Label className={classnames.headerText}>
									{t('PogisEntry')}
								</Label>
							</div>
							<PogisDocsList
								regulationId={regulationId}
								pogisDocuments={pogisDocuments}
								sticky={true}
							/>
						</>
					)}
				</RegDocsTooltipTranslationProvider>
				<CompareVersionPanel />
				<RegulationDetailRegulationForm
					mode={mode}
					setMode={setMode}
					regulation={regulationData}
					startPolling={startPolling}
				/>
			</RegulatoryDocumentsContextProvider>
			<CommentForm comments={comments} level={CommentLevel.Regulation} />
			<FAQForm faqs={faqs} level={FAQLevel.Regulation} />
		</LoadWrapper>
	);
};

const getClassnames = (theme: Theme) =>
	mergeStyleSets({
		header: {
			marginLeft: 15,
		},
		headerText: {
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
			textTransform: 'capitalize',
		},
	});

function arrangeVersions(versions: Version[]): Version[][] {
	const map: Record<string, Version> = {};
	const result: Version[][] = [];

	for (const version of versions) {
		map[version.id] = version;
	}

	for (const version of versions) {
		const predecessor = version.predecessor
			? map[version.predecessor]
			: undefined;
		const predecessorGroup = predecessor
			? result.find(group => group.some(v => v.id === predecessor.id))
			: undefined;

		if (!result.some(group => group.some(v => v.id === version.id))) {
			if (predecessorGroup) {
				predecessorGroup.push(version);
			} else {
				result.push(predecessor ? [predecessor, version] : [version]);
			}
		}
	}

	return result;
}

export default RegulationDetail;
