import React, {useCallback, useMemo} from 'react';

import {FetchResult} from '@apollo/client';
import {IButtonStyles, IIconProps, IStyle, IconButton} from '@fluentui/react';

import {BaselineOfBaselineListPage} from './BaselinesPage.types';
import {useBaselineListPageTranslations} from './BaselinesPage.utils';
import {getConfig} from '../../../config';

interface Props {
	baseline: BaselineOfBaselineListPage;
}

export const exportBaselineRequirementsAndDownloadFile = async (
	baselineId: string,
	onlyRequirementsIds: Array<string>,
) => {
	const config = getConfig();
	const token = getCookieValue('trace-msalAuthToken');
	try {
		const response = await fetch(
			config.REACT_APP_SERVICE_URL.replace('/graphql', '') +
				'/download/baseline',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({
					baselineId,
					onlyRequirementsIds,
				}),
			},
		);

		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const filename = response.headers.get('Filename') ?? 'filename';

		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;

		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error('There was an error downloading the file:', error);
	}
};

export function DownloadBtn({baseline}: Props): JSX.Element {
	const {t} = useBaselineListPageTranslations();

	const iconProps = useMemo((): IIconProps => {
		return {iconName: 'Download'};
	}, []);

	const styles = useMemo((): IButtonStyles => {
		const bgColorStyles: IStyle = {backgroundColor: 'transparent'};
		return {
			root: {height: '1rem'},
			rootHovered: bgColorStyles,
			rootPressed: bgColorStyles,
		};
	}, []);

	return (
		<IconButton
			iconProps={iconProps}
			onClick={() => {
				exportBaselineRequirementsAndDownloadFile(baseline.id, []);
			}}
			title={t('DownloadBtnTitle')}
			styles={styles}
		/>
	);
}

function getCookieValue(name: string) {
	const regex = new RegExp(`(^| )${name}=([^;]+)`);
	const match = document.cookie.match(regex);
	if (match) {
		return match[2];
	}
}
