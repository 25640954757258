import {Persona, PersonaSize, Stack, useTheme} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {getLocaleForDateFns} from 'i18n/localeDateFormat';

import {format} from 'date-fns';
import _ from 'lodash';
import {FaqReplies} from './FaqReplies';

export const FaqQuestions: any = ({
	questions,

	selectedQuestion,
	setSelectedQuestion,
}: any) => {
	const {t, i18n} = useTranslation('common/faq');
	const theme = useTheme();

	const locale: Locale = getLocaleForDateFns(i18n.language);

	return (
		<div style={{width: '50%'}}>
			{questions?.map((elem: any) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
						marginBottom: '10px',
						cursor: 'pointer',
						border:
							selectedQuestion?.id === elem.id
								? `${theme.palette.neutralDark} solid 3px`
								: '',
					}}
					key={elem.id}
					onClick={() => {
						setSelectedQuestion(elem);
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '10px',
						}}
					>
						<strong>
							<Persona
								aria-label='persona'
								size={PersonaSize.size24}
								text={elem.createdBy.name}
							/>
						</strong>
						<div
							style={{
								color: theme.palette.neutralDark,
								opacity: '0.7',
								marginRight: '8px',
							}}
						>
							|
						</div>
						<div style={{color: theme.palette.neutralDark, opacity: '0.7'}}>
							{format(new Date(elem.createdAt), 'dd LLLL yyyy HH:mm', {
								locale,
							})}
						</div>
					</div>

					<Stack.Item style={{padding: '10px'}}>{elem.name}</Stack.Item>
					<Stack.Item>
						<div
							style={{
								backgroundColor: theme.palette.neutralLight,

								padding: '10px',
								cursor: 'pointer',
								color: theme.palette.red,
							}}
						>
							{elem.answers.length ? (
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<FaqReplies answers={elem.answers} />
								</div>
							) : (
								t('NoReplies')
							)}
						</div>
					</Stack.Item>
				</div>
			))}
		</div>
	);
};
