import React from 'react';
import {ITextFieldProps, Stack} from '@fluentui/react';
import {ActionTags} from 'features/RegulatoryDocuments/components/DocumentDetails/ActionTags';
import HostWithoutType, {
	DefaultTooltipHostWithComponentProps,
} from 'features/localizedTooltips/DefaultTooltipHostWithComponent';
import {BasePropsOfComponentForRenderer} from 'features/localizedTooltips/TooltipRendererCreator.service';
import {ControlledTextFieldProps} from './ControlledTextField';

export type DataWithHostProps = Pick<
	DefaultTooltipHostWithComponentProps<ITextFieldProps>,
	'tooltipHostProps'
>;

type Props = DataWithHostProps &
	BasePropsOfComponentForRenderer<ITextFieldProps>;

type LabelProps = Pick<Props, 'defaultRender'> & ITextFieldProps;

const Label = ({
	defaultRender,
	'aria-describedby': ariaDescribedBy,
	...other
}: LabelProps): JSX.Element => {
	return (
		<Stack
			horizontal
			verticalAlign='center'
			tokens={{
				childrenGap: 5,
				maxWidth: 600,
			}}
			aria-describedby={ariaDescribedBy}
		>
			<span>{defaultRender?.(other)}</span>
			<ActionTags tags={(other as ControlledTextFieldProps)?.actionTags} />
		</Stack>
	);
};

export default function ControlledTextFieldTooltipAndLabel({
	defaultRender,
	originalComponentProps = {},
	tooltipHostProps,
}: Props) {
	const getComponentProps = (): LabelProps => {
		return {...originalComponentProps, defaultRender};
	};

	const Host = HostWithoutType<LabelProps>;

	return (
		<Host
			Component={Label}
			keyWithFieldName='name'
			componentProps={getComponentProps()}
			tooltipHostProps={tooltipHostProps}
		/>
	);
}
