import {TFunction} from 'react-i18next';
import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';
import Excel from 'exceljs';

interface IVehProjectsPage {
	(arrProjectsFilt: any[], t: TFunction): Promise<void>;
}

export const handleClickVehProjects: IVehProjectsPage = async (
	arrProjectsFilt,
	t,
) => {
	const workbook = new Excel.Workbook();
	const arrColWidth: number[] = [40, 20, 20, 30, 20, 20];
	const strPageName = t('VehicleProjects', {ns: 'features/vehicleprojects'});
	const sheet = workbook.addWorksheet(
		'TRACE_' + t('VehicleProjects', {ns: 'features/vehicleprojects'}),
	);

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'tableVehProjects',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('ModelSeries', {ns: 'features/vehicleprojects'}),
				filterButton: true,
			},
			{
				name: t('Generation', {ns: 'features/vehicleprojects'}),
				filterButton: true,
			},
			{
				name: t('RequirementsCount', {ns: 'features/vehicleprojects'}),
				filterButton: true,
			},
		],
		rows: [],
	});

	const table = sheet.getTable('tableVehProjects');
	let strModelSeries = '';
	intRowCount++;
	for (let ii = 0; ii < arrProjectsFilt.length; ii++) {
		if (arrProjectsFilt[ii].modelSeries !== strModelSeries) {
			row = sheet.getRow(intRowCount);
			row.alignment = {wrapText: true, vertical: 'top'};
			strModelSeries = arrProjectsFilt[ii].modelSeries;
			table.addRow([arrProjectsFilt[ii].modelSeries, '', '']);
			intRowCount++;
		}

		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		row.outlineLevel = 1;
		row.getCell('B').fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: '8DB4E2'},
		};
		table.addRow([
			'',
			arrProjectsFilt[ii].generation,
			arrProjectsFilt[ii].requirementsCount,
		]);
		intRowCount++;
	}

	table.commit();
	const strZipName = strPageName;
	await createZIPXL(workbook, strZipName, t);
};
