import {ICommandBarItemProps} from '@fluentui/react';
import * as React from 'react';
import {CommandsContext} from 'context';
import {UserRole} from 'types';
import {useUserContext} from 'authentication/UserContext';

export type Command = ICommandBarItemProps & {
	priority?: number;
	farCommand?: boolean;
	hidden?: boolean;
	/**
	 * Roles that are allowed to see this command
	 */
	roles?: UserRole[];
};

/**
 * Adds a new command to the existing CommandBarContext
 * @param command The command that should be added
 * @param deps Dependencies to other hooks within the command
 * @returns Noop function
 */
export function useCommand(command: Command, deps?: any[]) {
	const {addCommand} = React.useContext(CommandsContext);

	const {roles} = useUserContext();
	const commandRoles = command.roles;
	const hidden = commandRoles
		? command?.hidden ||
		  !commandRoles?.some(cmdRole =>
				roles?.some(role => role.toLowerCase() === cmdRole.toLowerCase()),
		  )
		: command?.hidden;

	const cmd = {
		...command,
		hidden,
	};

	return React.useEffect(() => {
		if (addCommand) {
			return addCommand(cmd);
		}

		// eslint-disable-next-line
		return () => {};
	}, [/** command, */ addCommand, roles, ...(deps || [])]);
}

export const useDeselectCommand = (
	count: number,
	command: Omit<Command, 'key' | 'roles'>,
	deps?: any[],
) => {
	return useCommand(
		{
			key: 'deselect',
			farCommand: true,
			hidden: count === 0,
			iconProps: {iconName: 'Cancel'},
			text: `${count} ausgewählt`,
			...command,
		},
		deps,
	);
};

export const commandIconStyle = {
	paddingLeft: '6px',
	paddingRight: '6px',
	color: '#B12525',
	fontSize: 'large',
};
