import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWorkbenchItemsQueryVariables = Types.Exact<{
  workflowStatus: Array<Types.WorkflowStatus> | Types.WorkflowStatus;
}>;


export type GetWorkbenchItemsQuery = { __typename?: 'Query', workbenchRegulatoryDocuments: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, status: Types.RegulatoryDocumentStatus, documentStatus: Types.DocumentStatus, regulation?: { __typename?: 'Regulation', id: string, name: string, regulationNumber: string } | null, workflow: { __typename?: 'Workflow', message?: string | null, status: Types.WorkflowStatus, clearingVKO: Array<{ __typename?: 'User', id: string }>, reapprovingVKO: { __typename?: 'User', id: string } } }> };

export type GetWorkbenchItemsVexQueryVariables = Types.Exact<{
  workflowStatus: Array<Types.WorkflowStatus> | Types.WorkflowStatus;
  inProcess: Types.Scalars['Boolean'];
}>;


export type GetWorkbenchItemsVexQuery = { __typename?: 'Query', workbenchRegulatoryDocumentParagraphs: Array<{ __typename?: 'VexWorkbenchItem', paragraphCount: number, regulatoryDocument: { __typename?: 'RegulatoryDocument', id: string, name: string, createdAt?: any | null, workflow: { __typename?: 'Workflow', status: Types.WorkflowStatus, message?: string | null, dueDate?: any | null, dueDateInProcess?: any | null }, regulation?: { __typename?: 'Regulation', id: string, name: string, regulationNumber: string } | null, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, createdAt?: any | null, modifiedAt?: any | null, keywords: Array<{ __typename?: 'Keyword', id: string, name: string }> }> } }> };

export type GetVkoWorkbenchRegulatoryDocumentParagraphsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVkoWorkbenchRegulatoryDocumentParagraphsQuery = { __typename?: 'Query', vkoWorkbenchRegulatoryDocumentParagraphs: Array<{ __typename?: 'VkoWorkbenchItem', rejectReason: string, rejectKeywordReason: string, vexClusterName: string, keywordName: string, regulatoryDocumentParagraph: { __typename?: 'RegulatoryDocumentParagraph', id: string, createdAt?: any | null, modifiedAt?: any | null, enumeration: string, keywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, keywordAssignments?: Array<{ __typename?: 'KeywordAssignment', keyword: { __typename?: 'Keyword', id: string }, vexClusterAssignments: Array<{ __typename?: 'VexClusterAssignment', rejectReason: string }> }> | null, parent: { __typename?: 'RegulatoryDocument', id: string, name: string, workflow: { __typename?: 'Workflow', status: Types.WorkflowStatus, message?: string | null }, regulation?: { __typename?: 'Regulation', id: string, name: string, regulationNumber: string } | null } } }> };


export const GetWorkbenchItemsDocument = gql`
    query GetWorkbenchItems($workflowStatus: [WorkflowStatus!]!) {
  workbenchRegulatoryDocuments(where: {workflow: {status: {in: $workflowStatus}}}) {
    id
    name
    createdAt
    modifiedAt
    status
    documentStatus
    regulation {
      id
      name
      regulationNumber
    }
    workflow {
      message
      status
      clearingVKO {
        id
      }
      reapprovingVKO {
        id
      }
    }
  }
}
    `;

/**
 * __useGetWorkbenchItemsQuery__
 *
 * To run a query within a React component, call `useGetWorkbenchItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkbenchItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkbenchItemsQuery({
 *   variables: {
 *      workflowStatus: // value for 'workflowStatus'
 *   },
 * });
 */
export function useGetWorkbenchItemsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkbenchItemsQuery, GetWorkbenchItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkbenchItemsQuery, GetWorkbenchItemsQueryVariables>(GetWorkbenchItemsDocument, options);
      }
export function useGetWorkbenchItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkbenchItemsQuery, GetWorkbenchItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkbenchItemsQuery, GetWorkbenchItemsQueryVariables>(GetWorkbenchItemsDocument, options);
        }
export type GetWorkbenchItemsQueryHookResult = ReturnType<typeof useGetWorkbenchItemsQuery>;
export type GetWorkbenchItemsLazyQueryHookResult = ReturnType<typeof useGetWorkbenchItemsLazyQuery>;
export type GetWorkbenchItemsQueryResult = Apollo.QueryResult<GetWorkbenchItemsQuery, GetWorkbenchItemsQueryVariables>;
export const GetWorkbenchItemsVexDocument = gql`
    query GetWorkbenchItemsVex($workflowStatus: [WorkflowStatus!]!, $inProcess: Boolean!) {
  workbenchRegulatoryDocumentParagraphs(
    where: {workflow: {status: {in: $workflowStatus}}}
    inProcess: $inProcess
  ) {
    paragraphCount
    regulatoryDocument {
      id
      name
      workflow {
        status
        message
        dueDate
        dueDateInProcess
      }
      regulation {
        id
        name
        regulationNumber
      }
      createdAt
      paragraphs {
        id
        createdAt
        modifiedAt
        keywords {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetWorkbenchItemsVexQuery__
 *
 * To run a query within a React component, call `useGetWorkbenchItemsVexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkbenchItemsVexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkbenchItemsVexQuery({
 *   variables: {
 *      workflowStatus: // value for 'workflowStatus'
 *      inProcess: // value for 'inProcess'
 *   },
 * });
 */
export function useGetWorkbenchItemsVexQuery(baseOptions: Apollo.QueryHookOptions<GetWorkbenchItemsVexQuery, GetWorkbenchItemsVexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkbenchItemsVexQuery, GetWorkbenchItemsVexQueryVariables>(GetWorkbenchItemsVexDocument, options);
      }
export function useGetWorkbenchItemsVexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkbenchItemsVexQuery, GetWorkbenchItemsVexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkbenchItemsVexQuery, GetWorkbenchItemsVexQueryVariables>(GetWorkbenchItemsVexDocument, options);
        }
export type GetWorkbenchItemsVexQueryHookResult = ReturnType<typeof useGetWorkbenchItemsVexQuery>;
export type GetWorkbenchItemsVexLazyQueryHookResult = ReturnType<typeof useGetWorkbenchItemsVexLazyQuery>;
export type GetWorkbenchItemsVexQueryResult = Apollo.QueryResult<GetWorkbenchItemsVexQuery, GetWorkbenchItemsVexQueryVariables>;
export const GetVkoWorkbenchRegulatoryDocumentParagraphsDocument = gql`
    query GetVkoWorkbenchRegulatoryDocumentParagraphs {
  vkoWorkbenchRegulatoryDocumentParagraphs {
    rejectReason
    rejectKeywordReason
    vexClusterName
    keywordName
    regulatoryDocumentParagraph {
      id
      createdAt
      modifiedAt
      enumeration
      keywords {
        id
        name
      }
      keywordAssignments {
        keyword {
          id
        }
        vexClusterAssignments {
          rejectReason
        }
      }
      parent {
        id
        name
        workflow {
          status
          message
        }
        regulation {
          id
          name
          regulationNumber
        }
      }
    }
  }
}
    `;

/**
 * __useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery__
 *
 * To run a query within a React component, call `useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery(baseOptions?: Apollo.QueryHookOptions<GetVkoWorkbenchRegulatoryDocumentParagraphsQuery, GetVkoWorkbenchRegulatoryDocumentParagraphsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVkoWorkbenchRegulatoryDocumentParagraphsQuery, GetVkoWorkbenchRegulatoryDocumentParagraphsQueryVariables>(GetVkoWorkbenchRegulatoryDocumentParagraphsDocument, options);
      }
export function useGetVkoWorkbenchRegulatoryDocumentParagraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVkoWorkbenchRegulatoryDocumentParagraphsQuery, GetVkoWorkbenchRegulatoryDocumentParagraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVkoWorkbenchRegulatoryDocumentParagraphsQuery, GetVkoWorkbenchRegulatoryDocumentParagraphsQueryVariables>(GetVkoWorkbenchRegulatoryDocumentParagraphsDocument, options);
        }
export type GetVkoWorkbenchRegulatoryDocumentParagraphsQueryHookResult = ReturnType<typeof useGetVkoWorkbenchRegulatoryDocumentParagraphsQuery>;
export type GetVkoWorkbenchRegulatoryDocumentParagraphsLazyQueryHookResult = ReturnType<typeof useGetVkoWorkbenchRegulatoryDocumentParagraphsLazyQuery>;
export type GetVkoWorkbenchRegulatoryDocumentParagraphsQueryResult = Apollo.QueryResult<GetVkoWorkbenchRegulatoryDocumentParagraphsQuery, GetVkoWorkbenchRegulatoryDocumentParagraphsQueryVariables>;