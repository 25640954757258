import {
	Checkbox,
	CommandButton,
	ICheckboxStyles,
	IStackItemStyles,
	Separator,
	Stack,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import React, {useState} from 'react';
import {PhaseDate, RegulatoryDocumentParagraph} from 'types';
import {TFunction} from 'react-i18next';
import {
	LocalizedDatePicker,
	LocalizedDatePickerProps,
} from 'components/hookForms';
import {isAfter, isValid, isWithinInterval, max, min, parseISO} from 'date-fns';
import {DEFAULT_DATE} from 'components/EntityList/EntityUtils';
import {EntityListColumn} from 'components';

type DateRange = {
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
};

interface IFilterOptions {
	(col: EntityListColumn, colValues: any[], strRe?: string): JSX.Element;
}

type FilterItemProps = {
	filterOptions: IFilterOptions;
	col: EntityListColumn;
	colValues: any[];
};

const checkboxStyles: ICheckboxStyles = {
	root: {paddingLeft: '5px'},
};

export type GridViewFilter = {
	key: string;
	columnKey: string;
	filterFn: (row: any) => boolean;
};

interface IFilterContentGridView {
	(
		baseColumns: EntityListColumn[],
		paragraphsFiltered: RegulatoryDocumentParagraph[],
		filters: GridViewFilter[],
		setFilters: (filters: GridViewFilter[]) => void,
		t: TFunction,
	): JSX.Element[];
}

export const getFilterContentGridView: IFilterContentGridView = (
	baseColumns,
	paragraphsFiltered,
	filters,
	setFilters,
	t,
) => {
	const getCheckboxChangeCallback =
		(
			col: EntityListColumn,
			getFilterFn: (match: any) => (item: any) => boolean,
		) =>
		(checked: boolean, match: any) => {
			let newFilters: GridViewFilter[] = [];
			if (checked) {
				const filter = {
					key: match,
					columnKey: col.fieldName!,
					filterFn: getFilterFn(match),
				};
				newFilters = [...filters, filter];
			} else {
				newFilters = filters.filter(f => f.key !== match);
			}
			setFilters(newFilters);
		};

	const renderArrayFieldOptions: IFilterOptions = (col, colValues, strRe?) => {
		const flatValues = colValues.flat();
		const reFilter = new RegExp(strRe ?? '', 'g');

		const {getFilterKey} = col;
		const getKey = getFilterKey ?? ((item: any) => item.id);

		const uniqueValues = [
			...new Map(flatValues.map(item => [getKey(item), item])).values(),
		].filter((val, _) => {
			if (typeof val === 'string') {
				return reFilter.test((val as string).toLowerCase());
			}
			if (typeof val.name === 'string') {
				return reFilter.test(val.name.toLowerCase());
			}
			return true;
		});

		const getFilterFn = (match: any) => (item: any) =>
			item[col.fieldName ?? '']?.some((val: any) => getKey(val) === match);
		const getBlankFilterFn = (_: any) => (item: any) =>
			item[col.fieldName ?? ''].length === 0;

		const onCheckboxChange = getCheckboxChangeCallback(col, getFilterFn);

		const onBlankCheckboxChange = getCheckboxChangeCallback(
			col,
			getBlankFilterFn,
		);
		const getLabel = col.getFilterLabel ?? ((val: any) => val.name);
		const sortedValues = uniqueValues.sort((v1, v2) =>
			getLabel(v1).localeCompare(getLabel(v2)),
		);

		return (
			<Stack tokens={{childrenGap: 4}}>
				<Checkbox
					label={`(${t('Blank', {ns: 'components/entitylist'})})`}
					styles={checkboxStyles}
					onChange={(_, checked) => onBlankCheckboxChange(Boolean(checked), '')}
				/>
				{sortedValues.map((val, j) => (
					<Checkbox
						key={j}
						label={getLabel(val)}
						styles={checkboxStyles}
						onChange={(_, checked) =>
							onCheckboxChange(Boolean(checked), getKey(val))
						}
					/>
				))}
			</Stack>
		);
	};

	const renderDateFieldOptions: IFilterOptions = (col, colValues, strRe?) => {
		const colValuesDateObjects = colValues
			.filter(date => date !== DEFAULT_DATE)
			.map(val => parseISO(val));
		if (colValuesDateObjects.length === 0) {
			colValuesDateObjects.push(new Date());
		}

		const earliestDate = min(colValuesDateObjects);
		const latestDate = max(colValuesDateObjects);

		const onRangeChange = (range: DateRange) => {
			const filterFn = (item: any) => {
				if (range.dateFrom && range.dateTo) {
					return isWithinInterval(parseISO(item[col.fieldName || '']), {
						start: range.dateFrom,
						end: range.dateTo,
					});
				}

				if (range.dateFrom) {
					return isAfter(parseISO(item[col.fieldName || '']), range.dateFrom);
				}

				if (range.dateTo) {
					return isAfter(parseISO(item[col.fieldName || '']), range.dateTo);
				}

				return true;
			};

			const {key} = col;
			const newFilters = [
				...filters.filter(f => f.key !== key),
				{
					key,
					columnKey: key,
					filterFn,
				},
			];

			setFilters(newFilters);
		};

		return (
			<DateRangePicker
				defaultDateFrom={earliestDate}
				defaultDateTo={latestDate}
				onRangeChange={onRangeChange}
			/>
		);
	};

	const arrDateFields = [
		'dateNewTypes',
		'dateNewRegistration',
		'comprehensive',
	];

	return baseColumns.map((col, i) => {
		let colValues: any[] = [];
		const {filterable, fieldName, getFieldValue} = col;

		if (!filterable) {
			return <div key={i}></div>;
		}

		if (getFieldValue) {
			colValues = paragraphsFiltered
				.map(item => getFieldValue(item))
				.filter(item => item !== null && item !== undefined);
		} else if (fieldName) {
			colValues = paragraphsFiltered
				.map(
					item => item[(fieldName ?? '') as keyof RegulatoryDocumentParagraph],
				)
				.filter(item => item !== null && item !== undefined);
		} else {
			return <div key={i}></div>;
		}

		if (!colValues.length) {
			return <div key={i}></div>;
		}

		let filterOptions: IFilterOptions = () => <></>;

		const isArrayField = arrDateFields.indexOf(col.fieldName ?? '') === -1;
		const isDateField = arrDateFields.indexOf(col.fieldName ?? '') !== -1;

		if (isArrayField) {
			filterOptions = renderArrayFieldOptions;
		}

		if (isDateField) {
			filterOptions = renderDateFieldOptions;
		}

		return (
			<div key={i}>
				{i !== 0 && <Separator />}
				<FilterItem
					filterOptions={filterOptions}
					col={col}
					colValues={colValues}
				/>
			</div>
		);
	});
};

const FilterItem: React.FC<FilterItemProps> = ({
	filterOptions,
	col,
	colValues,
}) => {
	const [isExpanded, {toggle: toggleExpanded}] = useBoolean(false);
	const [strFilterOptionsFilter, setsFilterOptionsFilter] =
		useState<string>('');

	const iconProps = React.useMemo(
		() => ({iconName: isExpanded ? 'ChevronUp' : 'ChevronDown'}),
		[isExpanded],
	);
	const {name} = col;

	const onChevronClick = React.useCallback(() => toggleExpanded(), []);
	const filterOptionsStyles: IStackItemStyles = React.useMemo(
		() => ({
			root: {
				display: isExpanded ? 'block' : 'none',
			},
		}),
		[isExpanded],
	);

	return (
		<Stack tokens={{childrenGap: 8}}>
			<CommandButton
				iconProps={iconProps}
				text={name}
				onClick={onChevronClick}
				ariaLabel={`Toggle filter ${name}`}
			/>
			<Stack.Item styles={filterOptionsStyles}>
				{filterOptions(col, colValues, strFilterOptionsFilter)}
			</Stack.Item>
		</Stack>
	);
};

const DateRangePicker: React.FC<{
	onRangeChange?: (range: DateRange) => void;
	defaultDateFrom?: Date;
	defaultDateTo?: Date;
	dateFromProps?: LocalizedDatePickerProps;
	dateToProps?: LocalizedDatePickerProps;
}> = ({
	onRangeChange,
	defaultDateFrom,
	defaultDateTo,
	dateFromProps,
	dateToProps,
}) => {
	const [dateFrom, setDateFrom] = React.useState<Date | undefined>(
		defaultDateFrom,
	);
	const [dateTo, setDateTo] = React.useState<Date | undefined>(defaultDateTo);
	const [range, setRange] = React.useState({dateFrom, dateTo});

	const onDateFromChange = React.useCallback(
		(date: Date | null | undefined) => {
			if (date) {
				setDateFrom(date);
				const newRange = {...range, dateFrom: date};
				setRange(newRange);
				if (onRangeChange) {
					onRangeChange(newRange);
				}
			}
		},
		[dateFrom, range, onRangeChange],
	);
	const onDateToChange = React.useCallback(
		(date: Date | null | undefined) => {
			if (date) {
				setDateTo(date);
				const newRange = {...range, dateTo: date};
				setRange(newRange);
				if (onRangeChange) {
					onRangeChange(newRange);
				}
			}
		},
		[dateTo, range, onRangeChange],
	);

	return (
		<Stack horizontal tokens={{childrenGap: 4}}>
			<LocalizedDatePicker
				{...dateFromProps}
				label='From'
				onSelectDate={onDateFromChange}
				value={dateFrom}
				maxDate={dateTo}
			/>
			<LocalizedDatePicker
				{...dateToProps}
				label='To'
				onSelectDate={onDateToChange}
				value={dateTo}
				minDate={dateFrom}
			/>
		</Stack>
	);
};
