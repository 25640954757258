import {Panel, PanelType, SelectionMode, Stack} from '@fluentui/react';
import React, {useEffect} from 'react';
import {useParagraphsContext} from '../ParagraphsContext';
import {RegulatoryDocument, UserRole} from 'types';
import {useCommand} from 'hooks';
import {DetailsField, DetailsSection} from 'components';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';
import {initFieldRowsFromParagraphDetails} from '../ParagraphDetails';
import {ViewButton, ViewButtonProps} from './ViewButton';
import {useRegDocTranslations} from 'features/RegulatoryDocuments/hooks/RegulatoryDocuments.commonHooks';
import {useSidebarInfoAndValidateIt} from '../../../../../context/SidebarInfoProvider';
import {RegDocSidebarInfoContext} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocSidebarInfoProvider';
import {useTranslation} from 'react-i18next';

type Props = Pick<ViewButtonProps, 'regulatoryDocument'>;

export const ViewParagraphsForm = ({
	regulatoryDocument,
}: Props): JSX.Element => {
	const {t} = useRegDocTranslations({
		keyPrefix: 'ParagraphDetailsPage',
	});
	const {t: tEnum} = useTranslation('common/enums');

	const {setSelectionMode} = useRegulatoryDocumentsContext();
	const {selectedParagraphs} = useParagraphsContext();
	const {isOpen, setIsOpen} = useSidebarInfoAndValidateIt(
		RegDocSidebarInfoContext,
	);

	useEffect(() => {
		if (isOpen) {
			setSelectionMode(SelectionMode.single);
		} else {
			setSelectionMode(SelectionMode.none);
		}
	}, [isOpen, setSelectionMode]);

	const handleCancelClick = React.useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const paragraphDetails = React.useMemo(
		() => selectedParagraphs[0] || {},
		[selectedParagraphs],
	);

	const initialFieldRows: DetailsField[][] = React.useMemo(
		() =>
			initFieldRowsFromParagraphDetails(
				paragraphDetails,
				false,
				t,
				tEnum,
				regulatoryDocument as RegulatoryDocument,
			),
		[paragraphDetails, t, tEnum],
	);

	useCommand(
		{
			key: 'paragraphDetailsSideBar',
			priority: 9,
			text: t('ParagraphDetailsSideBar'),
			iconProps: {iconName: 'View'},
			hidden: selectedParagraphs.length > 1,
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vex,
				UserRole.Vko,
				UserRole.VkoExternal,
				UserRole.ShApprover,
				UserRole.Readers,
				UserRole.RegulationReader,
			],
			onClick: () => setIsOpen(!isOpen),
			title: t('ParagraphDetailsSideBarTooltip'),
		},
		[t, selectedParagraphs, isOpen],
	);

	return (
		<Panel
			isLightDismiss
			type={PanelType.medium}
			isFooterAtBottom={true}
			onDismiss={handleCancelClick}
			isOpen={isOpen}
			isBlocking={false}
			closeButtonAriaLabel='Close'
		>
			<Stack styles={{root: {height: '100%', position: 'relative'}}}>
				<DetailsSection
					title={paragraphDetails?.enumeration || ''}
					initialFieldRows={initialFieldRows}
				/>
				{selectedParagraphs.length ? (
					<ViewButton
						regulatoryDocument={regulatoryDocument}
						paragraph={paragraphDetails}
					/>
				) : null}
			</Stack>
		</Panel>
	);
};
