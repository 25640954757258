import {Regulation} from 'types';
import {TFunction} from 'react-i18next';
import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';
import Excel from 'exceljs';

interface IRegulationsPage {
	(entityListItems: any[], t: TFunction): Promise<void>;
}

export const handleClickRegulations: IRegulationsPage = async (
	entityListItems,
	t,
) => {
	const filtRegulation = entityListItems as Regulation[];

	const workbook = new Excel.Workbook();
	const sheet = workbook.addWorksheet(
		'TRACE_' +
			t('Regulations', {
				ns: 'features/regulations',
				keyPrefix: 'RegulationsList',
			}),
	);
	const arrColWidth: number[] = [40, 80, 25, 40, 40, 40];
	const strPageName = t('Regulations', {
		ns: 'features/regulations',
		keyPrefix: 'RegulationsList',
	});

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'Regulations',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('RegulationNumber', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('Name', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('Markets', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('MainKeywords', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('KeyWords', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
			{
				name: t('StandardPlusPcmsClusters', {
					ns: 'features/regulations',
					keyPrefix: 'RegulationsList',
				}),
				filterButton: true,
			},
		],
		rows: [],
	});

	const table = sheet.getTable('Regulations');

	intRowCount++;
	for (let ii = 0; ii < filtRegulation.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			filtRegulation[ii].regulationNumber,
			filtRegulation[ii].name,
			filtRegulation[ii].markets.map(market => market.name).join(';\n'),
			filtRegulation[ii].mainKeywords.map(keyword => keyword.name).join(';\n'),
			filtRegulation[ii].keywords.map(keyword => keyword.name).join(';\n'),
			filtRegulation[ii].standardPlusPcmsClusters
				.map(PCMS => PCMS.name)
				.join(';\n'),
		]);
		intRowCount++;
	}

	table.commit();
	const strZipName = t('Regulations', {
		ns: 'features/regulations',
		keyPrefix: 'RegulationsList',
	});
	await createZIPXL(workbook, strZipName, t);
};
