import React from 'react';

export const parseTooltipNewlines = (tooltipContent: string): JSX.Element => {
	return tooltipContent.indexOf('\n') === -1 ? (
		<>{tooltipContent}</>
	) : (
		<>
			{tooltipContent.split('\n').map(tooltipPart => (
				<>
					{tooltipPart}
					<br />
				</>
			))}
		</>
	);
};
