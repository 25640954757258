import {
	DefaultButton,
	DialogFooter,
	FontWeights,
	IconButton,
	Modal,
	Stack,
	mergeStyleSets,
} from '@fluentui/react';
import React, {useState} from 'react';
import {getConfig} from 'config';

export const InfoModal = ({isModalOpen, hideModal}: InfoModalProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const config = getConfig();
	const toggleExpanded = () => {
		setIsExpanded(!isExpanded);
	};

	const date =
		config.REACT_APP_BUILD_TIMESTAMP_UTC === 'timestamp'
			? 'Unknown'
			: new Date(config.REACT_APP_BUILD_TIMESTAMP_UTC).toLocaleString();

	const MARGIN_LEFT = '20px';
	const labelStyles = {
		marginLeft: MARGIN_LEFT,
	};

	const valueStyles = {
		fontWeight: 'bold',
	};

	const closeStyles = {
		root: {
			marginLeft: 'auto',
			marginTop: '4px',
			marginRight: '4px',
			float: 'right',
			border: 'none',
		},
	};

	const buttonStyles = {
		root: {
			marginTop: '20px',
			marginBottom: '20px',
		},
	};

	const getMajorMinor = (version: string) => {
		const parts = version.split('.');
		return `${parts[0]}.${parts[1]}`;
	};

	return (
		<Modal
			titleAriaId='versionModal'
			isOpen={isModalOpen}
			onDismiss={hideModal}
			isBlocking={false}
			styles={{
				main: {
					width: isExpanded ? '900px' : '450px',
					height: isExpanded ? '260px' : '180px',
				},
			}}
		>
			<div
				className={modalStyles.header}
				style={{padding: `0 0 0 ${MARGIN_LEFT}`}}
			>
				<h2>TR:ACE Information</h2>
				<IconButton
					iconProps={{iconName: 'Cancel'}}
					className={modalStyles.icon}
					ariaLabel='Close popup modal'
					onClick={hideModal}
					styles={closeStyles}
				/>
			</div>
			<div className='basic-info'>
				<Stack>
					<Stack.Item>
						<span style={labelStyles}>{`Version: `}</span>
						<span style={valueStyles}>
							{getMajorMinor(config.REACT_APP_BUILD_VERSION)}
						</span>
					</Stack.Item>
					<Stack.Item>
						<span style={labelStyles}>{`Time: `}</span>
						<span style={valueStyles}>{date}</span>
					</Stack.Item>
					<Stack.Item></Stack.Item>
				</Stack>
			</div>

			<div className='show-more-container'>
				{isExpanded && (
					<Stack>
						<Stack.Item>
							<span style={labelStyles}>{`Full Version: `}</span>
							<span style={valueStyles}>
								{config.REACT_APP_BUILD_FULLVERSION}
							</span>
						</Stack.Item>
						<Stack.Item>
							<span style={labelStyles}>{`Informational Version: `}</span>
							<span style={valueStyles}>
								{config.REACT_APP_BUILD_INFORMATIONAL_VERSION}
							</span>
						</Stack.Item>
						<Stack.Item>
							<span style={labelStyles}>{`Build Number: `}</span>
							<span style={valueStyles}>{config.REACT_APP_BUILD_NUMBER}</span>
						</Stack.Item>
						<Stack.Item>
							<span style={labelStyles}>{`Build SHA: `}</span>
							<span style={valueStyles}>{config.REACT_APP_BUILD_SHA}</span>
						</Stack.Item>
						<Stack.Item></Stack.Item>
					</Stack>
				)}
			</div>
			<div className='button-container' style={{textAlign: 'center'}}>
				<DefaultButton
					styles={buttonStyles}
					onClick={async () => toggleExpanded()}
				>
					{isExpanded ? 'Hide Details' : 'Show More Details'}
				</DefaultButton>
			</div>
		</Modal>
	);
};

const modalStyles = mergeStyleSets({
	container: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'stretch',
	},
	header: [
		{
			flex: '1 1 auto',
			display: 'flex',
			alignItems: 'center',
			fontWeight: FontWeights.semibold,
			padding: '12px 12px 14px 24px',
		},
	],
	heading: {
		fontWeight: FontWeights.semibold,
		fontSize: 'inherit',
		margin: '0',
	},
	icon: {
		marginLeft: 'auto',
	},
});

export type InfoModalProps = {
	isModalOpen: boolean;
	hideModal: () => void;
};
