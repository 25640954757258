import {
	DefaultFile,
	FileUpload,
	FileWithMetadata,
} from 'components/hookForms/ControlledFileUpload';
import React, {Dispatch, SetStateAction} from 'react';
import {AttachmentCategory, AttachmentRef, Maybe} from 'types';

type Category = Pick<AttachmentCategory, 'id'>;

interface FileUploadAttachment extends Omit<AttachmentRef, 'category'> {
	category?: Maybe<Category>;
}

export interface EntityWithAttachments {
	attachments?: Maybe<Array<FileUploadAttachment>>;
}

export type PossibleAttachments = FileWithMetadata[] | undefined;

export const fileUploadDefaultValue = undefined;

export interface FileUploadInfo {
	attachments: PossibleAttachments;
	FileUploadComponent: () => JSX.Element;
	setAttachments: Dispatch<SetStateAction<PossibleAttachments>>;
}

export function useFileUpload<T extends EntityWithAttachments>(
	selectedItem: T | undefined,
	options?: {
		enableVkoOnlyOption?: boolean;
		renderCategory?: boolean;
	},
): FileUploadInfo {
	const defaultAttachments: DefaultFile[] = React.useMemo(
		() =>
			selectedItem?.attachments?.map(a => ({
				name: a.attachmentId,
				blob: a.file,
				attachmentCategoryId: a.category?.id,
				isVkoOnly: Boolean(a.isVkoOnly),
			})) ?? [],
		[selectedItem?.attachments],
	);

	const [attachments, setAttachments] = React.useState<PossibleAttachments>(
		fileUploadDefaultValue,
	);

	const FileUploadComponent = React.useCallback(
		() => (
			<FileUpload
				onChange={files => setAttachments(files)}
				defaultFiles={defaultAttachments}
				enableVkoOnlyOption={options?.enableVkoOnlyOption}
				renderCategory={options?.renderCategory}
			/>
		),
		[defaultAttachments],
	);

	return {
		attachments,
		FileUploadComponent,
		setAttachments,
	};
}
