import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllKeywordsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllKeywordsQuery = { __typename?: 'Query', keywords?: Array<{ __typename?: 'Keyword', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, sortPriority?: number | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, regulationClusters: Array<{ __typename?: 'RegulationCluster', id: string, name: string }>, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, vkos: Array<{ __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }>, approvers: Array<{ __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }> }> | null };

export type GetKeywordsFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetKeywordsFormDataQuery = { __typename?: 'Query', keywords?: Array<{ __typename?: 'Keyword', id: string, name: string }> | null };

export type GetRegulationClustersFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRegulationClustersFormDataQuery = { __typename?: 'Query', regulationClusters?: Array<{ __typename?: 'RegulationCluster', id: string, name: string }> | null };

export type GetVexClustersFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVexClustersFormDataQuery = { __typename?: 'Query', vexClusters?: Array<{ __typename?: 'VexCluster', id: string, name: string }> | null };

export type CreateKeywordMutationVariables = Types.Exact<{
  input: Types.CreateKeywordInput;
}>;


export type CreateKeywordMutation = { __typename?: 'Mutation', createKeyword: { __typename?: 'CreateKeywordPayload', keyword?: { __typename?: 'Keyword', id: string } | null } };

export type UpdateKeywordMutationVariables = Types.Exact<{
  input: Types.UpdateKeywordInput;
}>;


export type UpdateKeywordMutation = { __typename?: 'Mutation', updateKeyword: { __typename?: 'UpdateKeywordPayload', keyword?: { __typename?: 'Keyword', id: string } | null } };

export type DeleteKeywordMutationVariables = Types.Exact<{
  input: Types.DeleteKeywordInput;
}>;


export type DeleteKeywordMutation = { __typename?: 'Mutation', deleteKeyword: { __typename?: 'DeleteKeywordPayload', keyword?: { __typename?: 'Keyword', id: string } | null } };


export const GetAllKeywordsDocument = gql`
    query GetAllKeywords {
  keywords {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    regulationClusters {
      id
      name
    }
    vexClusters {
      id
      name
    }
    vkos {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    approvers {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    sortPriority
  }
}
    `;

/**
 * __useGetAllKeywordsQuery__
 *
 * To run a query within a React component, call `useGetAllKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllKeywordsQuery, GetAllKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllKeywordsQuery, GetAllKeywordsQueryVariables>(GetAllKeywordsDocument, options);
      }
export function useGetAllKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllKeywordsQuery, GetAllKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllKeywordsQuery, GetAllKeywordsQueryVariables>(GetAllKeywordsDocument, options);
        }
export type GetAllKeywordsQueryHookResult = ReturnType<typeof useGetAllKeywordsQuery>;
export type GetAllKeywordsLazyQueryHookResult = ReturnType<typeof useGetAllKeywordsLazyQuery>;
export type GetAllKeywordsQueryResult = Apollo.QueryResult<GetAllKeywordsQuery, GetAllKeywordsQueryVariables>;
export const GetKeywordsFormDataDocument = gql`
    query GetKeywordsFormData {
  keywords {
    id
    name
  }
}
    `;

/**
 * __useGetKeywordsFormDataQuery__
 *
 * To run a query within a React component, call `useGetKeywordsFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordsFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordsFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKeywordsFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetKeywordsFormDataQuery, GetKeywordsFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeywordsFormDataQuery, GetKeywordsFormDataQueryVariables>(GetKeywordsFormDataDocument, options);
      }
export function useGetKeywordsFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeywordsFormDataQuery, GetKeywordsFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeywordsFormDataQuery, GetKeywordsFormDataQueryVariables>(GetKeywordsFormDataDocument, options);
        }
export type GetKeywordsFormDataQueryHookResult = ReturnType<typeof useGetKeywordsFormDataQuery>;
export type GetKeywordsFormDataLazyQueryHookResult = ReturnType<typeof useGetKeywordsFormDataLazyQuery>;
export type GetKeywordsFormDataQueryResult = Apollo.QueryResult<GetKeywordsFormDataQuery, GetKeywordsFormDataQueryVariables>;
export const GetRegulationClustersFormDataDocument = gql`
    query GetRegulationClustersFormData {
  regulationClusters {
    id
    name
  }
}
    `;

/**
 * __useGetRegulationClustersFormDataQuery__
 *
 * To run a query within a React component, call `useGetRegulationClustersFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegulationClustersFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegulationClustersFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegulationClustersFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetRegulationClustersFormDataQuery, GetRegulationClustersFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegulationClustersFormDataQuery, GetRegulationClustersFormDataQueryVariables>(GetRegulationClustersFormDataDocument, options);
      }
export function useGetRegulationClustersFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegulationClustersFormDataQuery, GetRegulationClustersFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegulationClustersFormDataQuery, GetRegulationClustersFormDataQueryVariables>(GetRegulationClustersFormDataDocument, options);
        }
export type GetRegulationClustersFormDataQueryHookResult = ReturnType<typeof useGetRegulationClustersFormDataQuery>;
export type GetRegulationClustersFormDataLazyQueryHookResult = ReturnType<typeof useGetRegulationClustersFormDataLazyQuery>;
export type GetRegulationClustersFormDataQueryResult = Apollo.QueryResult<GetRegulationClustersFormDataQuery, GetRegulationClustersFormDataQueryVariables>;
export const GetVexClustersFormDataDocument = gql`
    query GetVexClustersFormData {
  vexClusters {
    id
    name
  }
}
    `;

/**
 * __useGetVexClustersFormDataQuery__
 *
 * To run a query within a React component, call `useGetVexClustersFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVexClustersFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVexClustersFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVexClustersFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetVexClustersFormDataQuery, GetVexClustersFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVexClustersFormDataQuery, GetVexClustersFormDataQueryVariables>(GetVexClustersFormDataDocument, options);
      }
export function useGetVexClustersFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVexClustersFormDataQuery, GetVexClustersFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVexClustersFormDataQuery, GetVexClustersFormDataQueryVariables>(GetVexClustersFormDataDocument, options);
        }
export type GetVexClustersFormDataQueryHookResult = ReturnType<typeof useGetVexClustersFormDataQuery>;
export type GetVexClustersFormDataLazyQueryHookResult = ReturnType<typeof useGetVexClustersFormDataLazyQuery>;
export type GetVexClustersFormDataQueryResult = Apollo.QueryResult<GetVexClustersFormDataQuery, GetVexClustersFormDataQueryVariables>;
export const CreateKeywordDocument = gql`
    mutation CreateKeyword($input: CreateKeywordInput!) {
  createKeyword(input: $input) {
    keyword {
      id
    }
  }
}
    `;
export type CreateKeywordMutationFn = Apollo.MutationFunction<CreateKeywordMutation, CreateKeywordMutationVariables>;

/**
 * __useCreateKeywordMutation__
 *
 * To run a mutation, you first call `useCreateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeywordMutation, { data, loading, error }] = useCreateKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKeywordMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeywordMutation, CreateKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKeywordMutation, CreateKeywordMutationVariables>(CreateKeywordDocument, options);
      }
export type CreateKeywordMutationHookResult = ReturnType<typeof useCreateKeywordMutation>;
export type CreateKeywordMutationResult = Apollo.MutationResult<CreateKeywordMutation>;
export type CreateKeywordMutationOptions = Apollo.BaseMutationOptions<CreateKeywordMutation, CreateKeywordMutationVariables>;
export const UpdateKeywordDocument = gql`
    mutation UpdateKeyword($input: UpdateKeywordInput!) {
  updateKeyword(input: $input) {
    keyword {
      id
    }
  }
}
    `;
export type UpdateKeywordMutationFn = Apollo.MutationFunction<UpdateKeywordMutation, UpdateKeywordMutationVariables>;

/**
 * __useUpdateKeywordMutation__
 *
 * To run a mutation, you first call `useUpdateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeywordMutation, { data, loading, error }] = useUpdateKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKeywordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKeywordMutation, UpdateKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKeywordMutation, UpdateKeywordMutationVariables>(UpdateKeywordDocument, options);
      }
export type UpdateKeywordMutationHookResult = ReturnType<typeof useUpdateKeywordMutation>;
export type UpdateKeywordMutationResult = Apollo.MutationResult<UpdateKeywordMutation>;
export type UpdateKeywordMutationOptions = Apollo.BaseMutationOptions<UpdateKeywordMutation, UpdateKeywordMutationVariables>;
export const DeleteKeywordDocument = gql`
    mutation DeleteKeyword($input: DeleteKeywordInput!) {
  deleteKeyword(input: $input) {
    keyword {
      id
    }
  }
}
    `;
export type DeleteKeywordMutationFn = Apollo.MutationFunction<DeleteKeywordMutation, DeleteKeywordMutationVariables>;

/**
 * __useDeleteKeywordMutation__
 *
 * To run a mutation, you first call `useDeleteKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeywordMutation, { data, loading, error }] = useDeleteKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteKeywordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKeywordMutation, DeleteKeywordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKeywordMutation, DeleteKeywordMutationVariables>(DeleteKeywordDocument, options);
      }
export type DeleteKeywordMutationHookResult = ReturnType<typeof useDeleteKeywordMutation>;
export type DeleteKeywordMutationResult = Apollo.MutationResult<DeleteKeywordMutation>;
export type DeleteKeywordMutationOptions = Apollo.BaseMutationOptions<DeleteKeywordMutation, DeleteKeywordMutationVariables>;