import {useUserContext} from 'authentication/UserContext';
import {PageRegulatoryDocs} from 'features/Regulations/RegulationDetail/RegulationDetail.types';
import {isPogisDocument} from 'features/RegulatoryDocuments/regulatoryUtils';
import React from 'react';
import {RegulatoryDocument, WorkflowStatus} from 'types';

export const useRegulatoryDocAuthorization = () => {
	const {
		encodedUserId,
		isAdmin,
		isVko,
		isVex,
		isApprover,
		isReader,
		isRegulationReader,
	} = useUserContext();

	const filterAuthorizedRegulatoryDocuments = React.useCallback(
		(regDocs: PageRegulatoryDocs) => {
			return regDocs.filter(elem => {
				if (elem?.workflow.status === WorkflowStatus.Modified) {
					return (
						isAdmin ||
						(elem.createdBy?.id === encodedUserId && isVko) ||
						elem.workflow?.reapprovingVKO?.id === encodedUserId
					);
				}

				return true;
			});
		},
		[encodedUserId, isAdmin, isRegulationReader],
	);

	const isRegulationRowDisabled = React.useCallback(
		(status: WorkflowStatus): boolean => {
			if (status === WorkflowStatus.Finalized) return false;

			return isVex || isReader || isRegulationReader;
		},
		[isVex, isReader, isRegulationReader],
	);

	const isViewParagraphsDisabled = React.useCallback(
		(selectedRegulatoryDocument?: Partial<RegulatoryDocument>): boolean => {
			if (
				selectedRegulatoryDocument?.workflow?.status ===
				WorkflowStatus.Finalized
			)
				return false;

			return (
				(isVex || isApprover || isRegulationReader) &&
				!isPogisDocument(selectedRegulatoryDocument)
			);
		},
		[isVex, isApprover, isRegulationReader],
	);

	return {
		filterAuthorizedRegulatoryDocuments,
		isRegulationRowDisabled,
		isViewParagraphsDisabled,
	};
};
