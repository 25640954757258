import {
	Country,
	ReferenceType,
	RegulationCluster,
	User,
	VexCluster,
} from 'types';
import {TFunction} from 'react-i18next';

import Excel from 'exceljs';

import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';

interface IAdminDetailPage {
	(filtList: any[], strAdminPage: string, t: TFunction): Promise<void>;
}

interface IColXLProps {
	name: string;
	filterButton: boolean;
}

export const handleClickAdminPage: IAdminDetailPage = async (
	filtList,
	strAdminPage,
	t,
) => {
	const strTrNS = 'features/adminsection';

	let arrCols: IColXLProps[];
	let arrColWidth: number[];
	let strPage: string;
	let strTrPrefix: string;
	switch (strAdminPage) {
		case 'markets':
			strTrPrefix = 'AdminMarketsPage';
			strPage = t('Markets', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Market', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('namingTemplates', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('Countries', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 40, 40, 20];
			break;
		case 'regulationsClusters':
			strTrPrefix = 'AdminRegulationsClusterPage';
			strPage = t('RegulationsClusters', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('RegulationCluster', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'vehicleCategories':
			strTrPrefix = 'AdminVehicleCategoriesPage';
			strPage = t('VehicleCategories', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('VehicleCategory', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'driveVariants':
			strTrPrefix = 'AdminDriveVariantsPage';
			strPage = t('DriveVariants', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('DriveVariants', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'engineVariants':
			strTrPrefix = 'AdminEngineVariantsPage';
			strPage = t('EngineVariants', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('EngineVariants', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'gearboxVariants':
			strTrPrefix = 'AdminGearboxVariantsPage';
			strPage = t('GearboxVariants', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Getriebevarianten', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'bodyworkVariants':
			strTrPrefix = 'AdminBodyworkVariantsPage';
			strPage = t('BodyworkVariants', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Getriebevarianten', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'activeTypeMarkets':
			strTrPrefix = 'AdminActiveTypeMarketsPage';
			strPage = t('ActiveTypeMarkets', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('ActiveTypeMarkets', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'categories':
			strTrPrefix = 'AdminCategoriesPage';
			strPage = t('Categories', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Categories', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'keywords':
			strTrPrefix = 'AdminKeywordsPage';
			strPage = t('Keywords', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Keywords', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('RegulationClusters', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('Vkos', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('VexClusters', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('SHApprovers', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 40, 40, 40, 40];
			break;
		case 'vexClusters':
			strTrPrefix = 'AdminVexClustersPage';
			strPage = t('VexClusters', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('VexClusters', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('Persons', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('SHApprovers', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 40, 40, 20];
			break;
		case 'tags':
			strTrPrefix = 'AdminTagsPage';
			strPage = t('Tags', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Tag', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'pcmsClusters':
			strTrPrefix = 'AdminPcmsClustersPage';
			strPage = t('PcmsClusters', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('PcmsClusters', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'systemLevels':
			strTrPrefix = 'AdminSystemLevelsPage';
			strPage = t('SystemLevels', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('SystemLevel', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'attachmentCategories':
			strTrPrefix = 'AdminAttachmentCategoriesPage';
			strPage = t('AttachmentCategories', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('AttachmentCategory', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'references':
			strTrPrefix = 'AdminReferencePage';
			strPage = t('References', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Reference', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
				{
					name: t('ReferenceTypes', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 40, 20, 20];
			break;
		case 'documentSources':
			strTrPrefix = 'AdminDocumentSourcesPage';
			strPage = t('DocumentSources', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('DocumentSource', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		case 'countries':
			strTrPrefix = 'AdminCountriesPage';
			strPage = t('Countries', {
				ns: 'appShell/sidenavigation',
				keyPrefix: 'SideNavigation',
			});
			arrCols = [
				{
					name: t('Country', {ns: strTrNS, keyPrefix: strTrPrefix}),
					filterButton: true,
				},
			];
			arrColWidth = [40, 20, 20, 20];
			break;
		default:
			strTrPrefix = '';
			strPage = '';
			arrCols = [];
			arrColWidth = [];
	}

	const workbook = new Excel.Workbook();
	const sheet = workbook.addWorksheet('TRACE_' + strPage);
	renderHeaderXL(workbook, sheet, arrColWidth, strPage, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};
	sheet.addTable({
		name: strAdminPage,
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: arrCols,
		rows: [],
	});

	intRowCount++;
	const table = sheet.getTable(strAdminPage);
	for (let ii = 0; ii < filtList.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		switch (strAdminPage) {
			case 'markets':
				table.addRow([
					filtList[ii].name,
					t('RegulationNumber', {ns: strTrNS, keyPrefix: strTrPrefix}) +
						':' +
						filtList[ii].regulationNamingTemplates.length +
						'\n' +
						t('RegulatoryDocumentName', {ns: strTrNS, keyPrefix: strTrPrefix}) +
						':' +
						filtList[ii].regulatoryDocumentNamingTemplates.length,
					(filtList[ii].countries as Country[]).map(x => x.name).join(';\n'),
				]);
				break;
			case 'keywords':
				table.addRow([
					filtList[ii].name,
					(filtList[ii].regulationClusters as RegulationCluster[])
						.map(x => x.name)
						.join(';\n'),
					(filtList[ii].vkos as User[]).map(x => x.name).join(';\n'),
					(filtList[ii].vexClusters as VexCluster[])
						.map(x => x.name)
						.join(';\n'),
					(filtList[ii].approvers as User[]).map(x => x.name).join(';\n'),
				]);
				break;
			case 'vexClusters':
				table.addRow([
					filtList[ii].name,
					(filtList[ii].users as User[]).map(x => x.name).join(';\n'),
					(filtList[ii].approvers as User[]).map(x => x.name).join(';\n'),
				]);
				break;
			case 'references':
				table.addRow([
					filtList[ii].name,
					(filtList[ii].referenceTypes as ReferenceType[])
						.map(x =>
							t('References.' + x, {ns: strTrNS, keyPrefix: strTrPrefix}),
						)
						.join(';\n'),
				]);
				break;
			default:
				table.addRow([filtList[ii].name]);
		}

		intRowCount++;
	}

	table.commit();
	const strZipName = strPage;
	await createZIPXL(workbook, strZipName, t);
};
