import {FC} from 'react';
import {Controller} from 'react-hook-form';
import * as React from 'react';
import {HookFormProps} from './HookFormProps';

type ControlledRadioGroup = HookFormProps & {
	label?: string;
	required: boolean;
	values: any[];
	translations: string[];
	onValueChanged: (val: string) => void;
};

export const ControlledRadioGroup: FC<ControlledRadioGroup> = props => {
	if (props.values.length !== props.translations.length) {
		console.error('Invalid translations provided for radio button');
	}

	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			defaultValue={props.defaultValue || false}
			render={({field: {onChange, value}}) => (
				<fieldset
					onChange={(e: any) => props.onValueChanged(e.target.value as string)}
					style={{border: 'none', padding: '10px 0 0 0'}}
				>
					{props.label && <legend>{props.label}</legend>}
					{props.values.map((val, index) => {
						return (
							<div key={index}>
								<input
									type='radio'
									id={val}
									name={props.name}
									value={val}
									defaultChecked={val === props.defaultValue}
								/>
								<label htmlFor={val}>{props.translations[index]}</label>
							</div>
						);
					})}
				</fieldset>
			)}
		/>
	);
};
