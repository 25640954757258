import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	IDialogProps,
	IPersona,
	PrimaryButton,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import React, {useEffect} from 'react';

import {Requirement, RequirementChangeType, User, UserRole} from 'types';

export enum SendApprovalDialogChangeTypeMode {
	Show,
	Hide,
	Disable,
}

export type ConfirmWithoutApprovalDialogProps = Omit<
	IDialogProps,
	'modalProps'
> & {
	onConfirm: () => void;
	requirementName: string;
	setChangeType: (changeType: RequirementChangeType) => void;
};

export const ConfirmWithoutApproval: React.FC<
	ConfirmWithoutApprovalDialogProps
> = ({requirementName, setChangeType, ...props}) => {
	const {t} = useTranslation('features/requirements', {
		keyPrefix: 'WorkflowDialog',
	});

	const dismiss = () => {
		props?.onDismiss?.();
	};

	const dialogContentProps: IDialogContentProps = React.useMemo(
		() => ({
			type: DialogType.normal,
			title: t('CompleteWithoutApproval'),
			subText: t('ConfirmWitoutApprovalText', {name: requirementName}),
		}),
		[],
	);

	const modalProps = {
		isBlocking: true,
		styles: {main: {maxWidth: 450}},
	};

	return (
		<Dialog
			dialogContentProps={dialogContentProps}
			{...props}
			onDismiss={dismiss}
			modalProps={modalProps}
		>
			<DialogFooter>
				<PrimaryButton
					onClick={() => {
						setChangeType(RequirementChangeType.Editorial);
						props?.onConfirm?.();
					}}
				>
					{t('ApprovalConfirmButton')}
				</PrimaryButton>
				<DefaultButton onClick={dismiss} text={t('CancelButton')} />
			</DialogFooter>
		</Dialog>
	);
};
