import {RequirementStatus} from 'types';
import {RequirementFromDetailsPage} from '../RequirementDetailsPage/requirementDetailsPage.types';
import {RequirementFromListPage} from '../requirementPage.types';

export const useRequirementsVersions = () => {
	const getInitialVersion = (): string => 'V1.0';

	const getVersionMatches = (
		currVersion: string,
	): {succeeded: boolean; major: number; minor: number} => {
		const regexp = /V(\d+)\.(\d+)/g;
		const matches = [...currVersion.matchAll(regexp)];
		if (matches.length === 1 && matches[0].length === 3) {
			const major = Number(matches[0][1]);
			const minor = Number(matches[0][2]);
			return {succeeded: true, major, minor};
		}

		console.error(`Invalid version '${currVersion}'. Cannot increment`);
		return {succeeded: false, major: 1, minor: 0};
	};

	const incrementMinorVersion = (currVersion: string): string => {
		const versions = getVersionMatches(currVersion);
		if (versions.succeeded) {
			return `V${versions.major}.${versions.minor + 1}`;
		}

		return currVersion;
	};

	const incrementMajorVersion = (currVersion: string): string => {
		const versions = getVersionMatches(currVersion);
		if (versions.succeeded) {
			return `V${versions.major + 1}.0`;
		}

		return currVersion;
	};

	const hasEditableChildren = (
		requirement?: RequirementFromDetailsPage | RequirementFromListPage,
	) => {
		if (!requirement || (requirement?.childrenRefs?.length || 0) === 0)
			return false;

		const reqMatches = getVersionMatches(
			requirement?.version || getInitialVersion(),
		);
		const myMajorVersion = reqMatches.major;

		const childIndex = requirement.childrenRefs?.findIndex(child => {
			if (child?.version) {
				const {major} = getVersionMatches(child.version);
				return major === myMajorVersion;
			}

			return false;
		});
		return childIndex !== -1;
	};

	const isHigherRequirementVersion = (
		major: number,
		minor: number,
		currentVersion: string,
	) => {
		const {major: currentMajor, minor: currentMinor} =
			getVersionMatches(currentVersion);
		return (
			major > currentMajor || (major === currentMajor && minor > currentMinor)
		);
	};

	/*
	User should be able to select only highest minor version of requirements in final state.
	Example:
	requirement 1 in final state versions: 1.0, 1.1, 1.5, 2.4, 2.7, 4.1
	user should be able to use only versions: 1.5, 2.7, 4.1
	*/
	const filterOutPastMinorVersionOfRequirements = (data: any) => {
		const finalRequirements: any = {};

		data.forEach((obj: any) => {
			const {status, requirementId, version, id} = obj;
			if (status !== RequirementStatus.Final || !version) {
				finalRequirements[id] = obj;
				return;
			}

			const {major, minor} = getVersionMatches(version);
			const key = `${requirementId}-${major}`;

			const isNewVersion = !finalRequirements[key];

			if (isNewVersion) {
				finalRequirements[key] = obj;
				return;
			}

			if (
				isHigherRequirementVersion(major, minor, finalRequirements[key].version)
			) {
				finalRequirements[key] = obj;
			}
		});

		return Object.values(finalRequirements);
	};

	return {
		getInitialVersion,
		incrementMinorVersion,
		incrementMajorVersion,
		hasEditableChildren,
		getVersionMatches,
		filterOutPastMinorVersionOfRequirements,
	};
};
