import {PersonaSize} from '@fluentui/react';
import {EntityListColumn} from 'components';
import {CellWithEntityLink} from 'components/EntityList/CellWithEntityLink';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {
	renderArrayField,
	renderAttachments,
	renderDate,
	renderPersona,
	renderReferenceField,
	renderRequirementCategory,
	renderRequirementStatus,
	renderRichtext,
} from 'components/EntityList/ColumnRenderers';
import {DATE_TYPE} from 'components/EntityList/EntityUtils';
import {FaqComponent} from 'components/Faq/FaqComponent';
import {renderKeywordsCell} from 'components/Keywords/Keywords';
import React, {useCallback, useMemo} from 'react';

import {useTranslation} from 'react-i18next';
import {RequirementStatus} from 'types';
import {createHrefFromRequirement} from '../requirementLinks.utils';
import {RequirementFromListPage} from '../requirementPage.types';
import {useRequirementsTranslation} from './useRequirementsTranslation';

type OptionalColumnFieldNames = 'minWidth' | 'maxWidth' | 'filterable';
type OptionalColumnFields = Partial<
	Pick<EntityListColumn, OptionalColumnFieldNames>
>;
type Column = Omit<EntityListColumn, OptionalColumnFieldNames> &
	OptionalColumnFields;

const createColumn = ({
	key,
	name,
	fieldName,
	filterable = false,
	minWidth = 150,
	maxWidth = 200,
	onRender,
	getFilterLabel,

	...other
}: Column): EntityListColumn => ({
	key,
	name,
	fieldName,
	filterable,
	minWidth,
	maxWidth,
	isResizable: true,
	onRender,
	getFilterLabel,

	...other,
});

/**
 * Some components have custom requirement types to ensure that they the
 * requirements have the fields to render these columns. If you update this hook
 * to require new requirement fields, update any components' requirement types
 * to reflect that.
 */
export const useRequirementColumns = (
	shouldShowFaq = false,
): EntityListColumn[] => {
	const {t} = useRequirementsTranslation();
	const {t: tEnum} = useTranslation('common/enums');

	const createCustomColumn = useCallback(
		({name, filterable = true, ...other}: Column): EntityListColumn => {
			return createColumn({name: t(name), filterable, ...other});
		},
		[t],
	);

	/**
	 * Use @see createCustomColumn from now on instead.
	 */
	const createCustomColumnFromParams = useCallback(
		(
			key: string,
			name: string,
			fieldName: string,
			filterable: boolean,
			onRender?: EntityListColumn['onRender'],
			getFilterLabel?: any,
			filterOnFilter?: boolean,
		): EntityListColumn =>
			createCustomColumn({
				key,
				name,
				fieldName,
				filterable,
				onRender,
				getFilterLabel,
				filterOnFilter,
			}),
		[createCustomColumn],
	);

	const renderRequirementName: EntityListColumn['onRender'] = (
		requirement: RequirementFromListPage,
	) => {
		if (!requirement.name) return null;
		return (
			<CellWithEntityLink href={createHrefFromRequirement(requirement)}>
				{requirement.status === RequirementStatus.Interim ||
				requirement.status === RequirementStatus.InterimCompleted ? (
					<div style={{color: 'gray'}}>
						<i>{requirement.name}</i>
					</div>
				) : (
					requirement.name
				)}
			</CellWithEntityLink>
		);
	};

	const renderRequirementId: EntityListColumn['onRender'] = (
		requirement: RequirementFromListPage,
	) => {
		if (!requirement.requirementId) return null;
		return (
			<CellWithEntityLink href={createHrefFromRequirement(requirement)}>
				{requirement.requirementId}
			</CellWithEntityLink>
		);
	};

	const renderFaq: EntityListColumn['onRender'] = (
		requirement: RequirementFromListPage,
	) => {
		if (!requirement.id) return null;

		return <FaqComponent questions={(requirement as any).question} />;
	};

	const getDisplayNameForStatus = useCallback(
		(item: string) => {
			return tEnum(`RequirementStatus.${item}`);
		},
		[tEnum],
	);

	const getDisplayRequirementCategory = useCallback(
		(item: string) => {
			return tEnum(`RequirementCategory.${item}`);
		},
		[tEnum],
	);

	const sortKeyForInvalidRequirementId = -1;

	const getRequirementIdAsNumber = useCallback(
		(requirementId: string): number => {
			const withoutPrefix: string = requirementId.replace('R-', '');
			return parseInt(withoutPrefix, 10);
		},
		[],
	);

	const getSortKeyForRequirementId = useCallback(
		(requirementId: RequirementFromListPage['requirementId']): number => {
			if (!requirementId) return sortKeyForInvalidRequirementId;
			const idAsNumber: number = getRequirementIdAsNumber(requirementId);
			if (Number.isNaN(idAsNumber)) return sortKeyForInvalidRequirementId;
			return idAsNumber;
		},
		[getRequirementIdAsNumber, sortKeyForInvalidRequirementId],
	);

	return useMemo(() => {
		const baseColumns = [
			{
				...createCustomColumnFromParams(
					'attachments',
					'Attachments',
					'attachments',
					false,
					renderAttachments(),
				),
				isIconOnly: true,
				iconName: 'Attach',
				minWidth: 40,
			},
			createCustomColumn({
				key: 'nameColumn',
				name: 'Name',
				fieldName: 'name',
				filterable: true,
				onRender: renderRequirementName,
				sortable: true,
				filterOnFilter: true,
			}),
			createCustomColumn({
				key: 'column2',
				name: 'RequirementId',
				fieldName: 'requirementId',
				filterable: true,
				onRender: renderRequirementId,
				sortable: true,
				getSortKey: getSortKeyForRequirementId,
				filterOnFilter: true,
			}),
			createCustomColumnFromParams(
				'column3',
				'VexCluster',
				'vexClusters',
				true,
				renderArrayField(),
				undefined,
				true,
			),
			createCustomColumn({
				key: 'column4',
				name: 'Status',
				fieldName: 'status',
				filterable: true,
				onRender: renderRequirementStatus(),
				getFilterLabel: getDisplayNameForStatus,
				sortable: true,
				getSortKey: getDisplayNameForStatus,
			}),
			createCustomColumnFromParams(
				'column5',
				'Definition',
				'definition',
				false,
				renderRichtext(),
			),
			createCustomColumnFromParams(
				'column6',
				'SystemLevels',
				'systemLevels',
				true,
				renderArrayField(),
			),
			createCustomColumnFromParams(
				'markets',
				'Markets',
				'markets',
				true,
				renderArrayField(),
			),
			createCustomColumnFromParams(
				'keywords',
				'Keywords',
				'keywords',
				true,
				renderKeywordsCell,
				undefined,
				true,
			),
			createCustomColumnFromParams(
				'tags',
				'Tags',
				'tags',
				true,
				renderArrayField(),
			),
			createCustomColumn({
				key: 'column7',
				name: 'CreatedBy',
				fieldName: 'createdBy',
				filterable: true,
				onRender: renderPersona({size: PersonaSize.size8}),
				sortable: true,
				getSortKey(createdBy: RequirementFromListPage['createdBy']): string {
					return createdBy.name;
				},
			}),
			createCustomColumn({
				key: 'column8',
				name: 'ModifiedAt',
				fieldName: 'modifiedAt',
				filterable: true,
				onRender: renderDate(),
				dataType: DATE_TYPE,
				sortable: true,
			}),
			createCustomColumn({
				key: 'category',
				name: 'Category',
				fieldName: 'category',
				filterable: true,
				onRender: renderRequirementCategory(),
				getFilterLabel: getDisplayRequirementCategory,
				sortable: true,
				getSortKey: getDisplayRequirementCategory,
			}),
			createCustomColumnFromParams('version', 'Version', 'version', true),
			{
				key: 'references',
				name: t('References'),
				isIconOnly: true,
				iconName: 'Link',
				fieldName: 'documentReferences',
				minWidth: 300,
				onRender: renderReferenceField(),
			},
		];
		if (shouldShowFaq && (isDevEnvironment() || isTestEnvironment()))
			return baseColumns.concat(
				createCustomColumn({
					key: 'FAQ',
					name: 'FAQs',
					fieldName: 'faq',
					onRender: renderFaq,
				}),
			);

		return baseColumns;
	}, [
		createCustomColumnFromParams,
		getDisplayNameForStatus,
		t,
		createCustomColumn,
		getSortKeyForRequirementId,
	]);
};
