import {Breadcrumb, IBreadcrumbItem} from '@fluentui/react';
import {DetailsField, DetailsSection} from 'components';
import {CommentForm} from 'components/CommentForm';
import {CommentLevel} from 'components/CommentList';
import {renderParagraph} from 'components/EntityList/ColumnRenderers';
import {FAQForm} from 'components/FAQForm';
import {FAQLevel} from 'components/FAQList';
import {LoadWrapper} from 'components/LoadWrapper';
import {useGetParagraphDetailsQuery} from 'features/RegulatoryDocuments/hooks/useGetParagraphDetails.generated';
import {useCommand, useFavoriteCommand} from 'hooks';
import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {RegulatoryDocument, RegulatoryDocumentParagraph} from 'types';

export const initFieldRowsFromParagraphDetails = (
	paragraphDetails: RegulatoryDocumentParagraph,
	showAudit: boolean,
	t: TFunction,
	tEnum: TFunction,
	regulatoryDocument?: RegulatoryDocument,
): DetailsField[][] => {
	const elements = renderParagraph(paragraphDetails, t, false);
	const status =
		paragraphDetails?.parent?.workflow?.status ??
		regulatoryDocument?.workflow?.status;
	return [
		[
			{
				data: elements,
				type: 'text',
				label: t('ElementsTextLabel'),
			},
		],
		[
			{
				data: paragraphDetails ? `${paragraphDetails.page}` : '',
				type: 'text',
				label: t('PageLabel'),
			},
			{
				data: tEnum(`WorkflowStatus.${status}`),
				type: 'text',
				label: t('WorkflowStatus'),
			},
		],
		[
			{
				data: paragraphDetails?.summary ?? '',
				type: 'richtext',
				label: t('SummaryLabel'),
				audit: showAudit,
				auditFieldName: 'summary',
			},
		],
		[
			{
				data: paragraphDetails?.modelYear,
				type: 'text',
				label: t('ModelYearLabel'),
				audit: showAudit,
				auditFieldName: 'modelYear',
			},
			{
				data: paragraphDetails?.comprehensive,
				type: 'date',
				label: t('ComprehensiveLabel'),
				audit: showAudit,
				auditFieldName: 'comprehensive',
			},
			{
				data: paragraphDetails?.dateNewTypes,
				type: 'date',
				label: t('DateNewTypesLabel'),
				audit: showAudit,
				auditFieldName: 'dateNewTypes',
			},
			{
				data: paragraphDetails?.dateNewRegistration,
				type: 'date',
				label: t('DateNewRegistrationLabel'),
				audit: showAudit,
				auditFieldName: 'dateNewRegistration',
			},
		],
		[
			{
				data: paragraphDetails?.keywords,
				type: 'array',
				label: t('KeywordsLabel'),
				audit: showAudit,
				auditFieldName: 'keywordRefs',
			},
			{
				data: paragraphDetails?.driveVariants,
				type: 'array',
				label: t('DriveVariantsLabel'),
				audit: showAudit,
				auditFieldName: 'driveVariantRefs',
			},
		],
		[
			{
				data: paragraphDetails?.vehicleCategories,
				type: 'array',
				label: t('VehicleCategoriesLabel'),
				audit: showAudit,
				auditFieldName: 'vehicleCategoryRefs',
			},
			{
				data: paragraphDetails?.categories,
				type: 'array',
				label: t('CategoriesLabel'),
				audit: showAudit,
				auditFieldName: 'categoryRefs',
			},
		],
		[
			{
				data: paragraphDetails?.requirements,
				type: 'array',
				label: t('RequirementsLabel'),
				audit: showAudit,
				auditFieldName: 'requirementRefs',
			},
			{
				data: paragraphDetails?.tags,
				type: 'array',
				label: t('TagsLabel'),
				audit: showAudit,
				auditFieldName: 'tagRefs',
			},
		],
		[
			{
				data: paragraphDetails?.phaseIn,
				type: 'phase',
				label: t('PhaseInLabel'),
				audit: showAudit,
				auditFieldName: 'phaseIn',
			},
		],
		[
			{
				data: paragraphDetails?.phaseOut,
				type: 'phase',
				label: t('PhaseOutLabel'),
				audit: showAudit,
				auditFieldName: 'phaseOut',
			},
		],
		[
			{
				data: paragraphDetails?.createdBy,
				type: 'personas',
				label: t('CreatedByLabel'),
			},
			{
				data: paragraphDetails?.createdAt,
				type: 'date',
				label: t('CreatedAtLabel'),
			},
			{
				data: paragraphDetails?.modifiedBy,
				type: 'personas',
				label: t('ModifiedByLabel'),
			},
			{
				data: paragraphDetails?.modifiedAt,
				type: 'date',
				label: t('ModifiedAtLabel'),
			},
		],
		[
			{
				data: paragraphDetails?.attachments,
				type: 'attachments',
				label: t('AttachmentsLabel'),
			},
		],
	];
};

const ParagraphDetailsPage: React.FC = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphDetailsPage',
	});
	const {t: tEnum} = useTranslation('common/enums');
	const [showAudit, setShowAudit] = React.useState(false);
	const {regulationId, regulatoryDocumentId, paragraphId} = useParams();

	const {loading, data} = useGetParagraphDetailsQuery({
		variables: {
			regulatoryDocumentId: regulatoryDocumentId || '',
			paragraphId: paragraphId || '',
		},
	});

	const paragraphDetails = React.useMemo(
		() =>
			(data?.regulatoryDocumentParagraph ?? {}) as RegulatoryDocumentParagraph,
		[data],
	);

	const auditlogs = React.useMemo(() => {
		const decodedRegDocId = regulatoryDocumentId
			? atob(regulatoryDocumentId).substring('RegulatoryDocument\nd'.length)
			: undefined;
		return paragraphDetails?.auditLog?.filter(
			log => decodedRegDocId === undefined || log.entityId === decodedRegDocId,
		);
	}, [paragraphDetails, regulatoryDocumentId]);

	const comments = React.useMemo(
		() => paragraphDetails?.comments,
		[paragraphDetails],
	);

	const faqs = React.useMemo(() => paragraphDetails?.faqs, [paragraphDetails]);

	const itemsWithHref: IBreadcrumbItem[] = [
		{
			text: t('Regulations'),
			key: 'regulations',
			href: '/regulations',
		},
		{
			text: `${paragraphDetails?.parent?.regulation?.regulationNumber}`,
			key: 'regulatoryDocument',
			href: `/regulations/${regulationId}`,
		},
		{
			text: `${paragraphDetails?.parent?.name}`,
			key: `paragraphs`,
			href: `/regulations/${regulationId}/${regulatoryDocumentId}/paragraphs`,
		},
		{
			text: `${paragraphDetails?.enumeration}`,
			key: `paragraph`,
			href: `/regulations/${regulationId}/${regulatoryDocumentId}/paragraphs/${paragraphId}`,
		},
	];

	const initialFieldRows: DetailsField[][] = React.useMemo(
		() =>
			initFieldRowsFromParagraphDetails(paragraphDetails, showAudit, t, tEnum),
		[paragraphDetails, showAudit, tEnum, t],
	);

	useCommand(
		{
			key: 'toggleAudit',
			iconProps: {
				iconName: 'History',
			},
			farCommand: true,
			priority: 4,
			onClick() {
				setShowAudit(!showAudit);
			},
			title: t('Audit'),
		},
		[showAudit],
	);

	useFavoriteCommand(paragraphId || '', []);

	return (
		<LoadWrapper loading={loading}>
			<Breadcrumb items={itemsWithHref} />
			<DetailsSection
				title={paragraphDetails?.enumeration || ''}
				initialFieldRows={initialFieldRows}
				auditLogData={auditlogs}
			/>
			<CommentForm comments={comments || []} level={CommentLevel.Paragraph} />
			<FAQForm faqs={faqs || []} level={FAQLevel.Paragraph} />
		</LoadWrapper>
	);
};

export default ParagraphDetailsPage;
