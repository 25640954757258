import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClearFieldForSelectedParagraphsMutationVariables = Types.Exact<{
  input: Types.ClearFieldForSelectedParagraphsInput;
}>;


export type ClearFieldForSelectedParagraphsMutation = { __typename?: 'Mutation', clearFieldForSelectedParagraphs: { __typename?: 'ClearFieldForSelectedParagraphsPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };

export type ClearFieldForAllParagraphsMutationVariables = Types.Exact<{
  input: Types.ClearFieldForAllParagraphsInput;
}>;


export type ClearFieldForAllParagraphsMutation = { __typename?: 'Mutation', clearFieldForAllParagraphs: { __typename?: 'ClearFieldForAllParagraphsPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const ClearFieldForSelectedParagraphsDocument = gql`
    mutation ClearFieldForSelectedParagraphs($input: ClearFieldForSelectedParagraphsInput!) {
  clearFieldForSelectedParagraphs(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type ClearFieldForSelectedParagraphsMutationFn = Apollo.MutationFunction<ClearFieldForSelectedParagraphsMutation, ClearFieldForSelectedParagraphsMutationVariables>;

/**
 * __useClearFieldForSelectedParagraphsMutation__
 *
 * To run a mutation, you first call `useClearFieldForSelectedParagraphsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearFieldForSelectedParagraphsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearFieldForSelectedParagraphsMutation, { data, loading, error }] = useClearFieldForSelectedParagraphsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearFieldForSelectedParagraphsMutation(baseOptions?: Apollo.MutationHookOptions<ClearFieldForSelectedParagraphsMutation, ClearFieldForSelectedParagraphsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearFieldForSelectedParagraphsMutation, ClearFieldForSelectedParagraphsMutationVariables>(ClearFieldForSelectedParagraphsDocument, options);
      }
export type ClearFieldForSelectedParagraphsMutationHookResult = ReturnType<typeof useClearFieldForSelectedParagraphsMutation>;
export type ClearFieldForSelectedParagraphsMutationResult = Apollo.MutationResult<ClearFieldForSelectedParagraphsMutation>;
export type ClearFieldForSelectedParagraphsMutationOptions = Apollo.BaseMutationOptions<ClearFieldForSelectedParagraphsMutation, ClearFieldForSelectedParagraphsMutationVariables>;
export const ClearFieldForAllParagraphsDocument = gql`
    mutation ClearFieldForAllParagraphs($input: ClearFieldForAllParagraphsInput!) {
  clearFieldForAllParagraphs(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type ClearFieldForAllParagraphsMutationFn = Apollo.MutationFunction<ClearFieldForAllParagraphsMutation, ClearFieldForAllParagraphsMutationVariables>;

/**
 * __useClearFieldForAllParagraphsMutation__
 *
 * To run a mutation, you first call `useClearFieldForAllParagraphsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearFieldForAllParagraphsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearFieldForAllParagraphsMutation, { data, loading, error }] = useClearFieldForAllParagraphsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearFieldForAllParagraphsMutation(baseOptions?: Apollo.MutationHookOptions<ClearFieldForAllParagraphsMutation, ClearFieldForAllParagraphsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearFieldForAllParagraphsMutation, ClearFieldForAllParagraphsMutationVariables>(ClearFieldForAllParagraphsDocument, options);
      }
export type ClearFieldForAllParagraphsMutationHookResult = ReturnType<typeof useClearFieldForAllParagraphsMutation>;
export type ClearFieldForAllParagraphsMutationResult = Apollo.MutationResult<ClearFieldForAllParagraphsMutation>;
export type ClearFieldForAllParagraphsMutationOptions = Apollo.BaseMutationOptions<ClearFieldForAllParagraphsMutation, ClearFieldForAllParagraphsMutationVariables>;