import * as Types from '../../types/types.generated';

import { gql } from '@apollo/client';
import { AttachmentFieldsFragmentDoc } from '../../fragments/attachmentFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VehicleProjectsForFaqQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VehicleProjectsForFaqQuery = { __typename?: 'Query', vehicleProjects: Array<{ __typename?: 'VehicleProject', generation: string, modelSeries: string, id: string }> };

export type QuestionCategoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type QuestionCategoryQuery = { __typename?: 'Query', questionCategories?: Array<{ __typename?: 'QuestionCategory', name: string, id: string }> | null };

export type QuestionCountsByEntityTypeQueryVariables = Types.Exact<{
  entityType: Types.Scalars['String'];
}>;


export type QuestionCountsByEntityTypeQuery = { __typename?: 'Query', questionCountsByEntityType: Array<{ __typename?: 'QuestionCount', entityId?: string | null, count: number }> };

export type QuestionsQueryVariables = Types.Exact<{
  targetRecordId: Types.Scalars['ID'];
}>;


export type QuestionsQuery = { __typename?: 'Query', questions: Array<{ __typename?: 'Question', name: string, targetCollection?: string | null, id: string, createdAt?: any | null, modifiedAt?: any | null, roles?: Array<string> | null, targetUsers: Array<{ __typename?: 'User', id: string }>, category?: { __typename?: 'QuestionCategory', id: string } | null, createdBy: { __typename?: 'User', name: string, id: string }, keywords: Array<{ __typename?: 'Keyword', id: string }>, attachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, isVkoOnly?: boolean | null, file: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string }, category?: { __typename?: 'AttachmentCategory', id: string, name: string } | null }> | null, targetRecords: Array<{ __typename?: 'TargetCollection', entityId?: string | null }>, answers?: Array<{ __typename?: 'Answer', text?: string | null, markedAsFinal: boolean, id: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', name: string } }> | null }> };

export type CreateQuestionMutationVariables = Types.Exact<{
  input: Types.CreateQuestionInput;
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion: { __typename?: 'QuestionPayload', requirement: { __typename?: 'Question', name: string, targetCollection?: string | null, id: string, createdAt?: any | null, modifiedAt?: any | null, roles?: Array<string> | null, targetUsers: Array<{ __typename?: 'User', id: string }>, category?: { __typename?: 'QuestionCategory', id: string } | null, attachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, isVkoOnly?: boolean | null, file: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string }, category?: { __typename?: 'AttachmentCategory', id: string, name: string } | null }> | null } } };

export type GetAllKeywordsForFaqQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllKeywordsForFaqQuery = { __typename?: 'Query', keywords?: Array<{ __typename?: 'Keyword', id: string, name: string }> | null };

export type CreateAnswerMutationVariables = Types.Exact<{
  input: Types.CreateAnswerInput;
  questionId: Types.Scalars['ID'];
}>;


export type CreateAnswerMutation = { __typename?: 'Mutation', createAnswer: { __typename?: 'AnswerPayload', succeeded: boolean } };

export type MarkAnswerAsFinalMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID'];
  answerId: Types.Scalars['ID'];
}>;


export type MarkAnswerAsFinalMutation = { __typename?: 'Mutation', markAnswerAsFinal: { __typename?: 'MarkAsFinalAnswerPayload', succeeded: boolean } };


export const VehicleProjectsForFaqDocument = gql`
    query VehicleProjectsForFAQ {
  vehicleProjects {
    generation
    modelSeries
    id
  }
}
    `;

/**
 * __useVehicleProjectsForFaqQuery__
 *
 * To run a query within a React component, call `useVehicleProjectsForFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleProjectsForFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleProjectsForFaqQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleProjectsForFaqQuery(baseOptions?: Apollo.QueryHookOptions<VehicleProjectsForFaqQuery, VehicleProjectsForFaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleProjectsForFaqQuery, VehicleProjectsForFaqQueryVariables>(VehicleProjectsForFaqDocument, options);
      }
export function useVehicleProjectsForFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleProjectsForFaqQuery, VehicleProjectsForFaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleProjectsForFaqQuery, VehicleProjectsForFaqQueryVariables>(VehicleProjectsForFaqDocument, options);
        }
export type VehicleProjectsForFaqQueryHookResult = ReturnType<typeof useVehicleProjectsForFaqQuery>;
export type VehicleProjectsForFaqLazyQueryHookResult = ReturnType<typeof useVehicleProjectsForFaqLazyQuery>;
export type VehicleProjectsForFaqQueryResult = Apollo.QueryResult<VehicleProjectsForFaqQuery, VehicleProjectsForFaqQueryVariables>;
export const QuestionCategoryDocument = gql`
    query QuestionCategory {
  questionCategories {
    name
    id
  }
}
    `;

/**
 * __useQuestionCategoryQuery__
 *
 * To run a query within a React component, call `useQuestionCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionCategoryQuery(baseOptions?: Apollo.QueryHookOptions<QuestionCategoryQuery, QuestionCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionCategoryQuery, QuestionCategoryQueryVariables>(QuestionCategoryDocument, options);
      }
export function useQuestionCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionCategoryQuery, QuestionCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionCategoryQuery, QuestionCategoryQueryVariables>(QuestionCategoryDocument, options);
        }
export type QuestionCategoryQueryHookResult = ReturnType<typeof useQuestionCategoryQuery>;
export type QuestionCategoryLazyQueryHookResult = ReturnType<typeof useQuestionCategoryLazyQuery>;
export type QuestionCategoryQueryResult = Apollo.QueryResult<QuestionCategoryQuery, QuestionCategoryQueryVariables>;
export const QuestionCountsByEntityTypeDocument = gql`
    query QuestionCountsByEntityType($entityType: String!) {
  questionCountsByEntityType(entityType: $entityType) {
    entityId
    count
  }
}
    `;

/**
 * __useQuestionCountsByEntityTypeQuery__
 *
 * To run a query within a React component, call `useQuestionCountsByEntityTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionCountsByEntityTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionCountsByEntityTypeQuery({
 *   variables: {
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useQuestionCountsByEntityTypeQuery(baseOptions: Apollo.QueryHookOptions<QuestionCountsByEntityTypeQuery, QuestionCountsByEntityTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionCountsByEntityTypeQuery, QuestionCountsByEntityTypeQueryVariables>(QuestionCountsByEntityTypeDocument, options);
      }
export function useQuestionCountsByEntityTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionCountsByEntityTypeQuery, QuestionCountsByEntityTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionCountsByEntityTypeQuery, QuestionCountsByEntityTypeQueryVariables>(QuestionCountsByEntityTypeDocument, options);
        }
export type QuestionCountsByEntityTypeQueryHookResult = ReturnType<typeof useQuestionCountsByEntityTypeQuery>;
export type QuestionCountsByEntityTypeLazyQueryHookResult = ReturnType<typeof useQuestionCountsByEntityTypeLazyQuery>;
export type QuestionCountsByEntityTypeQueryResult = Apollo.QueryResult<QuestionCountsByEntityTypeQuery, QuestionCountsByEntityTypeQueryVariables>;
export const QuestionsDocument = gql`
    query Questions($targetRecordId: ID!) {
  questions(targetRecordId: $targetRecordId) {
    name
    targetUsers {
      id
    }
    category {
      id
    }
    targetCollection
    name
    id
    createdAt
    createdBy {
      name
      id
    }
    modifiedAt
    keywords {
      id
    }
    roles
    attachments {
      ...AttachmentFields
    }
    targetRecords {
      entityId
    }
    answers {
      text
      createdBy {
        name
      }
      markedAsFinal
      id
      createdAt
      modifiedAt
    }
  }
}
    ${AttachmentFieldsFragmentDoc}`;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      targetRecordId: // value for 'targetRecordId'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($input: CreateQuestionInput!) {
  createQuestion(input: $input) {
    requirement {
      name
      targetUsers {
        id
      }
      targetCollection
      name
      id
      createdAt
      modifiedAt
      category {
        id
      }
      attachments {
        ...AttachmentFields
      }
      roles
    }
  }
}
    ${AttachmentFieldsFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const GetAllKeywordsForFaqDocument = gql`
    query GetAllKeywordsForFaq {
  keywords {
    id
    name
  }
}
    `;

/**
 * __useGetAllKeywordsForFaqQuery__
 *
 * To run a query within a React component, call `useGetAllKeywordsForFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllKeywordsForFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllKeywordsForFaqQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllKeywordsForFaqQuery(baseOptions?: Apollo.QueryHookOptions<GetAllKeywordsForFaqQuery, GetAllKeywordsForFaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllKeywordsForFaqQuery, GetAllKeywordsForFaqQueryVariables>(GetAllKeywordsForFaqDocument, options);
      }
export function useGetAllKeywordsForFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllKeywordsForFaqQuery, GetAllKeywordsForFaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllKeywordsForFaqQuery, GetAllKeywordsForFaqQueryVariables>(GetAllKeywordsForFaqDocument, options);
        }
export type GetAllKeywordsForFaqQueryHookResult = ReturnType<typeof useGetAllKeywordsForFaqQuery>;
export type GetAllKeywordsForFaqLazyQueryHookResult = ReturnType<typeof useGetAllKeywordsForFaqLazyQuery>;
export type GetAllKeywordsForFaqQueryResult = Apollo.QueryResult<GetAllKeywordsForFaqQuery, GetAllKeywordsForFaqQueryVariables>;
export const CreateAnswerDocument = gql`
    mutation CreateAnswer($input: CreateAnswerInput!, $questionId: ID!) {
  createAnswer(input: $input, questionId: $questionId) {
    succeeded
  }
}
    `;
export type CreateAnswerMutationFn = Apollo.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>;

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useCreateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(CreateAnswerDocument, options);
      }
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>;
export type CreateAnswerMutationResult = Apollo.MutationResult<CreateAnswerMutation>;
export type CreateAnswerMutationOptions = Apollo.BaseMutationOptions<CreateAnswerMutation, CreateAnswerMutationVariables>;
export const MarkAnswerAsFinalDocument = gql`
    mutation MarkAnswerAsFinal($questionId: ID!, $answerId: ID!) {
  markAnswerAsFinal(questionId: $questionId, answerId: $answerId) {
    succeeded
  }
}
    `;
export type MarkAnswerAsFinalMutationFn = Apollo.MutationFunction<MarkAnswerAsFinalMutation, MarkAnswerAsFinalMutationVariables>;

/**
 * __useMarkAnswerAsFinalMutation__
 *
 * To run a mutation, you first call `useMarkAnswerAsFinalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAnswerAsFinalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAnswerAsFinalMutation, { data, loading, error }] = useMarkAnswerAsFinalMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useMarkAnswerAsFinalMutation(baseOptions?: Apollo.MutationHookOptions<MarkAnswerAsFinalMutation, MarkAnswerAsFinalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAnswerAsFinalMutation, MarkAnswerAsFinalMutationVariables>(MarkAnswerAsFinalDocument, options);
      }
export type MarkAnswerAsFinalMutationHookResult = ReturnType<typeof useMarkAnswerAsFinalMutation>;
export type MarkAnswerAsFinalMutationResult = Apollo.MutationResult<MarkAnswerAsFinalMutation>;
export type MarkAnswerAsFinalMutationOptions = Apollo.BaseMutationOptions<MarkAnswerAsFinalMutation, MarkAnswerAsFinalMutationVariables>;