import i18n from 'i18n/i18n';
import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';
import Excel from 'exceljs';
import {BaselineOfBaselineListPage} from 'features/VehicleProjects/BaselineListPage/BaselinesPage.types';

interface IBaselinesPage {
	(
		arrBaselinesFilt: BaselineOfBaselineListPage[],
		modelSeries: string,
		generation: string,
		t: TFunction,
	): Promise<void>;
}

export const handleClickBaselines: IBaselinesPage = async (
	arrBaselinesFilt,
	modelSeries,
	generation,
	t,
) => {
	const tOtpions = {ns: 'features/VehicleProjects/baselinesPage'};
	const workbook = new Excel.Workbook();
	const arrColWidth: number[] = [80, 20, 20, 30, 20, 20];
	const strPageName =
		modelSeries + ' > ' + generation + ' > ' + t('BreadcrumbText', tOtpions);
	const sheet = workbook.addWorksheet('TRACE_' + t('BreadcrumbText', tOtpions));

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'tableBaselines',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('Name', tOtpions),
				filterButton: true,
			},
			{
				name: t('Date', tOtpions),
				filterButton: true,
			},
			{
				name: t('Time', tOtpions),
				filterButton: true,
			},
			{
				name: t('Download', tOtpions),
				filterButton: true,
			},
		],
		rows: [],
	});

	const table = sheet.getTable('tableBaselines');
	intRowCount++;
	for (let ii = 0; ii < arrBaselinesFilt.length; ii++) {
		const objDate = arrBaselinesFilt[ii].createdAt
			? new Date(arrBaselinesFilt[ii].createdAt)
			: undefined;
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		row.getCell('E').style.font = {
			underline: true,
			bold: false,
			color: {argb: '00B0F0'},
		};
		table.addRow([
			arrBaselinesFilt[ii].name,
			arrBaselinesFilt[ii].createdAt ? formatDateTime(objDate!, i18n) : '',

			objDate!.getHours().toString().padStart(2, '0') +
				':' +
				objDate!.getMinutes().toString().padStart(2, '0'),
			{
				text: 'TRACE',
				hyperlink: window.location.href,
			},
		]);
		intRowCount++;
	}

	table.commit();
	const strZipName = t('BreadcrumbText', tOtpions);
	await createZIPXL(workbook, strZipName, t);
};
