import {ApolloClient, useApolloClient} from '@apollo/client';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {
	EditParagraphsFormSubmissionStatusContext,
	EditParagraphsFormSubmissionStatusInfo,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/EditParagraphsFormSubmissionStatusProvider';
import {DocOfRegDocDetailsPageQuery} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {
	RegulatoryDetailsPageFormMode,
	SetIsFormRefetchingRegDoc,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.types';
import {evictAuditLogs} from 'features/RegulatoryDocuments/RegDocDetailsPage/evictAuditLogs';
import {RegDocAuditLogsInfo} from 'features/RegulatoryDocuments/RegDocDetailsPage/useGetRegDocAuditLogs';
import {useCloneRegulatoryDocumentMutation} from 'features/RegulatoryDocuments/hooks/useCloneRegulatoryDocument.generated';
import {
	GetRegulatoryDocumentDetailsDocument,
	GetRegulatoryDocumentDetailsQuery,
} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {
	UpdateRegulatoryDocumentParagraphMutation,
	useUpdateRegulatoryDocumentParagraphMutation,
} from 'features/RegulatoryDocuments/hooks/useUpdateRegulatoryDocumentParagraph.generated';
import {
	UpdateRegulatoryDocumentParagraphBatchMutation,
	useUpdateRegulatoryDocumentParagraphBatchMutation,
} from 'features/RegulatoryDocuments/hooks/useUpdateRegulatoryDocumentParagraphBatch.generated';
import {mapToRef} from 'helpers';
import {useNotificationBar} from 'hooks';
import omitDeep from 'omit-deep-lodash';
import React, {Dispatch, SetStateAction, useCallback, useContext} from 'react';
import {Control, UseFormHandleSubmit, UseFormTrigger} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {
	ConvoluteType,
	RegulatoryDocument,
	RegulatoryDocumentParagraph,
	WorkflowStatus,
} from 'types';
import {useParagraphsContext} from '../ParagraphsContext';
import {
	ParagraphFormFields,
	SanitizedFormFields,
	SetParagraphsCopy,
} from './EditParagraphsForm.types';
import {sanitizeFormFields} from './editParagraphsForm.utils';
import {useEditParagraphsFormTranslations} from './editParagraphsFormTranslationHooks';
import {MutationKeyOptimisticData} from './optimisticData/MutationKeyOptimisticData.utils';
import {
	MutationKeyOptimisticDataService,
	MutationKeyOptimisticDataServiceFields,
} from './optimisticData/updateRegDocParagraphMutations/MutationKeyOptimisticData/MutationKeyOptimisticData.service';
import {MutationParagraph} from './optimisticData/updateRegDocParagraphMutations/updateRegDocParagraphMutations.types';

type QueryParagraphs = DocOfRegDocDetailsPageQuery['paragraphs'];

type FieldsFromAuditLogsInfo = Pick<
	RegDocAuditLogsInfo,
	'setAreAuditLogsLoading'
>;

export interface FieldsToUseEditParagraphsFormSubmitter
	extends FieldsFromAuditLogsInfo {
	handleSubmit: UseFormHandleSubmit<ParagraphFormFields>;
	setMode: Dispatch<SetStateAction<RegulatoryDetailsPageFormMode>>;
	setSelectedParagraphsCopy: SetParagraphsCopy;
	onParagraphsSaved: () => Promise<void>;
	setIsFormRefetchingRegDoc: SetIsFormRefetchingRegDoc;
	clearedFields: MutationKeyOptimisticDataServiceFields['clearedFields'];
	/**
	 * We must use this instead of the selected reg doc because the latter doesn't
	 * get updated when we set the optimistic data or when we refetch.
	 */
	regDoc: GetRegulatoryDocumentDetailsQuery['regulatoryDocument'];
	control: Control<ParagraphFormFields, any>;
	trigger: UseFormTrigger<ParagraphFormFields>;
	isVex: boolean;
}

export const useEditParagraphsFormSubmitter = ({
	handleSubmit,
	setMode,
	onParagraphsSaved,
	clearedFields,
	setIsFormRefetchingRegDoc,
	regDoc,
	setAreAuditLogsLoading,
	control,
	trigger,
	isVex,
}: FieldsToUseEditParagraphsFormSubmitter) => {
	/**
	 * Mutations
	 */
	const [updateRegulatoryDocumentParagraphBatch] =
		useUpdateRegulatoryDocumentParagraphBatchMutation();
	const [updateRegulatoryDocumentParagraph] =
		useUpdateRegulatoryDocumentParagraphMutation();
	// Cconst {updateFinalRequirementsVersions} = useCloneRequirement();
	const [cloneRegulatoryDocumentMutation] =
		useCloneRegulatoryDocumentMutation();

	/**
	 * Other hooks
	 */
	const {selectedParagraphs} = useParagraphsContext();
	const {setMessage} = useNotificationBar();
	const {t} = useEditParagraphsFormTranslations();
	const apolloClient: ApolloClient<object> = useApolloClient();
	const {setSubmissionStarted, setSubmissionFinished} = useContext(
		EditParagraphsFormSubmissionStatusContext,
	) as EditParagraphsFormSubmissionStatusInfo;
	const navigate = useNavigate();

	// only changes done in final version by other user than Vex should create modified version
	const shouldCreateModifiedVersion = React.useMemo(
		() => regDoc?.workflow?.status === WorkflowStatus.Finalized && !isVex,
		[regDoc?.workflow?.status, isVex],
	);

	/**
	 * GDRP
	 */
	const {data: convolutes} = useGetConvolutesQuery();

	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.RegulatoryDocument,
			),
		[convolutes],
	);

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id || '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	const mapObjectMetadata = (
		formFields: ParagraphFormFields,
		field: keyof ParagraphFormFields,
		useRefs = false,
	) => {
		const item: SanitizedFormFields = sanitizeFormFields(formFields);
		const metadataMapping = {
			driveVariants: {
				driveVariantRefs: mapToRef(item.driveVariants ?? [], useRefs),
			},
			vehicleCategories: {
				vehicleCategoryRefs: mapToRef(item.vehicleCategories ?? [], useRefs),
			},
			keywords: {keywordRefs: mapToRef(item.keywords ?? [], useRefs)},
			categories: {categoryRefs: mapToRef(item.categories ?? [], useRefs)},
			tags: {tagRefs: mapToRef(item.tags ?? [], useRefs)},
			modelYear: {modelYear: item.modelYear},
			comprehensive: {comprehensive: item.comprehensive ?? undefined},
			dateNewRegistration: {
				dateNewRegistration: item.dateNewRegistration ?? undefined,
			},
			dateNewTypes: {dateNewTypes: item.dateNewTypes ?? undefined},
			phaseIn: {phaseIn: omitDeep(item.phaseIn ?? [], '__typename') as any},
			phaseOut: {
				phaseOut: omitDeep(item.phaseOut ?? [], '__typename') as any,
			},
			summary: {summary: item.summary},
		};

		return metadataMapping[field];
	};

	const getUpdateObject = (item: ParagraphFormFields, useRefs = false) => {
		const updatedObject: any = {};
		for (const field of Object.keys(item)) {
			Object.assign(
				updatedObject,
				mapObjectMetadata(item, field as keyof ParagraphFormFields, useRefs),
			);
		}

		return updatedObject;
	};

	const getUpdateFilledObject = (
		item: ParagraphFormFields,
		useRefs = false,
	) => {
		const updatedFilledObject: any = {};
		for (const field of Object.keys(item)) {
			const value = item[field as keyof ParagraphFormFields];
			/**
			 * If these conditions change, make sure that we still generate the
			 * optimistic data in the same way.
			 */
			if ((Array.isArray(value) && value.length) || value) {
				Object.assign(
					updatedFilledObject,
					mapObjectMetadata(item, field as keyof ParagraphFormFields, useRefs),
				);
			}
		}

		return updatedFilledObject;
	};

	type FieldsFromService = Pick<
		MutationKeyOptimisticDataServiceFields,
		'idsToUpdate'
	>;

	interface FieldsToCreateOptimisticData extends FieldsFromService {
		formFields: ParagraphFormFields;
	}

	/**
	 * The paragraphs can be undefined because the reg doc's type isn't accurate,
	 * and the reg doc will be an empty object sometimes.
	 */
	const getParagraphs = (): QueryParagraphs => {
		const paragraphs: QueryParagraphs | undefined = regDoc?.paragraphs;
		if (paragraphs) return paragraphs;
		throw new Error(`Invalid value for paragraphs, ${paragraphs}`);
	};

	const createMutationOptimisticData = ({
		formFields,
		...other
	}: FieldsToCreateOptimisticData): MutationKeyOptimisticData<MutationParagraph> => {
		return MutationKeyOptimisticDataService.createMutationKeyOptimisticData({
			paragraphs: getParagraphs(),
			selectedParagraphs,
			regDocId: getRegDocId(),
			clearedFields,
			sanitizedFormFields: sanitizeFormFields(formFields),
			...other,
		});
	};

	const createSingleParagraphMutationOptimisticData = (
		formFields: ParagraphFormFields,
		id: RegulatoryDocumentParagraph['id'],
	): UpdateRegulatoryDocumentParagraphMutation => {
		return {
			updateRegulatoryDocumentParagraph: createMutationOptimisticData({
				formFields,
				idsToUpdate: [id],
			}),
		};
	};

	const getRegDocId = (): RegulatoryDocument['id'] => {
		const id: RegulatoryDocument['id'] | undefined = regDoc?.id;
		if (id) return id;
		throw new Error('Could not get reg doc ID.');
	};

	const handleNewRegulatoryDocumentForSingleParagraph = async (
		item: ParagraphFormFields,
		updateObject: any,
		index: number,
	) => {
		if (!regDoc) return;
		const cloneResult = await cloneRegulatoryDocumentMutation({
			variables: {
				input: {
					originalRegulatoryDocumentId: regDoc.id,
					workflowStatus: WorkflowStatus.Modified,
				},
			},
		});

		const clonedRegDoc =
			cloneResult?.data?.cloneRegulatoryDocument?.regulatoryDocument;

		if (!clonedRegDoc) {
			return;
		}

		const cloneId = clonedRegDoc.id;
		const paragraphId = clonedRegDoc.paragraphs[index]?.id;

		await updateRegulatoryDocumentParagraph({
			variables: {
				input: {
					paragraphId,
					regulatoryDocumentId: cloneId,
					update: updateObject,
				},
			},
			update: evictAuditLogs,
			optimisticResponse: createSingleParagraphMutationOptimisticData(
				item,
				cloneId,
			),
		});

		/* Aawait updateFinalRequirementsVersions(updateObject, [
			selectedParagraphs[0],
		]); */
		navigate(`/regulations/${regDoc?.regulation?.id || ''}`);
	};

	const handleNewRegulatoryDocumentForMultipleParagraphs = async (
		item: ParagraphFormFields,
		update: any,

		paragraphsPositions: number[],
	) => {
		if (!regDoc) return;
		const cloneResult = await cloneRegulatoryDocumentMutation({
			variables: {
				input: {
					originalRegulatoryDocumentId: regDoc.id,
					workflowStatus: WorkflowStatus.Modified,
				},
			},
		});

		const clonedRegDoc =
			cloneResult?.data?.cloneRegulatoryDocument?.regulatoryDocument;

		if (!clonedRegDoc) {
			return;
		}

		const cloneId = clonedRegDoc.id;
		const paragraphIds = clonedRegDoc.paragraphs.map(p => p.id);

		const paragraphsIdsInClonedVersion = paragraphsPositions.map(
			(elem: number) => paragraphIds[elem],
		);

		await updateRegulatoryDocumentParagraphBatch({
			variables: {
				input: {
					regulatoryDocumentId: cloneId,
					paragraphIds: paragraphsIdsInClonedVersion,
					update,
				},
			},
			update: evictAuditLogs,
			optimisticResponse: createParagraphsMutationOptimisticData({
				formFields: item,
				idsToUpdate: paragraphsIdsInClonedVersion,
			}),
		});

		/* Aawait updateFinalRequirementsVersions(update, selectedParagraphs); */
		navigate(`/regulations/${regDoc?.regulation?.id || ''}`);
	};

	const saveSingleRegulatoryDocumentParagraph = async (
		item: ParagraphFormFields,
	) => {
		const updateObject = getUpdateObject(item);
		const currentRegId: RegulatoryDocument['id'] = getRegDocId();
		const {id} = selectedParagraphs[0];
		const index: number =
			regDoc?.paragraphs?.findIndex((elem: any) => elem.id === id) ?? -1;

		if (shouldCreateModifiedVersion) {
			await handleNewRegulatoryDocumentForSingleParagraph(
				item,
				updateObject,
				index,
			);
		} else {
			await updateRegulatoryDocumentParagraph({
				variables: {
					input: {
						paragraphId: id,
						regulatoryDocumentId: currentRegId,
						update: updateObject,
					},
				},
				update: evictAuditLogs,
				optimisticResponse: createSingleParagraphMutationOptimisticData(
					item,
					id,
				),
			})
				.then(() => {
					setSubmissionFinished();
					onParagraphsSaved();
				})
				.catch((e: Error) => {
					console.error(
						'[EditParagraphForm/saveMultipleRegulatoryDocumentParagraph]',
						e,
					);
				});
		}
	};

	const createParagraphsMutationOptimisticData = (
		fields: FieldsToCreateOptimisticData,
	): UpdateRegulatoryDocumentParagraphBatchMutation => {
		return {
			updateRegulatoryDocumentParagraphBatch:
				createMutationOptimisticData(fields),
		};
	};

	const saveMultipleRegulatoryDocumentParagraphs = async (
		item: ParagraphFormFields,
	) => {
		const update = getUpdateFilledObject(item, true);
		if (Object.keys(update).length) {
			const paragraphIds = selectedParagraphs.map(sp => sp.id) ?? [0];
			if (shouldCreateModifiedVersion) {
				const positions: number[] = (regDoc?.paragraphs ?? []).reduce(
					(acc, elem, index) => {
						if (paragraphIds.includes(elem.id)) {
							acc.push(index);
						}
						return acc;
					},
					[] as number[],
				);

				await handleNewRegulatoryDocumentForMultipleParagraphs(
					item,
					update,
					positions,
				);
			} else {
				await updateRegulatoryDocumentParagraphBatch({
					variables: {
						input: {
							regulatoryDocumentId: getRegDocId(),
							paragraphIds,
							update,
						},
					},
					update: evictAuditLogs,
					optimisticResponse: createParagraphsMutationOptimisticData({
						formFields: item,
						idsToUpdate: paragraphIds,
					}),
				})
					.then(() => {
						setSubmissionFinished();
						onParagraphsSaved();
					})
					.catch((e: Error) => {
						console.error(
							'[EditParagraphForm/saveMultipleRegulatoryDocumentParagraphs:1]',
							e,
						);
					});
			}
		}
	};

	const refetchRegDoc = async (): Promise<void> => {
		/**
		 * We must refetch the data because the reg doc's phase properties and
		 * other fields can change when its paragraphs' fields change.
		 */
		await apolloClient.refetchQueries({
			include: [GetRegulatoryDocumentDetailsDocument],
		});
	};

	const refetchRegDocAndUpdateState = async (): Promise<void> => {
		setIsFormRefetchingRegDoc(true);
		try {
			await refetchRegDoc();
		} finally {
			setIsFormRefetchingRegDoc(false);
		}
	};

	const showNotificationAndRefetchAndUpdateLoadingState =
		async (): Promise<void> => {
			setAreAuditLogsLoading(false);
			setMessage({
				message: t('MetadataUpdated', {
					selectedParagraphs: selectedParagraphs.length.toString(),
				}),
				timeout: 10000,
			});

			await refetchRegDocAndUpdateState();
			setSubmissionFinished();
		};

	const phaseInOutIsValid = async (): Promise<boolean> => {
		await trigger('phaseIn');
		await trigger('phaseOut');

		return (
			control.getFieldState('phaseIn').invalid ||
			control.getFieldState('phaseOut').invalid
		);
	};

	const handleSaveClick = async () => {
		/*
		 * Check if phaseIn and phaseOut are valid
		 */

		if (await phaseInOutIsValid()) {
			return;
		}

		/**
		 * Note: Everything inside the submission handler will only be called if the
		 * form's validation succeeds.
		 */
		await handleSubmit(async item => {
			setSubmissionStarted();
			/**
			 * We call this at the beginning of the submission process. Otherwise, a
			 * user could open the change history while the form is submitting and would
			 * expect to see accurate data, but it would be outdated. So, we must show a
			 * loading indicator instead.
			 */
			setAreAuditLogsLoading(true);
			setMode(RegulatoryDetailsPageFormMode.None);

			try {
				if (selectedParagraphs.length > 1) {
					await saveMultipleRegulatoryDocumentParagraphs(item);
				} else {
					await saveSingleRegulatoryDocumentParagraph(item);
				}
			} catch (e: any) {
				console.error('[EditParagraphForm/handleSaveClick/handleSubmit]', e);
			}
		})()
			.catch((e: any) =>
				console.error('[EditParagraphForm/handleSaveClick]', e),
			)
			/**
			 * Note that we cannot return a Promise here. Otherwise, Codecov would
			 * show a code smell warning.
			 */
			.finally((): void => {
				showNotificationAndRefetchAndUpdateLoadingState();
			});
	};

	const memoizedHandleSaveClick = useCallback(handleSaveClick, [
		selectedParagraphs,
		regDoc,
		clearedFields,
		onParagraphsSaved,
	]);

	return memoizedHandleSaveClick;
};
