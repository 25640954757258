import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { MarketNamingTemplateFieldsFragmentDoc } from '../../../fragments/marketNamingTemplateFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRegulationFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRegulationFormDataQuery = { __typename?: 'Query', markets?: Array<{ __typename?: 'Market', id: string, name: string, sortPriority?: number | null, regulationNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }>, regulatoryDocumentNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }> }> | null, keywords?: Array<{ __typename?: 'Keyword', id: string, name: string, sortPriority?: number | null }> | null, standardPlusPcmsClusters?: Array<{ __typename?: 'StandardPlusPcmsCluster', id: string, name: string }> | null, vehicleCategories?: Array<{ __typename?: 'VehicleCategory', id: string, name: string }> | null, driveVariants?: Array<{ __typename?: 'DriveVariant', id: string, name: string }> | null };


export const GetRegulationFormDataDocument = gql`
    query GetRegulationFormData {
  markets {
    id
    name
    sortPriority
    ...MarketNamingTemplateFields
  }
  keywords {
    id
    name
    sortPriority
  }
  standardPlusPcmsClusters {
    id
    name
  }
  vehicleCategories {
    id
    name
  }
  driveVariants {
    id
    name
  }
}
    ${MarketNamingTemplateFieldsFragmentDoc}`;

/**
 * __useGetRegulationFormDataQuery__
 *
 * To run a query within a React component, call `useGetRegulationFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegulationFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegulationFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegulationFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetRegulationFormDataQuery, GetRegulationFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegulationFormDataQuery, GetRegulationFormDataQueryVariables>(GetRegulationFormDataDocument, options);
      }
export function useGetRegulationFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegulationFormDataQuery, GetRegulationFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegulationFormDataQuery, GetRegulationFormDataQueryVariables>(GetRegulationFormDataDocument, options);
        }
export type GetRegulationFormDataQueryHookResult = ReturnType<typeof useGetRegulationFormDataQuery>;
export type GetRegulationFormDataLazyQueryHookResult = ReturnType<typeof useGetRegulationFormDataLazyQuery>;
export type GetRegulationFormDataQueryResult = Apollo.QueryResult<GetRegulationFormDataQuery, GetRegulationFormDataQueryVariables>;