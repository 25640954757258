import {TFunction} from 'react-i18next';
import Excel from 'exceljs';
import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';
import {Keyword, RegulationCluster, User, VexCluster} from 'types';

interface IVkoVexPage {
	(entityListItems: any[], t: TFunction): Promise<void>;
}

export const handleClickVkoVexPage: IVkoVexPage = async (
	entityListItems,
	t,
) => {
	const strTrNS = 'features/adminsection';
	const strTrPrefix = 'AdminKeywordsPage';
	const strPageName = t('VkoVexList', {
		ns: 'features/adminsection',
		keyPrefix: 'AdminVexClustersPage',
	});
	const arrColWidth = [30, 30, 30, 30, 20, 20, 20, 20, 20, 20, 20];
	const workbook = new Excel.Workbook();
	const sheet = workbook.addWorksheet('TRACE_VKO-VEX');
	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};
	sheet.addTable({
		name: 'tableVKOVEX',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('Keywords', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('RegulationClusters', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('Vkos', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('VexClusters', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('Vex', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
		],
		rows: [],
	});

	intRowCount++;
	const table = sheet.getTable('tableVKOVEX');
	for (let ii = 0; ii < entityListItems.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			entityListItems[ii].name,
			(entityListItems[ii].regulationClusters as RegulationCluster[])
				.map(x => x.name)
				.join(';\n'),
			(entityListItems[ii].vkos as User[]).map(x => x.name).join(';\n'),
			(entityListItems[ii].vexClusters as VexCluster[])
				.map(x => x.name)
				.join(';\n'),
			(entityListItems[ii].vexes as VexCluster[]).map(x => x.name).join(';\n'),
		]);

		intRowCount++;
	}

	table.commit();
	const strZipName = 'VKO-VEX';
	await createZIPXL(workbook, strZipName, t);
};
