import {
	useTheme,
	Text,
	List,
	Link as FluentLink,
	Stack,
	FontIcon,
} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {Link} from 'react-router-dom';
import {useFavoriteItemsQuery} from '../hooks/favoriteItems.generated';
import {getListClassNames} from 'styles';
import {useTranslation} from 'react-i18next';

type FavoritesListProps = {halfHeight?: boolean};

export const FavoritesList: React.FC<FavoritesListProps> = ({
	halfHeight = false,
}) => {
	const {t} = useTranslation('appShell/navigation', {
		keyPrefix: 'Navigation',
	});
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const {loading, data} = useFavoriteItemsQuery();
	const favorites = React.useMemo(
		() =>
			data?.myFavorites
				? [
						...data.myFavorites.regulations,
						...data.myFavorites.regulatoryDocuments,
						...data.myFavorites.paragraphs,
						...data.myFavorites.requirements,
				  ]
				: [],
		[data],
	);

	const onRenderCell = (item: any): JSX.Element => {
		const displayName =
			item.__typename === 'RegulatoryDocumentParagraph'
				? item.enumeration
				: item.name;
		let subtext = '';
		let url = '';

		switch (item.__typename) {
			case 'Regulation':
				subtext = item.regulationNumber;
				url = `/regulations/${item.id}`;
				break;
			case 'RegulatoryDocument':
				subtext = `${item.regulation.regulationNumber} ${item.regulation.name}`;
				url = `/regulatoryDocuments/${item.id}`;
				break;
			case 'RegulatoryDocumentParagraph':
				subtext = `${item.parent.regulation.regulationNumber} ${item.parent.regulation.name}, ${item.parent.name}`;
				url = `/regulations/${item.parent.regulation.id}/${item.parent.id}/paragraphs/${item.id}`;
				break;
			case 'Requirement':
				subtext = item.requirementId;
				url = `/requirements/${item.id}`;
				break;

			default:
		}

		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Link to={url} className={classNames.link}>
						<FluentLink>
							<div className={classNames.itemName}>{displayName}</div>
						</FluentLink>
					</Link>
					<div className={classNames.itemSubtext}>{subtext}</div>
				</div>
			</div>
		);
	};

	return (
		<div className={classNames.activityContainer}>
			<Stack horizontal horizontalAlign='space-between'>
				<Stack.Item>
					<Text className={classNames.activity}>{t('Meine-Favoriten')}</Text>
				</Stack.Item>
				<Stack.Item>
					<Link to={'/favorites'}>
						<FontIcon
							iconName='OpenInNewWindow'
							className={classNames.iconStyles}
						></FontIcon>
					</Link>
				</Stack.Item>
			</Stack>
			<div className={classNames.container} data-is-scrollable>
				<LoadWrapper loading={loading}>
					<List items={favorites} onRenderCell={onRenderCell} />
				</LoadWrapper>
			</div>
		</div>
	);
};
