import {useStore} from 'react-context-hook';
import React, {useState, useEffect} from 'react';

import _ from 'lodash';

import {useQuestionCategoryLazyQuery} from './Faq.generated';
import {FaqQuestions} from './FaqQuestions';
import {FaqRepliesPanel} from './FaqRepliesPanel';

export const FaqPanelContent: any = ({questions}: any) => {
	const [questionCategories, setQuestionCategories] = useStore<any>(
		'questionCategories',
		[],
	);

	const [fetchCategories, {data: categories, loading, error, refetch}] =
		useQuestionCategoryLazyQuery();

	useEffect(() => {
		if (questionCategories?.length === 0) {
			fetchCategories();
		}
	}, [questionCategories]);

	useEffect(() => {
		if (categories) {
			setQuestionCategories(
				categories?.questionCategories?.map((e: any) => {
					return {
						key: e.id,
						text: e.name,
					};
				}) || [],
			);
		}
	}, [categories]);

	const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

	return (
		<div
			style={{
				display: 'flex',
				gap: '10px',
				height: '100%',
				marginTop: '20px',
			}}
		>
			<FaqQuestions
				questions={questions}
				selectedQuestion={selectedQuestion}
				setSelectedQuestion={setSelectedQuestion}
			/>
			<FaqRepliesPanel
				questions={questions}
				selectedQuestion={selectedQuestion}
			/>
		</div>
	);
};
