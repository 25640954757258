import {Pivot, PivotItem, PrimaryButton, Stack} from '@fluentui/react';
import {ControlledTextField} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {
	ParagraphResultsList,
	RegulationResultsList,
	RegulatoryDocumentResultsList,
} from './components';
import {
	useFullTextSearchDocumentsLazyQuery,
	useFullTextSearchPogisLazyQuery,
	useFullTextSearchRegulationsLazyQuery,
} from './hooks';
import {PogisArchiveResultsList} from './components/PogisArchiveResultsList';

type SearchInput = {
	searchText: string;
};

type SearchEntity =
	| 'regulations'
	| 'regulatoryDocuments'
	| 'paragraphs'
	| 'pogisDocuments';

export const SearchPage: React.FC = () => {
	const {t} = useTranslation('features/search');

	const [searchParams, setSearchParams] = useSearchParams();

	const text = searchParams.get('text') || '';
	const entity = (searchParams.get('entity') as any) || 'regulations';

	// Use separate lazy queries for each search
	const [
		runRegulationSearch,
		{data: regulationData, loading: regulationsLoading},
	] = useFullTextSearchRegulationsLazyQuery();
	const [runDocumentsSearch, {data: documentData, loading: documentsLoading}] =
		useFullTextSearchDocumentsLazyQuery();
	const [runPogisSearch, {data: pogisData, loading: pogisLoading}] =
		useFullTextSearchPogisLazyQuery();

	const regulations = regulationData?.searchRegulations.regulations || [];
	const regulatoryDocuments =
		documentData?.searchRegulatoryDocumentsAndParagraphs?.regulatoryDocuments ||
		[];
	const paragraphs =
		documentData?.searchRegulatoryDocumentsAndParagraphs?.paragraphs || [];
	const pogisDocuments = pogisData?.searchPogisDocuments.pogisDocuments || [];

	const {control, handleSubmit, reset} = useForm<SearchInput>({
		defaultValues: {searchText: text},
	});

	React.useEffect(() => {
		if (text !== '') {
			reset({searchText: text});
			runRegulationSearch({variables: {searchString: text}});
			runDocumentsSearch({variables: {searchString: text}});
			runPogisSearch({variables: {searchString: text}});
		}
	}, [text]);

	const onPivotItemClick = React.useCallback(
		(item: PivotItem | undefined) => {
			if (item?.props.itemKey) {
				searchParams.set('entity', item.props.itemKey as SearchEntity);
				setSearchParams(searchParams);
			}
		},
		[text, searchParams],
	);

	const onSearch = React.useCallback(() => {
		handleSubmit(data => {
			const {searchText} = data;
			if (searchText.length > 2) {
				searchParams.set('text', searchText);
				setSearchParams(searchParams);
				runRegulationSearch({variables: {searchString: searchText}});
				runPogisSearch({variables: {searchString: searchText}});
			}
		})();
	}, [searchParams.entries]);

	return (
		<Stack tokens={{childrenGap: 8}}>
			<Stack styles={{root: {marginLeft: 20}}}>
				<h3>{t('SearchFunction')}</h3>
				<Stack horizontal tokens={{childrenGap: 8}}>
					<ControlledTextField
						styles={{root: {width: 400}}}
						control={control}
						name={'searchText'}
					/>
					<PrimaryButton onClick={onSearch}>{t('Search')}</PrimaryButton>
				</Stack>
			</Stack>
			<Pivot onLinkClick={onPivotItemClick} defaultSelectedKey={entity}>
				<PivotItem
					headerText={t('RegulatoryDocuments')}
					itemKey='regulatoryDocuments'
				>
					<LoadWrapper loading={documentsLoading}>
						<RegulatoryDocumentResultsList
							regulatoryDocuments={regulatoryDocuments}
						/>
					</LoadWrapper>
				</PivotItem>
				<PivotItem headerText={t('Regulations')} itemKey='regulations'>
					<LoadWrapper loading={regulationsLoading}>
						<RegulationResultsList regulations={regulations} />
					</LoadWrapper>
				</PivotItem>
				<PivotItem headerText={t('Paragraphs')} itemKey='paragraphs'>
					<LoadWrapper loading={documentsLoading}>
						<ParagraphResultsList paragraphs={paragraphs} />
					</LoadWrapper>
				</PivotItem>
				<PivotItem headerText={t('PogisDocuments')} itemKey='pogisDocuments'>
					<LoadWrapper loading={pogisLoading}>
						<PogisArchiveResultsList pogisDocuments={pogisDocuments} />
					</LoadWrapper>
				</PivotItem>
			</Pivot>
		</Stack>
	);
};
