import React, {useEffect} from 'react';
import {
	TooltipHost,
	Stack,
	Text,
	IconButton,
	Theme,
	useTheme,
	mergeStyleSets,
} from '@fluentui/react';
import {useStore} from 'react-context-hook';
import {useTranslation} from 'react-i18next';

export const FaqComponent = ({questions}: any) => {
	const {t} = useTranslation('common/faq');
	const theme: Theme = useTheme();
	const [panel, setPanel] = useStore('panel', false);

	const classNames = mergeStyleSets({
		arrayItem: {
			borderRadius: '10px',
			color: theme?.palette.white,
			background: theme?.palette.red,

			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
			whiteSpace: 'nowrap',
		},
		arrayButton: {
			cursor: 'pointer',
		},
	});

	return (
		<div>
			<TooltipHost
				key={`faq`}
				styles={{
					root: {
						cursor: 'pointer',
					},
				}}
				tooltipProps={{
					onRenderContent: () => (
						<Stack
							styles={{
								root: {
									cursor: 'pointer',
								},
							}}
						>
							<Text
								variant='medium'
								onClick={() => {
									setPanel(true);
								}}
							>
								{t('OpenFaq')}
							</Text>
						</Stack>
					),
				}}
			>
				<div
					style={{display: 'flex'}}
					onClick={() => {
						setPanel(true);
					}}
				>
					<IconButton
						iconProps={{
							iconName: 'Chat',
							styles: {
								root: {
									color: theme.palette.black,
								},
							},
						}}
					/>

					<div>
						<Text
							className={`${classNames.arrayItem} ${classNames.arrayButton}`}
						>
							{questions?.count}
						</Text>
					</div>
				</div>
			</TooltipHost>
		</div>
	);
};
