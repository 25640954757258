/**
 * This file contains common hooks that are not in other files.
 */

import {useTranslation} from 'react-i18next';
import {REQUIREMENTS_TRANSLATION_NAMESPACE} from '../requirementsConstants';

export const useRequirementsTranslation = () => {
	return useTranslation(REQUIREMENTS_TRANSLATION_NAMESPACE);
};

export const useRequirementsTooltipTranslation = () => {
	return useTranslation('features/requirements', {
		keyPrefix: 'TooltipsText',
	});
};

export const useEnumsTranslation = () => {
	const {t: tStatus} = useTranslation('common/enums', {
		keyPrefix: 'RequirementStatus',
	});

	const {t: tCategory} = useTranslation('common/enums', {
		keyPrefix: 'RequirementCategory',
	});

	return {
		tStatus,
		tCategory,
	};
};
