import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoubletCheckRegulatoryDocumentFileMutationVariables = Types.Exact<{
  input: Types.DoubletcheckRegulatoryDocumentFileInput;
}>;


export type DoubletCheckRegulatoryDocumentFileMutation = { __typename?: 'Mutation', doubletcheckRegulatoryDocumentFile: { __typename?: 'DoubletcheckRegulatoryDocumentFilePayload', regulatoryDocument?: Array<{ __typename?: 'RegulatoryDocument', name: string, id: string, regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string } | null }> | null } };


export const DoubletCheckRegulatoryDocumentFileDocument = gql`
    mutation DoubletCheckRegulatoryDocumentFile($input: DoubletcheckRegulatoryDocumentFileInput!) {
  doubletcheckRegulatoryDocumentFile(input: $input) {
    regulatoryDocument {
      name
      id
      regulation {
        id
        regulationNumber
      }
    }
  }
}
    `;
export type DoubletCheckRegulatoryDocumentFileMutationFn = Apollo.MutationFunction<DoubletCheckRegulatoryDocumentFileMutation, DoubletCheckRegulatoryDocumentFileMutationVariables>;

/**
 * __useDoubletCheckRegulatoryDocumentFileMutation__
 *
 * To run a mutation, you first call `useDoubletCheckRegulatoryDocumentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoubletCheckRegulatoryDocumentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doubletCheckRegulatoryDocumentFileMutation, { data, loading, error }] = useDoubletCheckRegulatoryDocumentFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoubletCheckRegulatoryDocumentFileMutation(baseOptions?: Apollo.MutationHookOptions<DoubletCheckRegulatoryDocumentFileMutation, DoubletCheckRegulatoryDocumentFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoubletCheckRegulatoryDocumentFileMutation, DoubletCheckRegulatoryDocumentFileMutationVariables>(DoubletCheckRegulatoryDocumentFileDocument, options);
      }
export type DoubletCheckRegulatoryDocumentFileMutationHookResult = ReturnType<typeof useDoubletCheckRegulatoryDocumentFileMutation>;
export type DoubletCheckRegulatoryDocumentFileMutationResult = Apollo.MutationResult<DoubletCheckRegulatoryDocumentFileMutation>;
export type DoubletCheckRegulatoryDocumentFileMutationOptions = Apollo.BaseMutationOptions<DoubletCheckRegulatoryDocumentFileMutation, DoubletCheckRegulatoryDocumentFileMutationVariables>;