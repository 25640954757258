import {LoadWrapper} from 'components/LoadWrapper';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
	useGetPogisDocumentsQuery,
	useCreatePogisDocumentMutation,
	useUpdatePogisDocumentMutation,
	GetPogisDocumentsDocument,
} from './hooks/PogisDocuments.generated';
import {PogisDocumentsTooltipTranslationProvider} from './components/PogisDocumentTranslationTooltipProvider';
import {EntityListColumn, EntityPage} from 'components';
import {PogisDate, PogisDateInput, PogisDocument} from 'types';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {RenderAdditionalFormElements} from 'components/EntityPage/EntityFormPanels';
import PogisDocumentFormelements from './components/PogisDocumentFormElements';
import {useUserContext} from 'authentication/UserContext';
import {getPogisArchiveColumns} from './hooks/UsePogisArchiveColumns';
import {
	createSearchParams,
	NavigateFunction,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';

const PogisDocumentPage: React.FC = () => {
	const {t} = useTranslation('features/pogisdocuments', {
		keyPrefix: 'PogisDocumentsList',
	});

	const [createPogisDocumentMutation] = useCreatePogisDocumentMutation();
	const [updatePogisDocumentMutation] = useUpdatePogisDocumentMutation();

	const {isAdmin, isReader} = useUserContext();
	const [searchParams] = useSearchParams();

	const filter = searchParams.get('id') ?? '';

	const {loading, data} = useGetPogisDocumentsQuery({
		variables: {pogisId: filter},
	});

	const pogisDocuments = React.useMemo(
		() =>
			(data?.pogisDocuments ?? []).slice().sort((a, b) => {
				const aComp = a.pogisId.startsWith('GSO')
					? '.' + a.pogisId
					: a.pogisId.padStart(5, '0');
				const bComp = b.pogisId.startsWith('GSO')
					? '.' + b.pogisId
					: b.pogisId.padStart(5, '0');
				return aComp.localeCompare(bComp) * -1;
			}),
		[data],
	);

	const createPogisDocument = React.useCallback(
		(pogisDocument: PogisDocument) => {
			const input = mapInput(pogisDocument);
			createPogisDocumentMutation({
				variables: {
					input,
				},
			});
		},
		[],
	);

	const updatePogisDocument = React.useCallback(
		(pogisDocument: PogisDocument) => {
			const input = mapInput(pogisDocument);
			updatePogisDocumentMutation({
				variables: {
					input: {
						id: pogisDocument.id,
						...input,
					},
				},
				refetchQueries: [GetPogisDocumentsDocument],
			});
		},
		[],
	);

	const navigate = useNavigate();

	const handleViewingItem = useCallback(
		(pogis: PogisDocument) => {
			navigate({
				pathname: '',
				search: createSearchParams({
					id: pogis.pogisId,
				}).toString(),
			});
		},
		[navigate],
	);

	const columns = React.useMemo(
		() => [...getPogisArchiveColumns(t)] as EntityListColumn[],
		[t],
	);

	const renderFormElements: RenderAdditionalFormElements<
		PogisDocument
	> = control => {
		return <PogisDocumentFormelements control={control} />;
	};

	return (
		<EntityContextProvider>
			<LoadWrapper loading={loading}>
				<PogisDocumentsTooltipTranslationProvider>
					<EntityPage
						items={pogisDocuments as any}
						entityDisplayName='POGIS Documents'
						createEntity={createPogisDocument}
						updateEntity={updatePogisDocument}
						deleteEntity={null as any}
						hideCreate={true}
						hideDelete={true}
						additionalColumns={columns}
						withNameColumn={false}
						disableEdit={() => !isAdmin}
						hideEdit={isReader}
						withNameColumnInPanel={false}
						renderAdditionalFormElements={renderFormElements}
						onViewItem={handleViewingItem}
					/>
				</PogisDocumentsTooltipTranslationProvider>
			</LoadWrapper>
		</EntityContextProvider>
	);
};

export function mapInput(pogisDocument: PogisDocument) {
	function getDateInput(dates: PogisDate[]): PogisDateInput[] {
		return dates.map(item => {
			const {__typename, ...rest} = item;
			return rest;
		});
	}

	return {
		pogisId: pogisDocument.pogisId,
		keywords: pogisDocument.keywords,
		markets: pogisDocument.markets,
		regulationStatus: pogisDocument.regulationStatus,
		shortInfo: pogisDocument.shortInfo,
		dates: getDateInput(pogisDocument.dates),
		standards: pogisDocument.standards,
		associations: pogisDocument.associations,
		editor: pogisDocument.editor,
		docStatus: pogisDocument.docStatus,
		relatedDocuments: pogisDocument.relatedDocuments,
		linkVersion: pogisDocument.linkVersion,
	};
}

export default PogisDocumentPage;
