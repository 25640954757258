import React from 'react';
import {
	ApolloClient,
	ApolloProvider,
	DefaultOptions,
	FieldPolicy,
	InMemoryCache,
} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initializeIcons} from '@fluentui/react/lib/Icons';
import {getToken, MsalAuthComponent, msalConfig} from 'authentication';
import {PublicClientApplication} from '@azure/msal-browser';
import {setContext} from '@apollo/client/link/context';
import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';
import {initI18n} from 'i18n';
import {getConfig} from 'config';
import {ErrorModal, NotificationProvider} from 'components';
import {useBoolean} from '@fluentui/react-hooks';
import {useNotificationBar} from 'hooks';
import {MessageBarType} from '@fluentui/react';
import {OperationDefinitionNode} from 'graphql';
import {RegulatoryDocumentParagraph} from 'types';
import _ from 'lodash';
import {namesOfMergeableArrayFields} from 'features/RegulatoryDocuments/components/DocumentDetails/EditParagraphsForm/optimisticData/updateRegDocParagraphMutations/mergeableArrayFields';
import {NetworkError} from '@apollo/client/errors';

const resourceFileContext = require.context('locales', true, /\.json$/, 'sync');
initI18n(resourceFileContext);

initializeIcons();

const config = getConfig();

const httpLink: any = createUploadLink({
	uri: config.REACT_APP_SERVICE_URL,
	headers: {'GraphQL-preflight': '1'},
});

const msalInstance = new PublicClientApplication(msalConfig);

const authLink = setContext(async (_, {headers}) => {
	// Get the authentication token from local storage if it exists
	const token = await getToken(msalInstance);
	// Return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const acceptIncoming = <Item extends unknown>(
	existing: Item,
	incoming: Item,
): Item => {
	return incoming;
};

type ParagraphKey = keyof RegulatoryDocumentParagraph;

type TypePolicyPair = [ParagraphKey, FieldPolicy];

const createParagraphArrayTypePolicyPair = (
	fieldName: ParagraphKey,
): TypePolicyPair => {
	return [fieldName, {merge: acceptIncoming}];
};

const getParagraphArrayFieldNames = (): ParagraphKey[] => {
	return [
		...namesOfMergeableArrayFields,
		'phaseIn',
		'phaseOut',
		'keywordAssignments',
		'attachments',
	];
};

type ParagraphArrayPolicies = {[key: string]: FieldPolicy};

const createParagraphArrayTypePolicyPairs = (): TypePolicyPair[] => {
	const fieldNames: ParagraphKey[] = getParagraphArrayFieldNames();
	return fieldNames.map(createParagraphArrayTypePolicyPair);
};

const getParagraphArrayPolicies = (): ParagraphArrayPolicies => {
	const pairs: TypePolicyPair[] = createParagraphArrayTypePolicyPairs();
	return _.fromPairs(pairs);
};

// Note: will only retry Network Errors
const retryLink = new RetryLink({
	delay: {
		initial: 300,
		max: Infinity,
		jitter: true,
	},
	attempts: {
		max: 4,
		retryIf: error => error.statusCode === 500,
	},
});

const getCache = (): InMemoryCache => {
	return new InMemoryCache({
		typePolicies: {
			RegulatoryDocument: {
				fields: {workflow: {merge: true}},
			},
			RegulatoryDocumentParagraph: {
				fields: getParagraphArrayPolicies(),
			},
		},
	});
};

export const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

const defaultFetchOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'cache-and-network',
	},
};

const isEmptyError = (err: NetworkError) => {
	return err === null || Object.keys(err).length === 0;
};

const ApolloProviderErrorHandler: React.FC<{children: React.ReactNode}> = ({
	children,
}) => {
	const [
		erroModalIsOpen,
		{setTrue: openErrorModal, setFalse: closeErrorModal},
	] = useBoolean(false);
	const [fullErrorMessage, setFullErrorMessage] = React.useState<
		string | undefined
	>();
	const showError = React.useCallback((message: string) => {
		console.log(`>>> showError with message ${message}`);
		setFullErrorMessage(message);
		openErrorModal();
	}, []);
	const {setMessage} = useNotificationBar();

	const apolloClient = React.useMemo(() => {
		const errorLink = onError(({graphQLErrors, networkError, operation}) => {
			const isMutation = operation.query.definitions.some(
				d => (d as OperationDefinitionNode).operation === 'mutation',
			);

			if (graphQLErrors && isMutation) {
				console.error(
					`One or more graphql errors occured: ${JSON.stringify(
						graphQLErrors,
						undefined,
						2,
					)}`,
				);
				setMessage({
					message: `One or more graphql errors occured. Sorry for inconvenience.`,
					timeout: 0,
					options: {
						messageBarType: MessageBarType.error,
					},
				});

				return;
			}

			if (networkError) {
				console.error(`Network Error occured: ${networkError}`);
				if (isEmptyError(networkError)) {
					return;
				}

				setMessage({
					message: `Network Error occured. Sorry for inconvenience.`,
					timeout: 0,
					options: {
						messageBarType: MessageBarType.error,
					},
				});
			}
		});

		return new ApolloClient({
			link: authLink.concat(errorLink).concat(retryLink).concat(httpLink),
			cache: getCache(),
			defaultOptions: defaultFetchOptions,
		});
	}, []);

	return (
		<ApolloProvider client={apolloClient}>
			<React.Fragment>
				<ErrorModal
					isOpen={erroModalIsOpen}
					onDismiss={closeErrorModal}
					error={fullErrorMessage}
				/>
				{children}
			</React.Fragment>
		</ApolloProvider>
	);
};

root.render(
	<NotificationProvider>
		<ApolloProviderErrorHandler>
			<MsalAuthComponent msalInstance={msalInstance}>
				<App />
			</MsalAuthComponent>
		</ApolloProviderErrorHandler>
	</NotificationProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
