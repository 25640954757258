import {
	DefaultButton,
	Dialog,
	ITextFieldProps,
	Label,
	PrimaryButton,
	Stack,
} from '@fluentui/react';

import {useForm} from 'react-hook-form';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {TextFieldWithTooltip} from 'features/localizedTooltips';
import React, {useState, useMemo, useEffect} from 'react';
import {mapToRef} from 'helpers';
import {useTranslation} from 'react-i18next';
import {
	useVehicleProjectsForFaqQuery,
	useCreateQuestionMutation,
	QuestionsDocument,
	useQuestionCategoryLazyQuery,
	QuestionCountsByEntityTypeDocument,
	useGetAllKeywordsForFaqQuery,
} from './Faq.generated';

import {ControlledDropdown, ControlledTagPicker} from 'components/hookForms';

import _ from 'lodash';
import {useFileUpload} from 'hooks';

import {AttachmentsField} from 'components/AttachmentsField';
import {PeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {UserRole} from 'types';
import {useStore} from 'react-context-hook';
import {
	VEHICLE_PROJECT_TYPE,
	VehicleProjectsForFAQ,
} from './VehicleProjectsForFAQ ';
import {getCollectionName} from './EntityTypeMapper';

const Roles = [{name: 'VKO'}, {name: 'VEX'}];
export const CreateQuestionDialog: React.FC<any> = ({
	showDialog,
	setShowDialog,
}) => {
	const [questionCategories, setQuestionCategories] = useStore<any>(
		'questionCategories',
		[],
	);

	const [fetchCategories, {data: categories, loading, error, refetch}] =
		useQuestionCategoryLazyQuery();

	useEffect(() => {
		if (questionCategories?.length === 0) {
			fetchCategories();
		}
	}, [questionCategories]);

	useEffect(() => {
		if (categories) {
			setQuestionCategories(
				categories?.questionCategories?.map((e: any) => {
					return {
						key: e.id,
						text: t(e.name),
					};
				}) || [],
			);
		}
	}, [categories]);

	const [selectedItem, setSelectedItem] = useState(null);

	const {selectedItems} = useEntityContext<any>();

	// useEntityContext is set to null after clicking on any button, panel so we need to preserve selection
	useEffect(() => {
		if (selectedItems && selectedItems.length > 0) {
			setSelectedItem(selectedItems[0]);
		}
	}, [selectedItems]);

	const {t} = useTranslation('common/faq');

	const [value, setValue] = useState('');

	const {data} = useVehicleProjectsForFaqQuery({});
	const {data: keywords} = useGetAllKeywordsForFaqQuery({});

	const keywordOptions = React.useMemo(() => {
		return keywords?.keywords || [];
	}, [keywords]);
	const [createQuestionMutation] = useCreateQuestionMutation();

	const {control, getValues, reset, watch} = useForm<any>({
		reValidateMode: 'onSubmit',
		mode: 'onBlur',
	});

	const QUESTION_TYPE = 'questionType';
	const ROLES_TYPE = 'roles';
	const KEYWORDS = 'keywords';

	const vehicleProjects = useMemo((): any[] => {
		const projects: any[] = data?.vehicleProjects ?? [];

		return _.sortBy(projects, ['modelSeries']);
	}, [data]);

	type OnChange = Exclude<ITextFieldProps['onChange'], undefined>;

	const handleChange: OnChange = (_event, newValue): void => {
		setValue(newValue ?? '');
	};

	const closeDialog = () => {
		setShowDialog(false);
		setValue('');
		reset();
	};

	const postQuestion = () => {
		createQuestionMutation({
			variables: {
				input: {
					name: value,

					targetUserRefs: mapToRef(users),
					vehicleCategoryRefs: mapToRef(getValues(VEHICLE_PROJECT_TYPE)),
					targetCollection: getCollectionName((selectedItem as any).__typename),
					keywordRefs: mapToRef(getValues(KEYWORDS)),
					targetRecords: [
						{
							entityId: (selectedItem as any).id,
							entityType: (selectedItem as any).__typename,
						},
					],
					categoryRef: {
						id: getValues(QUESTION_TYPE),
					},
					attachments: attachments?.map(f => ({
						attachmentId: f.file.name,
						file: f.file,
					})),
					roles: getValues(ROLES_TYPE)?.map((e: any) => e.name),
				},
			},
			refetchQueries: [QuestionsDocument, QuestionCountsByEntityTypeDocument],
		});

		closeDialog();
	};

	const {attachments, FileUploadComponent} = useFileUpload(undefined, {
		renderCategory: false,
	});

	const [users, setUsers] = useState([]);

	const onUserInputChange = React.useCallback(
		(items: any) => setUsers(items || []),
		[],
	);

	return (
		<Dialog
			hidden={!showDialog}
			styles={{main: {maxWidth: '600px !important'}}}
			modalProps={{
				isBlocking: true,
				isDarkOverlay: true,
				styles: {main: {width: '750px !important'}},
			}}
			dialogContentProps={{
				title: `${t('PostQuestion')} ${(selectedItem as any)?.name}`,
			}}
			onDismiss={closeDialog}
		>
			<ControlledDropdown
				label={`${t(`QuestionType`)} *`}
				name={QUESTION_TYPE}
				control={control}
				options={questionCategories}
				required={true}
			/>
			<VehicleProjectsForFAQ
				control={control}
				t={t}
				vehicleProjects={vehicleProjects}
			/>

			<ControlledTagPicker
				name={KEYWORDS}
				control={control}
				selectableItems={keywordOptions}
				label={t(`SelectKeywords`)}
				hideTagIcon
				placeholder=''
				getKey={item => (item as any)?.id}
				getName={item => (item as any)?.name}
			/>

			<TextFieldWithTooltip
				multiline
				label={t('QuestionContent')}
				placeholder={t('QuestionContent')}
				onChange={handleChange}
				value={value}
				styles={{root: {margin: '1rem 0 0.5rem'}}}
				autoAdjustHeight
				required={true}
			/>
			<AttachmentsField t={t} FileUploadComponent={FileUploadComponent} />
			<ControlledTagPicker
				name={ROLES_TYPE}
				control={control}
				selectableItems={Roles}
				label={t(`SelectRoles`)}
				hideTagIcon
				placeholder=''
				getKey={item => (item as any)?.name}
				getName={item => (item as any)?.name}
			/>

			<Label> {t('TargetUsers')}</Label>
			<PeoplePicker
				onChange={items => onUserInputChange(items ?? [])}
				excludeCurrentUser
				roles={[UserRole.Vko, UserRole.Vex, UserRole.SystemAdministrator]}
			/>
			<Stack
				horizontal
				horizontalAlign='end'
				tokens={{childrenGap: 5, padding: '20px 0 0 0'}}
			>
				<Stack.Item>
					<PrimaryButton
						onClick={postQuestion}
						ariaLabel={'ConfirmDialogButton'}
						disabled={watch(QUESTION_TYPE)?.length === 0 || !value}
					>
						{t('Confirm')}
					</PrimaryButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton onClick={closeDialog} ariaLabel={'CancelDialogButton'}>
						{t('Cancel')}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
