export const RequirementsCollectionName = 'requirements';
export const RegulationsCollectionName = 'regulations';
export const getCollectionName = (entity: string) => {
	if (entity === 'Requirement') {
		return RequirementsCollectionName;
	}
	if (entity === 'Regulation') {
		return RegulationsCollectionName;
	}
	return '';
};
