import {
	RegulatoryDocument,
	RegulatoryDocumentParagraph,
	Requirement,
	WorkflowStatus,
} from 'types';
import {TFunction} from 'react-i18next';
import {
	IHTMLXL,
	IArrayRoles,
	renderHeaderXL,
	renderHTMLExcel,
	cleanHTMLExcel,
	createZIPXL,
} from './ExcelExportCommon';
import i18n from 'i18n/i18n';
import {formatDateTime} from 'i18n/localeDateFormat';
import Excel, {RichText} from 'exceljs';

interface IRegulationVersionDetailPage {
	(
		entityListItems: any[],
		arrRoles: IArrayRoles,
		pageDetails: any,
		t: TFunction,
	): Promise<void>;
}

export const handleClickRegulationVersionDetailPage: IRegulationVersionDetailPage =
	async (entityListItems, arrRoles, pageDetails, t) => {
		const arrTables: string[] = [];
		const arrImagesUri: string[] = [];
		const arrImagesName: string[] = [];

		const arrParaFilt = entityListItems as RegulatoryDocumentParagraph[];
		const doc = pageDetails as RegulatoryDocument;

		const workbook = new Excel.Workbook();
		const sheet = workbook.addWorksheet(
			'TRACE_' +
				t('ParagraphsHeader', {
					ns: 'features/regulatorydocuments',
					keyPrefix: 'RegulatoryDocumentDetailsPage',
				}),
		);
		let arrColWidth: number[] = [];
		if (doc.workflow.status === WorkflowStatus.Finalized) {
			arrColWidth = [
				50, 100, 20, 20, 100, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
			];
		} else {
			arrColWidth = [
				30, 100, 20, 20, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
			];
		}

		const strPageName = t('ParagraphsHeader', {
			ns: 'features/regulatorydocuments',
			keyPrefix: 'RegulatoryDocumentDetailsPage',
		});

		renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

		let intRowCount = 4;
		let row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};

		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('RegulationNumber', {
							ns: 'features/regulations',
							keyPrefix: 'RegulationsList',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.regulation?.regulationNumber ?? '',
				},
			],
		};

		row.getCell('C').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('Name', {
							ns: 'features/regulations',
							keyPrefix: 'RegulationsList',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.regulation?.name ?? '',
				},
			],
		};

		row.getCell('D').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('RegulatoryDocuments', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.name,
				},
			],
		};

		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};

		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('KeywordsLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: (doc.keywords ?? []).map(a => a.name).join('; '),
				},
			],
		};

		const tempDivElement = document.createElement('div');
		tempDivElement.innerHTML = doc.summary
			? '<div>' + doc.summary + '</div>'
			: '<div></div>';

		const arrHTMLXL: IHTMLXL[] = [];
		const arrHTMLXLClean: IHTMLXL[] = [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text:
					t('ShortSummaryLabel', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'RegulatoryDocumentDetailsPage',
					}) + '\n',
			},
		];

		renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
		cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);

		row.getCell('C').value = {richText: arrHTMLXLClean as RichText[]};

		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};

		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('EffectiveDateLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.dateEffective
						? formatDateTime(new Date(doc.dateEffective), i18n)
						: '',
				},
			],
		};

		row.getCell('C').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('ExpirationDateLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.dateExpiration
						? formatDateTime(new Date(doc.dateExpiration), i18n)
						: '',
				},
			],
		};

		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};

		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('ModelYearLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.modelYear ? (doc.modelYear as unknown as string) : '',
				},
			],
		};

		row.getCell('C').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('NewTypesLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.dateNewTypes
						? formatDateTime(new Date(doc.dateNewTypes), i18n)
						: '',
				},
			],
		};

		row.getCell('D').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('NewRegistrationLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: doc.dateNewRegistration
						? formatDateTime(new Date(doc.dateNewRegistration), i18n)
						: '',
				},
			],
		};

		intRowCount++;
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		row.getCell('B').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('WorkflowStatus', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: t(
						doc.workflow && doc.workflow.status ? doc.workflow.status : '',
						{ns: 'common/enums', keyPrefix: 'WorkflowStatus'},
					),
				},
			],
		};

		row.getCell('C').value = {
			richText: [
				{
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						t('StatusLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}) + '\n',
				},
				{
					font: {bold: false, name: 'Porsche Next TT', size: 10},
					text: t(doc.status ? doc.status : '', {
						ns: 'common/enums',
						keyPrefix: 'RegulatoryDocumentStatus',
					}),
				},
			],
		};

		if (doc.attachments && doc.attachments.length > 0) {
			intRowCount++;
			row = sheet.getRow(intRowCount);
			row.getCell('B').alignment = {wrapText: true, vertical: 'top'};
			row.getCell('B').value = {
				richText: [
					{
						font: {bold: true, name: 'Porsche Next TT', size: 10},
						text: t('AttachmentsLabel', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
						}),
					},
				],
			};

			doc.attachments.forEach(iAttach => {
				intRowCount++;
				row = sheet.getRow(intRowCount);
				row.getCell('B').value = {
					text: iAttach.attachmentId,
					hyperlink: iAttach.file.uri,
					tooltip: t('Link', {
						ns: 'components/EntityList/ExcelExport',
						keyPrefix: 'TooltipsText',
					}),
				};
				row.getCell('B').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
			});
		}

		intRowCount++;
		intRowCount++;

		row = sheet.getRow(intRowCount);
		row.font = {
			bold: true,
			name: 'Porsche Next TT',
			size: 10,
			color: {argb: 'FFFFFFFF'},
		};

		sheet.addTable({
			name: 'tableSegments',
			ref: 'B' + String(intRowCount),
			style: {
				showRowStripes: true,
			},
			columns: [
				{
					name: t('KeywordAssignments', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('Paragraph', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('Categories', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('Keywords', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('Requirements', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('Tags', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('VehicleCategory', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('DriveVariants', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('DateNewTypes', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('DateNewRegistration', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('Comprehensive', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('ModelYear', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('PhaseIn', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('PhaseOut', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
				{
					name: t('Summary', {
						ns: 'features/regulatorydocuments',
						keyPrefix: 'ParagraphsList',
					}),
					filterButton: true,
				},
			],
			rows: [],
		});

		const table = sheet.getTable('tableSegments');

		let arrRichTexts = [];
		let arrRichTextsReq = [];

		let intTableCount = 0;

		let status = '';
		let category = '';
		let keyword = '';
		let tag = '';
		let vehClass = '';
		let drivetrain = '';
		let dateNT = '';
		let dateNF = '';
		let dateUB = '';
		let modelyear = '';
		let phaseIn = '';
		let phaseOut = '';
		let comment = '';

		if (arrParaFilt.length === 0) {
			table.addRow([
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
			]);
		}

		intRowCount++;
		for (let ii = 0; ii < arrParaFilt.length; ii++) {
			let arrRequirementsSorted = [] as Requirement[];

			if (!arrRoles.isReader) {
				arrRequirementsSorted = arrParaFilt[ii].requirements.slice();
				arrRequirementsSorted.sort(function (a, b) {
					return a.requirementId! < b.requirementId! ? -1 : 1;
				});
			}

			arrRichTexts = [];
			arrRichTextsReq = [];
			status = (arrParaFilt[ii].keywordAssignments ?? [])
				.map(keywordAssign =>
					keywordAssign.vexClusterAssignments
						.map(
							iVexAssign =>
								keywordAssign.keyword.name +
								'|' +
								t(iVexAssign.keywordStatus, {
									ns: 'features/regulatorydocuments',
									keyPrefix: 'ParagraphsList',
								}) +
								'|' +
								iVexAssign.vexCluster.name,
						)
						.join(';\n'),
				)
				.join(';\n');

			category = arrParaFilt[ii].categories
				.map(category => category.name)
				.join(';\n');

			keyword = arrParaFilt[ii].keywords
				.map(keyword => keyword.name)
				.join(';\n');

			tag = arrParaFilt[ii].tags.map(tag => tag.name).join(';\n');

			vehClass = arrParaFilt[ii].vehicleCategories
				.map(category => category.name)
				.join(';\n');

			drivetrain = arrParaFilt[ii].driveVariants
				.map(drive => drive.name)
				.join(';\n');

			dateNT = arrParaFilt[ii].dateNewTypes
				? formatDateTime(new Date(arrParaFilt[ii].dateNewTypes), i18n)
				: '';

			dateNF = arrParaFilt[ii].dateNewRegistration
				? formatDateTime(new Date(arrParaFilt[ii].dateNewRegistration), i18n)
				: '';

			dateUB = arrParaFilt[ii].comprehensive
				? formatDateTime(new Date(arrParaFilt[ii].comprehensive), i18n)
				: '';

			modelyear = String(arrParaFilt[ii].modelYear ?? '');

			phaseIn = (arrParaFilt[ii].phaseIn ?? [])
				.map(
					PI =>
						(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
						' (' +
						(PI.status ?? '') +
						')',
				)
				.join(';\n');

			phaseOut = (arrParaFilt[ii].phaseOut ?? [])
				.map(
					PI =>
						(PI.date ? formatDateTime(new Date(PI.date), i18n) : '') +
						' (' +
						(PI.status ?? '') +
						')',
				)
				.join(';\n');

			comment = (arrParaFilt[ii].comments ?? [])
				.map(comment => comment.comment ?? ' ')
				.join(';\n');

			for (let jj = 0; jj < arrRequirementsSorted.length; jj++) {
				arrRichTextsReq.push({
					font: {bold: true, name: 'Porsche Next TT', size: 10},
					text:
						arrRequirementsSorted[jj].name +
						' (' +
						arrRequirementsSorted[jj].requirementId +
						')\n\n',
				});
			}

			for (let jj = 0; jj < arrParaFilt[ii].elements.length; jj++) {
				if (String(arrParaFilt[ii].elements[jj].type) === 'TEXT') {
					if (
						(arrParaFilt[ii].elements[jj].text ?? '').startsWith(
							'VKO_PDF_DISMANTLED',
						)
					) {
						arrRichTexts.push({
							font: {bold: false, name: 'Porsche Next TT', size: 10},
							text:
								t(
									arrParaFilt[ii].elements[jj].text ===
										'VKO_PDF_DISMANTLED_FALSE'
										? 'DismantledFalse'
										: 'DismantledTrue',
									{
										ns: 'features/regulatorydocuments',
										keyPrefix: 'RegulatoryDocumentDetailsPage',
									},
								) + '\n',
						});
					} else if (arrParaFilt[ii].elements[jj].isHeader === true) {
						arrRichTexts.push({
							font: {bold: true, name: 'Porsche Next TT', size: 10},
							text: arrParaFilt[ii].elements[jj].text + '\n',
						});
					} else {
						arrRichTexts.push({
							font: {bold: false, name: 'Porsche Next TT', size: 10},
							text: arrParaFilt[ii].elements[jj].text + '\n',
						});
					}
				}

				if (String(arrParaFilt[ii].elements[jj].type) === 'NEW_LINE') {
					arrRichTexts.push({text: '\n'});
				}

				if (jj === arrParaFilt[ii].elements.length - 1) {
					row = sheet.getRow(intRowCount);
					row.alignment = {wrapText: true, vertical: 'top'};
					row.getCell('C').alignment = {
						indent: arrParaFilt[ii].level,
						wrapText: true,
						vertical: 'top',
					};
					table.addRow([
						status,
						{richText: arrRichTexts, wrapText: true, vertical: 'top'},
						category,
						keyword,
						{richText: arrRichTextsReq, wrapText: true, vertical: 'top'},
						tag,
						vehClass,
						drivetrain,
						dateNT,
						dateNF,
						dateUB,
						modelyear,
						phaseIn,
						phaseOut,
						comment,
					]);
					intRowCount++;
					arrRichTexts = [];
				}
			}

			const arrLinks: string[] = [];
			const arrLinkText: string[] = [];

			for (let jj = 0; jj < arrParaFilt[ii].elements.length; jj++) {
				if (
					String(arrParaFilt[ii].elements[jj].type) === 'IMAGE' ||
					String(arrParaFilt[ii].elements[jj].type) === 'TABLE'
				) {
					arrImagesUri.push(String(arrParaFilt[ii].elements[jj].asset?.uri));
					arrImagesName.push(
						String(
							(arrParaFilt[ii].elements[jj].asset?.fileName.split(
								'.',
							)[0] as string) + '.html',
						),
					);
					arrLinks.push('assets/' + arrImagesName[arrImagesName.length - 1]);
					arrLinkText.push(
						t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
					);
				}

				if (String(arrParaFilt[ii].elements[jj].type) === 'HTML_TABLE') {
					arrTables.push(String(arrParaFilt[ii].elements[jj].text));
					arrLinks.push('assets/html_table_' + intTableCount + '.html');
					arrLinkText.push(
						t('LinkTable', {ns: 'components/EntityList/ExcelExport'}),
					);
					intTableCount++;
				}
			}

			let intRowCountReq = 0;
			let arrHTMLXL: IHTMLXL[];
			let arrHTMLXLClean: IHTMLXL[];
			for (let jj = 0; jj < arrRequirementsSorted.length; jj++) {
				arrHTMLXL = [];
				arrHTMLXLClean = [
					{
						font: {bold: true, name: 'Porsche Next TT', size: 10},
						text:
							arrRequirementsSorted[jj].name +
							' (' +
							arrRequirementsSorted[jj].requirementId +
							')\n',
					},
				];
				tempDivElement.innerHTML = arrRequirementsSorted[jj].definition
					? '<div>' + arrRequirementsSorted[jj].definition + '</div>'
					: '<div></div>';
				renderHTMLExcel(tempDivElement.childNodes[0].childNodes, arrHTMLXL);
				cleanHTMLExcel(arrHTMLXL, arrHTMLXLClean);

				for (
					let jj = 0;
					jj < tempDivElement.getElementsByTagName('img').length;
					jj++
				) {
					if (
						arrImagesName.indexOf(
							(arrRequirementsSorted[jj].requirementId as string) +
								'_' +
								jj +
								'.html',
						) === -1
					) {
						arrImagesUri.push(
							tempDivElement
								.getElementsByTagName('img')[0]
								.getAttribute('src') as string,
						);
						arrImagesName.push(
							(arrRequirementsSorted[jj].requirementId as string) +
								'_' +
								jj +
								'.html',
						);
					}
				}

				row = sheet.getRow(intRowCount);
				row.outlineLevel = 1;
				row.alignment = {wrapText: true, vertical: 'top'};
				row.getCell('B').fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {argb: '8DB4E2'},
				};
				row.getCell('C').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
				row.getCell('C').alignment = {
					indent: arrParaFilt[ii].level,
					wrapText: true,
					vertical: 'top',
				};
				row.getCell('F').alignment = {
					indent: 1,
					wrapText: true,
					vertical: 'top',
				};
				table.addRow([
					'',
					intRowCountReq < arrLinks.length
						? {
								text: arrLinkText[intRowCountReq],
								hyperlink: arrLinks[intRowCountReq],
								tooltip: t('Link', {
									ns: 'components/EntityList/ExcelExport',
									keyPrefix: 'TooltipsText',
								}),
						  }
						: '',
					'',
					'',
					{richText: arrHTMLXLClean, wrapText: true, vertical: 'top'},
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
				]);
				intRowCount++;
				intRowCountReq++;

				for (
					let kk = 0;
					kk < tempDivElement.getElementsByTagName('img').length;
					kk++
				) {
					row = sheet.getRow(intRowCount);
					row.outlineLevel = 1;
					row.alignment = {wrapText: true, vertical: 'top'};
					row.getCell('C').alignment = {
						indent: arrParaFilt[ii].level,
						wrapText: true,
						vertical: 'top',
					};
					row.getCell('B').fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: {argb: '8DB4E2'},
					};
					row.getCell('C').style.font = {
						underline: true,
						bold: false,
						color: {argb: '00B0F0'},
					};
					row.getCell('C').alignment = {
						indent: arrParaFilt[ii].level,
						wrapText: true,
						vertical: 'top',
					};
					row.getCell('F').alignment = {
						indent: 1,
						wrapText: true,
						vertical: 'top',
					};
					row.getCell('F').style.font = {
						underline: true,
						bold: false,
						color: {argb: '00B0F0'},
					};
					table.addRow([
						'',
						intRowCountReq < arrLinks.length
							? {
									text: arrLinkText[intRowCountReq],
									hyperlink: arrLinks[intRowCountReq],
									tooltip: t('Link', {
										ns: 'components/EntityList/ExcelExport',
										keyPrefix: 'TooltipsText',
									}),
							  }
							: '',
						'',
						'',
						{
							text: t('LinkImage', {ns: 'components/EntityList/ExcelExport'}),
							hyperlink:
								(('assets/' +
									arrRequirementsSorted[jj].requirementId) as string) +
								'_' +
								kk +
								'.html',
							tooltip: t('Link', {
								ns: 'components/EntityList/ExcelExport',
								keyPrefix: 'TooltipsText',
							}),
						},
						'',
						'',
						'',
						'',
						'',
						'',
						'',
						'',
						'',
					]);
					intRowCount++;
					intRowCountReq++;
				}
			}

			for (let jj = intRowCountReq; jj < arrLinks.length; jj++) {
				row = sheet.getRow(intRowCount);
				row.outlineLevel = 1;
				row.alignment = {wrapText: true, vertical: 'top'};
				row.getCell('B').fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {argb: '8DB4E2'},
				};
				row.getCell('C').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
				row.getCell('C').alignment = {
					indent: arrParaFilt[ii].level,
					wrapText: true,
					vertical: 'top',
				};
				table.addRow([
					'',
					{
						text: arrLinkText[jj],
						hyperlink: arrLinks[jj],
						tooltip: t('Link', {
							ns: 'components/EntityList/ExcelExport',
							keyPrefix: 'TooltipsText',
						}),
					},
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
				]);
				intRowCount++;
			}
		}

		table.commit();
		const strZipName =
			t('ParagraphsHeader', {
				ns: 'features/regulatorydocuments',
				keyPrefix: 'RegulatoryDocumentDetailsPage',
			}) +
			'_' +
			atob(doc.id).split('\n')[1].slice(1);
		await createZIPXL(
			workbook,
			strZipName,
			t,
			arrTables,
			arrImagesUri,
			arrImagesName,
			doc.attachments!,
		);
	};
