import React, {useMemo} from 'react';
import {Separator, Stack} from '@fluentui/react';
import {
	CompatibleDocRefs,
	ControlledTagPicker,
	EnumDropdown,
	ReferencePicker,
	RequirementsEnum,
} from 'components/hookForms';
import {
	ControlledRichtextEditor,
	ValueCompatibleWithRichTextEditor,
} from 'components/hookForms/ControlledRichtextEditor';
import {Control} from 'react-hook-form';
import {
	ReferenceType,
	Requirement,
	RequirementCategory,
	RequirementStatus,
	VexCluster,
} from 'types';
import {GetRequirementsFormDataQuery} from '../hooks/requirements.generated';
import {ProviderThatEnablesGettingTooltipsFromContext} from 'features/localizedTooltips';
import {
	useRequirementsTooltipTranslation,
	useRequirementsTranslation,
} from 'features/Requirements/hooks/useRequirementsTranslation';
import {parseTooltipNewlines} from 'helpers/tooltips';

/*
 * Data for selectable fields
 */

type NonNullableFormDataValue<Key extends keyof GetRequirementsFormDataQuery> =
	NonNullable<GetRequirementsFormDataQuery[Key]>;

export interface DataForSelectableFields {
	systemLevelData: NonNullableFormDataValue<'systemLevels'>;
	driveVariantData: NonNullableFormDataValue<'driveVariants'>;
	engineVariantData: NonNullableFormDataValue<'engineVariants'>;
	gearboxVariantData: NonNullableFormDataValue<'gearboxVariants'>;
	bodyworkVariantData: NonNullableFormDataValue<'bodyworkVariants'>;
	activeTypeMarketData: NonNullableFormDataValue<'activeTypeMarkets'>;
	vehicleCategoryData: NonNullableFormDataValue<'vehicleCategories'>;
	vexClusterData: NonNullableFormDataValue<'vexClusters'>;
	tagData: NonNullableFormDataValue<'tags'>;
}

export const createDataForSelectableFields = (
	data?: GetRequirementsFormDataQuery,
): DataForSelectableFields => ({
	systemLevelData: data?.systemLevels ?? [],
	driveVariantData: data?.driveVariants ?? [],
	engineVariantData: data?.engineVariants ?? [],
	gearboxVariantData: data?.gearboxVariants ?? [],
	bodyworkVariantData: data?.bodyworkVariants ?? [],
	activeTypeMarketData: data?.activeTypeMarkets ?? [],
	vehicleCategoryData: data?.vehicleCategories ?? [],
	vexClusterData: data?.vexClusters ?? [],
	tagData: data?.tags ?? [],
});

/*
 * Form fields
 */

/**
 * We don't pick any types from the Requirement here because it can change. To
 * ensure that we catch errors where the requirement is incompatible with the
 * form, we must not pick any types from Requirement.
 */
export interface CompatibleFormFields {
	status: RequirementStatus;
	category: RequirementCategory;
	definition?: ValueCompatibleWithRichTextEditor;
	systemLevels: DataForSelectableFields['systemLevelData'];
	vexClusters: SelectableVexClusters;
	gearboxVariants: DataForSelectableFields['gearboxVariantData'];
	bodyworkVariants: DataForSelectableFields['bodyworkVariantData'];
	activeTypeMarkets: DataForSelectableFields['activeTypeMarketData'];
	engineVariants: DataForSelectableFields['engineVariantData'];
	tags: DataForSelectableFields['tagData'];
	driveVariants: DataForSelectableFields['driveVariantData'];
	vehicleCategories: DataForSelectableFields['vehicleCategoryData'];
	documentReferences?: CompatibleDocRefs;
}

/*
 * Props
 */

type VexClusterFields = Pick<VexCluster, 'id' | 'name'>;

type SelectableVexClusters = VexClusterFields[];

type RequirementFormElementProps<
	CustomFormFields extends CompatibleFormFields,
> = {
	control: Control<CustomFormFields>;
	formData: GetRequirementsFormDataQuery | undefined;
	selectableVexClusters: SelectableVexClusters;
	id: Requirement['id'] | null;
	aggregationBtn: JSX.Element | null;
	disableRule?: (fieldName: string) => boolean;
};

/**
 * - Requires wrapping the item with a Stack.
 * - Requires translations.
 */
export const CommonFormElementsOfRequirements = <
	CustomFormFields extends CompatibleFormFields,
>({
	control,
	formData,
	selectableVexClusters,
	id,
	aggregationBtn,
	disableRule,
}: RequirementFormElementProps<CustomFormFields>): JSX.Element => {
	const {t} = useRequirementsTranslation();
	const {t: tt} = useRequirementsTooltipTranslation();

	const {
		systemLevelData,
		activeTypeMarketData,
		bodyworkVariantData,
		driveVariantData,
		engineVariantData,
		gearboxVariantData,
		vehicleCategoryData,
		tagData,
	} = useMemo(() => {
		return createDataForSelectableFields(formData);
	}, [formData]);

	const wrapWithStackItem = (
		field: JSX.Element,
		index: number,
	): JSX.Element => {
		return <Stack.Item key={index}>{field}</Stack.Item>;
	};

	/**
	 * We do not wrap each item manually because it's easier to set a key on an
	 * item than to wrap it with Stack.Item. We use Stack.Items so that developers
	 * can easily set the spacing between form fields.
	 */
	const renderMainContents = (): JSX.Element[] => {
		let index = 0;

		/**
		 * We use ++index here so we don't need to track the order of the indexes
		 */
		const items: JSX.Element[] = [
			<ControlledRichtextEditor
				label={t('Definition')}
				control={control}
				name={'definition'}
				enableImages={true}
				key={++index}
				rules={{required: t('RequiredField')}}
				disabled={disableRule?.('definition')}
			/>,
			<EnumDropdown
				name={'category'}
				label={t('RequirementCategoryTagPickerLabel')}
				control={control}
				enumType={RequirementCategory}
				enumName={'RequirementCategory'}
				rules={{required: t('RequiredField')}}
				defaultValue={RequirementCategory.ProductRequirement}
				key={++index}
				disabled={disableRule?.('category')}
				tooltipHostProps={{
					content: parseTooltipNewlines(tt('category')),
				}}
			/>,
			<ControlledTagPicker
				name={'systemLevels'}
				label={t('SystemLevelTagPickerLabel')}
				control={control}
				selectableItems={systemLevelData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				rules={{required: t('RequiredField')}}
				disabled={disableRule?.('systemLevels')}
			/>,
			<ControlledTagPicker
				name={'vexClusters'}
				control={control}
				label={t('VexClustersTagPickerLabel')}
				selectableItems={selectableVexClusters}
				getKey={item => item.id}
				getName={item => item.name}
				rules={{required: t('RequiredField')}}
				key={++index}
				disabled={disableRule?.('vexClusters')}
				tooltipHostProps={{
					content: parseTooltipNewlines(tt('vexClusters')),
				}}
			/>,
			<ControlledTagPicker
				name={'gearboxVariants'}
				label={t('GearboxVariantTagPickerLabel')}
				control={control}
				selectableItems={gearboxVariantData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				disabled={disableRule?.('gearboxVariants')}
				tooltipHostProps={{
					content: parseTooltipNewlines(tt('gearboxVariants')),
				}}
			/>,
			<ControlledTagPicker
				name={'bodyworkVariants'}
				label={t('BodyworkVariantTagPickerLabel')}
				control={control}
				selectableItems={bodyworkVariantData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				disabled={disableRule?.('bodyworkVariants')}
				tooltipHostProps={{
					content: parseTooltipNewlines(tt('bodyworkVariants')),
				}}
			/>,
			<ControlledTagPicker
				name={'activeTypeMarkets'}
				label={t('ActiveTypeMarketTagPickerLabel')}
				control={control}
				selectableItems={activeTypeMarketData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				disabled={disableRule?.('activeTypeMarkets')}
			/>,
			<ControlledTagPicker
				name={'engineVariants'}
				label={t('EngineVariantTagPickerLabel')}
				control={control}
				selectableItems={engineVariantData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				disabled={disableRule?.('engineVariants')}
			/>,
			<ControlledTagPicker
				name={'tags'}
				label={t('TagPickerLabel')}
				control={control}
				selectableItems={tagData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				disabled={disableRule?.('tags')}
				tooltipHostProps={{
					content: parseTooltipNewlines(tt('tags')),
				}}
			/>,
			<Separator key={++index} />,
			<ControlledTagPicker
				name={'driveVariants'}
				label={t('DriveVariantTagPickerLabel')}
				control={control}
				selectableItems={driveVariantData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				disabled={disableRule?.('driveVariants')}
			/>,
			<ControlledTagPicker
				name={'vehicleCategories'}
				label={t('VehicleCategoryTagPickerLabel')}
				control={control}
				selectableItems={vehicleCategoryData}
				getKey={item => item.id}
				getName={item => item.name}
				key={++index}
				disabled={disableRule?.('vehicleCategories')}
			/>,
		];

		return items.map(wrapWithStackItem);
	};

	const mainContents: JSX.Element[] = renderMainContents();

	/**
	 * Note that every child here is a stack item because the component should be
	 * wrapped in a stack. Furthermore, the provider here returns its children, so
	 * it does not affect the fact that all children rendered here are stack
	 * items.
	 */
	return (
		<>
			<ProviderThatEnablesGettingTooltipsFromContext>
				{mainContents}
			</ProviderThatEnablesGettingTooltipsFromContext>
			{aggregationBtn ? <Stack.Item>{aggregationBtn}</Stack.Item> : null}
			<Stack.Item>
				<Separator />
			</Stack.Item>
			<Stack.Item>
				<ReferencePicker
					control={control}
					name={'documentReferences'}
					label={t('ReferencesPickerLabel')}
					rules={{required: t('RequiredField')}}
					enumType={RequirementsEnum}
					referenceTypes={[ReferenceType.Requirement]}
					idsToExclude={id ? [id] : []}
				/>
			</Stack.Item>
		</>
	);
};
