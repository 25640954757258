import React from 'react';
import {PanelType} from '@fluentui/react';
import {EntityFormPanels} from 'components/EntityPage/EntityFormPanels';
import {RequirementDetailsPageFormInfo} from './useRequirementDetailsPageEditCmdAndFormInfo';
import {RequirementFromDetailsPage} from './requirementDetailsPage.types';
import {
	UpdateRequirementAttachments,
	useRequirementAttachmentsUpdater,
} from '../hooks/requirementFormHooks';
import {useRequirementsTranslation} from '../hooks/useRequirementsTranslation';
import {RequirementsTooltipTranslationProvider} from '../RequirementsTooltipTranslationProvider';
import {useCreateRequirementMutation} from '../hooks/requirements.generated';
import {RequirementFromListPage} from '../requirementPage.types';
import {RequirementUtilsService} from '../RequirementUtils.service';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {ConvoluteType, Requirement, RequirementStatus} from 'types';
import {useCloneRequirement} from '../hooks/useCloneRequirement';
import {createHrefFromRequirement} from '../requirementLinks.utils';
import {useNavigate} from 'react-router-dom';

type FormInfoFields = Pick<
	RequirementDetailsPageFormInfo,
	| 'updateRequirement'
	| 'refetchQueries'
	| 'renderFormElements'
	| 'commonStateForPanels'
	| 'setIsFormSubmittingOrRefetching'
>;

interface Props extends FormInfoFields {
	requirement: RequirementFromDetailsPage;
}
const {createCommonCreationInputFields} = new RequirementUtilsService();
export const RequirementDetailsPagePanelsForEditing = ({
	requirement,
	updateRequirement,
	refetchQueries,
	renderFormElements,
	commonStateForPanels,
	setIsFormSubmittingOrRefetching,
}: Props): JSX.Element => {
	const {t} = useRequirementsTranslation();
	const {cloneRequirement} = useCloneRequirement();

	const {data: convolutes} = useGetConvolutesQuery();
	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.Requirement,
			),
		[convolutes],
	);
	const navigate = useNavigate();

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id ?? '',
		},
	});

	const updateAttachments: UpdateRequirementAttachments =
		useRequirementAttachmentsUpdater();
	const [createRequirementMutation] = useCreateRequirementMutation();
	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	const createRequirement = React.useCallback(
		async (editedRequirement: RequirementFromListPage) => {
			if (editedRequirement.status === RequirementStatus.Final) {
				const res = await cloneRequirement(
					editedRequirement as Requirement,
					refetchQueries,
					rule,
					requirement,
				);
				if (res?.data?.createRequirement?.requirement?.id) {
					const {id} = res.data.createRequirement.requirement;
					const href: string = createHrefFromRequirement({id});
					return navigate(href);
				}

				return res?.data?.createRequirement?.requirement;
			}

			const res = await createRequirementMutation({
				variables: {
					input: createCommonCreationInputFields(editedRequirement, rule),
				},
				refetchQueries,
			});

			return res.data?.createRequirement?.requirement;
		},
		[createRequirementMutation, refetchQueries, rule, requirement],
	);

	return (
		<RequirementsTooltipTranslationProvider>
			<EntityFormPanels
				entity={requirement}
				entityDisplayName={t('Requirements')}
				entityHeaderText={t('RequirementsHeader')}
				createEntity={createRequirement}
				updateEntity={updateRequirement}
				refetchQueries={refetchQueries}
				renderAdditionalFormElements={renderFormElements}
				updateEntityAttachments={updateAttachments}
				panelType={PanelType.medium}
				confirmUpdate
				setIsSubmittingOrRefetching={setIsFormSubmittingOrRefetching}
				{...commonStateForPanels}
			/>
		</RequirementsTooltipTranslationProvider>
	);
};
