import React from 'react';
import {IDropdownOption} from '@fluentui/react';
import {TFunction, useTranslation} from 'react-i18next';
import {
	ControlledDropdown,
	ControlledDropdownProps,
} from './ControlledDropdown';

export type EnumDropdownProps<TEnum> = Omit<
	ControlledDropdownProps,
	'options'
> & {
	enumType: TEnum;
	enumName: string;
	exclude?: Array<string>;
	sortOrder?: string[];
};

export function getDropdownOptionsForEnum<TEnum>(
	t: TFunction,
	enumObj: any,
	exclude?: Array<string>,
	sortOrder?: string[],
): IDropdownOption[] {
	const enumValues: TEnum[] = Object.keys(enumObj)
		.sort(
			(a, b) =>
				(sortOrder ?? []).indexOf(a.toString()) -
				(sortOrder ?? []).indexOf(b.toString()),
		)

		.map(k => enumObj[k])

		.filter(val => !(exclude ?? []).includes(val));

	return enumValues.map((value: TEnum) => {
		const translatedType = t(`${value}`);
		return {
			key: value as unknown as string,
			text: translatedType,
		} as IDropdownOption;
	});
}

export function EnumDropdown<TEnum>({
	enumType,
	enumName,
	exclude,
	sortOrder,
	...props
}: EnumDropdownProps<TEnum>) {
	const {t} = useTranslation('common/enums', {
		keyPrefix: enumName,
	});

	const options = getDropdownOptionsForEnum(t, enumType, exclude, sortOrder);
	return <ControlledDropdown {...props} options={options} />;
}
