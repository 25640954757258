import {
	IScrollablePaneStyles,
	IStackStyles,
	IStackTokens,
	ScrollablePane,
	Stack,
} from '@fluentui/react';
import {AppCommandBar, NotificationBar} from 'components';
import * as React from 'react';
import {Outlet} from 'react-router-dom';
import {Navigation} from './Navigation';
import {SideNavigation} from './SideNavigation';
import {SideNavigationProvider} from './SideNavigation/SideNavigationProvider';

const containerStackTokens: IStackTokens = {childrenGap: 0};

const stackStyles: IStackStyles = {
	root: {
		marginTop: '0',
		overflow: 'hidden',
	},
};

const stackItemStyles: IStackStyles = {
	root: {
		height: '100%',
		position: 'relative',
	},
};

const stackItemStylesMain: IStackStyles = {
	root: {
		width: 'calc(100% - 240px)',
	},
};

const scrollablePaneStylesRoot: IScrollablePaneStyles = {
	root: {
		background: 'rgb(237, 235, 233)',
	},
	stickyAbove: {},
	stickyBelow: {},
	stickyBelowItems: {},
	contentContainer: {},
};

export const DashboardLayout: React.FC = () => {
	return (
		<Stack tokens={containerStackTokens}>
			<Navigation />
			<Stack horizontal styles={stackStyles}>
				<SideNavigationProvider>
					<SideNavigation />
					<Stack styles={stackItemStylesMain} grow={1}>
						<AppCommandBar hideWhenEmpty={true} />
						<NotificationBar timeout={1000} />
						<Stack.Item styles={stackItemStyles}>
							<ScrollablePane styles={scrollablePaneStylesRoot}>
								<Outlet />
							</ScrollablePane>
						</Stack.Item>
					</Stack>
				</SideNavigationProvider>
			</Stack>
		</Stack>
	);
};

export default DashboardLayout;
