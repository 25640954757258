import {
	Persona,
	PersonaSize,
	mergeStyleSets,
	useTheme,
	Text,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import React from 'react';
import _ from 'lodash';

const Persons = ({unique}: any) => {
	return (
		<>
			{unique.slice(0, 5).map((e: any) => (
				<Persona
					aria-label='persona'
					size={PersonaSize.size24}
					key={e}
					text={e}
					hidePersonaDetails={true}
				/>
			))}
		</>
	);
};

export const FaqReplies: any = ({answers}: any) => {
	const theme = useTheme();
	const {t} = useTranslation('common/faq');

	const classNames = mergeStyleSets({
		arrayWrapper: {
			display: 'flex !important',
			flexWrap: 'wrap',
		},
		arrayItem: {
			borderRadius: '10px',
			color: theme?.palette.neutralSecondary,
			background: theme?.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
			whiteSpace: 'nowrap',
		},
		arrayButton: {
			cursor: 'pointer',
		},
	});

	const getUsers = (users: any) => {
		const unique: any[] =
			_.uniq(users.map((e: any) => e.createdBy.name)).slice(0, 6) || [];

		if (unique.length === 1 && users.length === 1) {
			return (
				<>
					<div>
						1 {t('ReplyFrom')} {unique[0]}
						<div style={{display: 'flex', marginTop: '10px', gap: '5px'}}>
							<Persons unique={unique} />
						</div>
					</div>
				</>
			);
		}
		if (unique.length >= 6) {
			const text = `${users.length} ${t('RepliesFrom')} ${unique
				.slice(0, 5)
				.map(e => ` ${e}`)} ${t('AndOthers')}`;
			return (
				<>
					<div>
						{text}
						<div style={{display: 'flex', marginTop: '10px', gap: '5px'}}>
							<Persons unique={unique} />
							<Text
								className={`${classNames.arrayItem} ${classNames.arrayButton}`}
							>{`+ ${unique.length - 4}`}</Text>
						</div>
					</div>
				</>
			);
		}

		const last = unique[unique.length - 1];
		const uniqueList = unique.slice(0, unique.length - 1).map(e => ` ${e}`);

		return (
			<div>
				{users.length} {t('RepliesFrom')}
				{uniqueList} {uniqueList.length > 0 && t('RepliesFrom')} {last}
				<div style={{display: 'flex', marginTop: '10px', gap: '5px'}}>
					<Persons unique={unique} />
				</div>
			</div>
		);
	};
	return getUsers(answers);
};
