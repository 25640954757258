import {
	Checkbox,
	CheckboxVisibility,
	CommandButton,
	ConstrainMode,
	ContextualMenuItemType,
	DefaultButton,
	DetailsList,
	ICheckboxStyles,
	IColumn,
	IDetailsHeaderProps,
	IDetailsListProps,
	IDetailsListStyles,
	IGroup,
	IRenderFunction,
	ISelection,
	IStackItemStyles,
	MarqueeSelection,
	Panel,
	PanelType,
	SelectionMode,
	Separator,
	Stack,
	TextField,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {
	LocalizedDatePicker,
	LocalizedDatePickerProps,
} from 'components/hookForms';
import {isAfter, isValid, isWithinInterval, max, min, parseISO} from 'date-fns';
import {groupBy, mapValues} from 'helpers';
import {useCommand, useNotificationBar} from 'hooks';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EmptyResults} from './EmptyResults';
import {useUserContext} from 'authentication/UserContext';
import {useSetTableConfigurationMutation} from './hooks/useSetTableConfigurationMutation.generated';
import {Column} from 'types';
import {MyTableConfigurationsDocument} from 'authentication/hooks/myTableConfigurations.generated';
import {HideColumnsPanel} from './HideColumnsPanel';
import {
	ProviderThatEnablesGettingTooltipsFromContext,
	ColumnWithTooltipOptions,
} from 'features/localizedTooltips';
import {getRendererForHeaderWithTooltips} from '../../features/localizedTooltips/componentsWithTooltips/HeaderWithTooltipsAndStickySupport';
import _ from 'lodash';
import {DEFAULT_DATE, isReportsTable, isValueDateField} from './EntityUtils';

import {useEntityListContext} from './EntityListContext';
import {ExcelExportButton} from './Excel/ExcelExportButton';
import {excelButtonHide} from './Excel/ExcelExportCommon.react';
import {useStore} from 'react-context-hook';
import {FaqPanel} from '../Faq/FaqPanel';
import {v4 as uuidv4} from 'uuid';
import {useEntityContext} from 'components/EntityPage/EntityContext';

export type EntityListColumn = ColumnWithTooltipOptions & {
	sortable?: boolean;
	filterable?: boolean;
	primitiveFilter?: boolean;
	hidden?: boolean;
	getFilterLabel?: (item: any) => string;
	getFilterKey?: (item: any) => string | number;
	getSortKey?: (item: any) => string | number;
	getFieldValue?: (item: any) => any;
	tableId?: string;
	filterOnFilter?: boolean;
	dataType?: string;
};

export type EntityListProps = Omit<IDetailsListProps, 'columns'> & {
	columns: EntityListColumn[];
	tableId?: string;
	fixedHeight?: string | number;
	listId?: string;
	sticky?: boolean;
	groupByFieldName?: string;
};

type FilterItemProps = {
	filterOptions: (
		col: EntityListColumn,
		colValues: any[],
		strRe?: string,
	) => JSX.Element;
	col: EntityListColumn;
	colValues: any[];
};

type EntityListFilter = {
	key: string;
	columnKey: string;
	filterFn: (row: any) => boolean;
};

type DateRange = {
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
};

const DateRangePicker: React.FC<{
	onRangeChange?: (range: DateRange) => void;
	defaultDateFrom?: Date;
	defaultDateTo?: Date;
	dateFromProps?: LocalizedDatePickerProps;
	dateToProps?: LocalizedDatePickerProps;
}> = ({
	onRangeChange,
	defaultDateFrom,
	defaultDateTo,
	dateFromProps,
	dateToProps,
}) => {
	const [dateFrom, setDateFrom] = React.useState<Date | undefined>(
		defaultDateFrom,
	);
	const [dateTo, setDateTo] = React.useState<Date | undefined>(defaultDateTo);
	const [range, setRange] = React.useState({dateFrom, dateTo});

	const onDateFromChange = React.useCallback(
		(date: Date | null | undefined) => {
			if (date) {
				setDateFrom(date);
				const newRange = {...range, dateFrom: date};
				setRange(newRange);
				if (onRangeChange) {
					onRangeChange(newRange);
				}
			}
		},
		[dateFrom, range, onRangeChange],
	);
	const onDateToChange = React.useCallback(
		(date: Date | null | undefined) => {
			if (date) {
				setDateTo(date);
				const newRange = {...range, dateTo: date};
				setRange(newRange);
				if (onRangeChange) {
					onRangeChange(newRange);
				}
			}
		},
		[dateTo, range, onRangeChange],
	);

	return (
		<Stack horizontal tokens={{childrenGap: 4}}>
			<LocalizedDatePicker
				{...dateFromProps}
				label='From'
				onSelectDate={onDateFromChange}
				value={dateFrom}
				maxDate={dateTo}
			/>
			<LocalizedDatePicker
				{...dateToProps}
				label='To'
				onSelectDate={onDateToChange}
				value={dateTo}
				minDate={dateFrom}
			/>
		</Stack>
	);
};

const FilterItem: React.FC<FilterItemProps> = ({
	filterOptions,
	col,
	colValues,
}) => {
	const [isExpanded, {toggle: toggleExpanded}] = useBoolean(false);
	const [strFilterOptionsFilter, setsFilterOptionsFilter] =
		useState<string>('');

	const iconProps = React.useMemo(
		() => ({iconName: isExpanded ? 'ChevronUp' : 'ChevronDown'}),
		[isExpanded],
	);
	const {name, filterOnFilter} = col;

	const onChevronClick = React.useCallback(() => toggleExpanded(), []);
	const filterOptionsStyles: IStackItemStyles = React.useMemo(
		() => ({
			root: {
				display: isExpanded ? 'block' : 'none',
			},
		}),
		[isExpanded],
	);

	return (
		<Stack tokens={{childrenGap: 8}}>
			<CommandButton
				iconProps={iconProps}
				text={name}
				onClick={onChevronClick}
				ariaLabel={`Toggle filter ${name}`}
			/>
			<Stack.Item styles={filterOptionsStyles}>
				<>
					{filterOnFilter && (
						<>
							<TextField
								underlined
								onChange={_event => {
									setsFilterOptionsFilter(
										_event.currentTarget.value.trim().toLowerCase(),
									);
								}}
							/>
							<br />
						</>
					)}
					{filterOptions(col, colValues, strFilterOptionsFilter)}
				</>
			</Stack.Item>
		</Stack>
	);
};

export const EntityListContext = React.createContext<{
	currentColumnConfig: Column[];
	setCurrentColumnConfig: React.Dispatch<React.SetStateAction<Column[]>>;
}>({} as any);

/**
 * Note: Tooltips are enabled by default, so you must wrap this component in a
 * tooltips translation provider and provide tooltips for all columns. See
 * TooltipTranslationContext for more info.
 *
 * You can also disable the tooltip using the column's tooltip options. See the
 * column's type definition for more info.
 */
export const EntityList: React.FC<EntityListProps> = props => {
	const {t} = useTranslation('components/entitylist');
	const [sortedItems, setSortedItems] = React.useState<any[]>(props.items);

	const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] =
		useBoolean(false);

	const {setMessage} = useNotificationBar();
	const {isAdmin, isVko, isVex, isReader, myVexClusters, tableConfigurations} =
		useUserContext();
	const {pageDetails} = useEntityListContext();
	const columnConfigs = React.useMemo(
		() =>
			tableConfigurations?.find(tc => tc.tableId === props.tableId)?.columns,
		[tableConfigurations],
	);
	const [setTableConfiguration] = useSetTableConfigurationMutation();
	const [currentColumnConfig, setCurrentColumnConfig] = React.useState<
		Column[]
	>([]);

	const [hideColumnsPanelOpen, setHideColumnsPanelOpen] = React.useState(false);
	const onHideColumnsPanelOpen = React.useCallback(() => {
		setHideColumnsPanelOpen(true);
	}, []);
	const onHideColumnsPanelDismiss = React.useCallback(() => {
		setHideColumnsPanelOpen(false);
	}, []);

	const {setSelectedItems} = useEntityContext<any>();
	React.useEffect(() => {
		if (props?.selection && setSelectedItems) {
			setSelectedItems((props?.selection as any)?._selectedItems || []);
		}
	}, [(props?.selection as any)?._selectedItems, setSelectedItems]);

	React.useEffect(() => {
		let newColumnConfig: Column[] = [];
		if (columnConfigs && columnConfigs.length > 0) {
			newColumnConfig = columnConfigs.map(c => ({
				hidden: c.hidden,
				key: c.key,
				width: c.width,
			}));
		} else {
			newColumnConfig = props.columns.map(c => ({
				hidden: false,
				width: c.minWidth,
				key: c.key,
			}));
		}

		setCurrentColumnConfig(newColumnConfig);
		setColumns(cols =>
			cols.map(c => {
				const config = newColumnConfig.find(cfg => cfg.key === c.key);

				if (!config) {
					return {...c};
				}

				return {
					...c,
					hidden: config.hidden,
					minWidth: config.width,
				};
			}),
		);
	}, [columnConfigs]);

	const onColumnResize = React.useCallback(
		(col?: IColumn, newWidth?: number) => {
			if (!(col && newWidth)) {
				return;
			}

			setCurrentColumnConfig(cfg => {
				const newConfig = cfg.slice();
				const i = newConfig.findIndex(c => c.key === col?.key);
				const changedCol = newConfig[i];
				if (i !== -1) {
					newConfig[i] = {
						...changedCol,
						width: newWidth,
					};
				}

				return newConfig;
			});
		},
		[],
	);

	const saveColumnConfiguration = React.useCallback(
		async (config: Column[]) => {
			if (props.tableId) {
				await setTableConfiguration({
					variables: {
						input: {
							columns: config,
							tableId: props.tableId,
						},
					},
					refetchQueries: [MyTableConfigurationsDocument],
				}).then(() => {
					onHideColumnsPanelDismiss();
					setMessage(t('UpdatedTableConfiguration'));
				});
			}
		},
		[props.tableId],
	);

	const onColumnConfigSaveClick = React.useCallback(async () => {
		await saveColumnConfiguration(currentColumnConfig);
	}, [saveColumnConfiguration, currentColumnConfig]);

	const resetColumnConfiguration = React.useCallback(async () => {
		if (props.tableId) {
			await setTableConfiguration({
				variables: {
					input: {
						columns: props.columns.map(c => ({
							hidden: Boolean(c.hidden),
							key: c.key,
							width: c.minWidth,
						})),
						tableId: props.tableId,
					},
				},
				refetchQueries: [MyTableConfigurationsDocument],
			}).then(() => {
				setMessage(t('ResetTableConfiguration'));
			});
		}
	}, [props.columns]);

	const onColumnConfigResetClick = React.useCallback(async () => {
		await resetColumnConfiguration();
	}, [resetColumnConfiguration]);

	function _copyAndSort<T>(
		items: T[],
		column?: EntityListColumn,
		isSortedDescending?: boolean,
	): T[] {
		if (!column) {
			return items.slice(0);
		}

		const convertArrayEntity = (val: any) => {
			if (Array.isArray(val)) {
				switch (val[0]?.__typename) {
					case 'VexCluster':
					case 'User':
					case 'RegulationCluster':
						return val[0].name;
					default:
						return val[0] || '';
				}
			}

			return val;
		};

		const key = column.fieldName || '';
		const {getSortKey} = column;
		return items.slice(0).sort((a: T, b: T) => {
			let aVal: any = a[key as keyof T];
			let bVal: any = b[key as keyof T];
			aVal = convertArrayEntity(aVal);
			bVal = convertArrayEntity(bVal);
			aVal = getSortKey ? getSortKey(aVal) : aVal;
			bVal = getSortKey ? getSortKey(bVal) : bVal;
			aVal = typeof aVal === 'string' ? aVal.toLowerCase() : aVal;
			bVal = typeof bVal === 'string' ? bVal.toLowerCase() : bVal;

			const toggleKey = isSortedDescending ? aVal < bVal : aVal > bVal;
			return toggleKey ? 1 : -1;
		});
	}

	const onColumnClick = (
		_event: React.MouseEvent<HTMLElement>,
		column: IColumn,
	): void => {
		let {isSortedDescending} = column;

		// If we've sorted this column, flip it.
		if (column.isSorted) {
			isSortedDescending = !isSortedDescending;
		}

		setSortedItems(_copyAndSort(sortedItems, column, isSortedDescending));
		setColumns(
			baseColumns.map(col => {
				col.isSorted = col.key === column.key;

				if (col.isSorted) {
					col.isSortedDescending = isSortedDescending;
				}

				return col;
			}),
		);
	};

	const [baseColumns, setColumns] = React.useState<Array<EntityListColumn>>(
		props.columns.reduce((cols, column) => {
			const columnCopy = {...column};

			return cols.concat(
				column.sortable
					? {
							...columnCopy,
							sortAscendingAriaLabel: 'Sorted A to Z',
							sortDescendingAriaLabel: 'Sorted Z to A',
							filterAriaLabel: 'Filter active',
							showSortIconWhenUnsorted: true,
							onColumnClick,
					  }
					: columnCopy,
			);
		}, [] as Array<EntityListColumn>),
	);

	const filterColumns = (): EntityListColumn[] => {
		return baseColumns.filter(c => !c.hidden);
	};

	const columns: EntityListColumn[] = React.useMemo(filterColumns, [
		baseColumns,
	]);

	const setInitialSortedItems = useCallback((): void => {
		const getInitialSortedItems = (): EntityListProps['items'] => {
			type Match = EntityListColumn | undefined;
			const sortedColumn: Match = _.find(columns, {isSorted: true});
			return _copyAndSort(
				props.items,
				sortedColumn,
				sortedColumn?.isSortedDescending,
			);
		};

		const newSortedItems: EntityListProps['items'] = getInitialSortedItems();
		setSortedItems(newSortedItems);
	}, [props.items, columns]);

	/**
	 * We only trigger this hook when the list of items changes and not when the
	 * function changes because it has "columns" as a dependency. If a component
	 * forgot to memoize the columns, then it would cause this function to fire
	 * unnecessarily, which would cause UX problems.
	 */
	React.useEffect(setInitialSortedItems, [props.items]);

	useCommand(
		{
			key: 'tableConfigurations',
			iconProps: {
				iconName: 'More',
			},
			farCommand: true,
			hidden: !props.tableId,
			subMenuProps: {
				items: [
					{
						key: 'configureHiddenColumns',
						iconProps: {iconName: 'ColumnOptions'},
						onClick: onHideColumnsPanelOpen,
						text: t('OpenHiddenColmnsConfig'),
					},
					{
						key: 'saveTableConfiguration',
						iconProps: {iconName: 'Save'},
						onClick: onColumnConfigSaveClick,
						text: t('SaveTableConfiguration'),
					},
					{
						key: 'divider',
						itemType: ContextualMenuItemType.Divider,
					},
					{
						key: 'resetTableConfiguration',
						iconProps: {
							iconName: 'Cancel',
						},
						onClick: onColumnConfigResetClick,
						text: t('ResetTableConfiguration'),
					},
				],
			},
			priority: 100,
		},
		[props.tableId, onColumnConfigSaveClick, onColumnConfigResetClick],
	);

	useCommand(
		{
			key: props.listId || 'filter',
			priority: 1,
			iconProps: {
				iconName: 'Filter',
			},
			farCommand: true,
			onClick: openPanel,
			ariaLabel: 'Filter',
			title: 'Filter',
			hidden: !baseColumns?.some(col => col.filterable),
		},
		[baseColumns],
	);

	const [filters, setFilters] = React.useState<EntityListFilter[]>([]);

	useCommand(
		{
			key: 'clearFilter',
			farCommand: true,
			hidden: filters.length === 0,
			iconProps: {
				iconName: 'ClearFilter',
			},
			onClick: () => handleClearFilterClick(),
		},
		[filters],
	);

	const handleFilterChange = React.useCallback(
		(newFilters: EntityListFilter[]) => {
			setFilters(newFilters);
			setColumns(
				baseColumns.map(col => ({
					...col,
					isFiltered: newFilters?.some(f => f.columnKey === col.key),
				})),
			);
		},
		[],
	);

	const getCheckboxChangeCallback = React.useCallback(
		(
				col: EntityListColumn,
				getFilterFn: (match: any) => (item: any) => boolean,
			) =>
			(checked: boolean, match: any) => {
				let newFilters: EntityListFilter[] = [];
				if (checked) {
					const filter = {
						key: match,
						columnKey: col.key,
						filterFn: getFilterFn(match),
					};
					newFilters = [...filters, filter];
				} else {
					newFilters = filters.filter(f => f.key !== match);
				}

				handleFilterChange(newFilters);
			},
		[filters],
	);

	const renderArrayFieldOptions = React.useCallback(
		(col: EntityListColumn, colValues: any[], strRe?: string) => {
			const flatValues = colValues.flat();
			const reFilter = new RegExp(strRe ?? '');
			const {getFilterKey} = col;
			const getKey = getFilterKey ?? ((item: any) => item.id);

			const uniqueValues = [
				...new Map(flatValues.map(item => [getKey(item), item])).values(),
			].filter((val, _) => {
				if (typeof val === 'string') {
					return reFilter.test((val as string).toLowerCase());
				}
				if (typeof val.name === 'string') {
					return reFilter.test(val.name.toLowerCase());
				}
				return true;
			});

			const getFilterFn = (match: any) => (item: any) =>
				item[col.fieldName ?? '']?.some((val: any) => getKey(val) === match);
			const getBlankFilterFn = (_: any) => (item: any) =>
				item[col.fieldName ?? ''].length === 0;
			const onCheckboxChange = getCheckboxChangeCallback(col, getFilterFn);
			const onBlankCheckboxChange = getCheckboxChangeCallback(
				col,
				getBlankFilterFn,
			);
			const getLabel = col.getFilterLabel ?? ((val: any) => val.name);
			const sortedValues = uniqueValues.sort((v1, v2) =>
				getLabel(v1).localeCompare(getLabel(v2)),
			);

			return (
				<Stack tokens={{childrenGap: 4}}>
					<Checkbox
						label={`(${t('Blank')})`}
						styles={checkboxStyles}
						onChange={(_, checked) =>
							onBlankCheckboxChange(Boolean(checked), '')
						}
					/>
					{sortedValues.map((val, j) => (
						<Checkbox
							key={j.toString() + strRe ?? ''}
							label={getLabel(val)}
							styles={checkboxStyles}
							onChange={(_, checked) =>
								onCheckboxChange(Boolean(checked), getKey(val))
							}
						/>
					))}
				</Stack>
			);
		},
		[filters, t],
	);

	const renderPrimitiveFieldOptions = React.useCallback(
		(col: EntityListColumn, colValues: any[], strRe?: string) => {
			const reFilter = new RegExp(strRe ?? '');
			const getLabel = col.getFilterLabel || ((val: any) => val);
			const {getFilterKey} = col;
			const sortedValues = [...new Set(colValues)]
				.filter((x: string) =>
					typeof x === 'string' ? reFilter.test(x.toLowerCase()) : true,
				)
				.sort((v1, v2) =>
					getFilterKey
						? `${getFilterKey(v1)}`.localeCompare(`${getFilterKey(v2)}`)
						: `${getLabel(v1)}`.localeCompare(`${getLabel(v2)}`),
				);

			return renderCheckboxOptions(
				col,
				sortedValues,
				(val: any) => getFieldMatchFn(col, val),
				getLabel,
				strRe,
			);
		},
		[filters],
	);

	function renderCheckboxOptions(
		col: EntityListColumn,
		sortedValues: any[],
		getFilterFn: (val: any) => (item: any) => boolean,
		getLabelFn: (val: any) => string = val => val,
		strRe?: string,
	) {
		const onCheckboxChange = getCheckboxChangeCallback(col, getFilterFn);

		return (
			<Stack tokens={{childrenGap: 4}}>
				{sortedValues.map((val, j) => (
					<Checkbox
						key={j.toString() + strRe ?? ''}
						label={getLabelFn(val)}
						styles={checkboxStyles}
						onChange={(_, checked) => onCheckboxChange(Boolean(checked), val)}
					/>
				))}
			</Stack>
		);
	}

	function getFieldMatchFn(col: EntityListColumn, val: any) {
		const {fieldName, getFieldValue} = col;

		if (getFieldValue) {
			return (item: any) => getFieldValue(item) === val;
		}

		return (item: any) => item[fieldName || ''] === val;
	}

	const renderObjectFieldOptions = React.useCallback(
		(col: EntityListColumn, colValues: any[], strRe?: string) => {
			const reFilter = new RegExp(strRe ?? '');
			const uniqueValues = [
				...new Map(colValues.map(item => [item.id, item])).values(),
			].filter(x => (x.name ? reFilter.test(x.name.toLowerCase()) : true));
			const getFilterFn = (match: any) => (item: any) =>
				item[col.fieldName || ''].id === match;
			const onCheckboxChange = getCheckboxChangeCallback(col, getFilterFn);
			const getLabel = col.getFilterLabel || ((val: any) => val.name);

			const sortedValues = uniqueValues.sort((v1, v2) =>
				getLabel(v1).localeCompare(getLabel(v2)),
			);

			return (
				<Stack tokens={{childrenGap: 4}}>
					{sortedValues.map((val, j) => (
						<Checkbox
							key={j.toString() + strRe ?? ''}
							label={getLabel(val)}
							styles={checkboxStyles}
							onChange={(_, checked) =>
								onCheckboxChange(Boolean(checked), val.id)
							}
						/>
					))}
				</Stack>
			);
		},
		[filters],
	);

	const renderDateFieldOptions = React.useCallback(
		(col: EntityListColumn, colValues: any[]) => {
			const colValuesDateObjects = colValues
				.filter(date => date !== DEFAULT_DATE)
				.map(val => parseISO(val));
			if (colValuesDateObjects.length === 0) {
				colValuesDateObjects.push(new Date());
			}

			const earliestDate = min(colValuesDateObjects);
			const latestDate = max(colValuesDateObjects);

			const onRangeChange = (range: DateRange) => {
				const filterFn = (item: any) => {
					if (range.dateFrom && range.dateTo) {
						return isWithinInterval(parseISO(item[col.fieldName || '']), {
							start: range.dateFrom,
							end: range.dateTo,
						});
					}

					if (range.dateFrom) {
						return isAfter(parseISO(item[col.fieldName || '']), range.dateFrom);
					}

					if (range.dateTo) {
						return isAfter(parseISO(item[col.fieldName || '']), range.dateTo);
					}

					return true;
				};

				const {key} = col;
				const newFilters = [
					...filters.filter(f => f.key !== key),
					{
						key,
						columnKey: key,
						filterFn,
					},
				];

				handleFilterChange(newFilters);
			};

			return (
				<DateRangePicker
					defaultDateFrom={earliestDate}
					defaultDateTo={latestDate}
					onRangeChange={onRangeChange}
				/>
			);
		},
		[filters],
	);

	const filterContent = React.useMemo(
		() =>
			props.columns.map((col, i) => {
				let colValues: any[] = [];

				const {filterable, fieldName, getFieldValue, primitiveFilter} = col;

				if (!filterable) {
					return <div key={i}></div>;
				}

				if (getFieldValue) {
					colValues = props.items
						.map(item => getFieldValue(item))
						.filter(item => item !== null && item !== undefined);
				} else if (fieldName) {
					colValues = props.items
						.map(item => item[fieldName ?? ''])
						.filter(item => item !== null && item !== undefined);
				} else {
					return <div key={i}></div>;
				}

				if (!colValues.length) {
					return <div key={i}></div>;
				}
				const firstElement = colValues[0];

				const isArrayField = Array.isArray(firstElement);

				const isDateField = isValueDateField(col.dataType || '');
				const isObjectField = !isArrayField && typeof firstElement === 'object';
				const isPrimitiveField =
					!isArrayField && !isObjectField && !isDateField;

				let filterOptions: (
					col: EntityListColumn,
					colValues: any[],
					strRe?: string,
				) => JSX.Element = () => <></>;

				if (isArrayField) {
					if (isReportsTable(props.tableId)) {
						colValues = colValues.flat();
						filterOptions = renderPrimitiveFieldOptions;
					} else {
						filterOptions = renderArrayFieldOptions;
					}
				}

				if (isDateField) {
					filterOptions = renderDateFieldOptions;

					if (primitiveFilter) {
						filterOptions = renderPrimitiveFieldOptions;
					}
				}

				if (isPrimitiveField) {
					filterOptions = renderPrimitiveFieldOptions;
				}

				if (isObjectField) {
					filterOptions = renderObjectFieldOptions;
				}

				return (
					<div key={i}>
						{i !== 0 && <Separator />}
						<FilterItem
							filterOptions={filterOptions}
							col={col}
							colValues={colValues}
						/>
					</div>
				);
			}),
		[baseColumns, filters, sortedItems],
	);

	const sortedFilteredItems = React.useMemo(() => {
		if (!filters.length) {
			return sortedItems;
		}

		return sortedItems.filter(item => {
			const filterableColumns = baseColumns.filter(col => col.filterable);
			const filterRes: boolean[] = filterableColumns.map(col => {
				const colFilters = filters.filter(f => f.columnKey === col.key);

				if (!colFilters.length) {
					return true;
				}

				for (const filter of colFilters) {
					if (
						isReportsTable(props.tableId) &&
						Array.isArray(item[filter.columnKey])
					) {
						if (
							item[filter.columnKey].findIndex((e: any) => e === filter.key) >
							-1
						) {
							return true;
						}
					} else if (filter.filterFn(item)) {
						return true;
					}
				}

				return false;
			});

			return filterRes.reduce((res, curr) => res && curr, true);
		});
	}, [sortedItems, filters]);

	const handleClearFilterClick = React.useCallback(() => {
		handleFilterChange([]);
		dismissPanel();
	}, []);

	const onRenderFooterContent = React.useCallback(
		() => (
			<DefaultButton
				text={t('ClearFilters')}
				onClick={handleClearFilterClick}
			/>
		),
		[],
	);

	const {groups, finalItems} = React.useMemo(() => {
		let finalItems = sortedFilteredItems;

		if (props.groupByFieldName) {
			const groups: IGroup[] = [];

			const itemsByGroup = mapValues(
				groupBy(sortedFilteredItems, props.groupByFieldName),
				y => y.slice(0),
			);

			finalItems = [];

			for (const [groupKey] of Object.entries(itemsByGroup)) {
				const groupItems = itemsByGroup[groupKey];

				groups.push({
					key: groupKey,
					name: groupKey,
					isCollapsed: false,
					startIndex: finalItems.length,
					count: groupItems.length,
				});

				finalItems = [...finalItems, ...(groupItems as any[])];
			}

			return {groups, finalItems};
		}

		return {groups: undefined, finalItems};
	}, [sortedFilteredItems]);

	const entityContextValue = React.useMemo(
		() => ({
			currentColumnConfig,
			setCurrentColumnConfig,
		}),
		[currentColumnConfig, setCurrentColumnConfig],
	);

	const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> =
		getRendererForHeaderWithTooltips(props.sticky);

	const boolExcelButtonHide = useMemo(
		() => excelButtonHide(finalItems),
		[finalItems],
	);

	useCommand(
		{
			key: 'excelExport_' + new Date().toISOString(),
			farCommand: true,
			priority: 0,
			hidden: boolExcelButtonHide,
			commandBarButtonAs(): JSX.Element {
				return (
					<ExcelExportButton
						entityListItems={finalItems}
						arrRoles={{isAdmin, isVko, isVex, isReader}}
						myVexClusters={myVexClusters}
						pageDetails={pageDetails}
					/>
				);
			},
		},
		[filters, finalItems, myVexClusters, pageDetails],
	);
	return (
		<EntityListContext.Provider value={entityContextValue}>
			<MarqueeSelection
				selection={props.selection as ISelection}
				isDraggingConstrainedToRoot={true}
				isEnabled={props.selectionMode === SelectionMode.multiple}
			>
				<ProviderThatEnablesGettingTooltipsFromContext>
					<DetailsList
						{...props}
						checkboxVisibility={
							props.checkboxVisibility ??
							props.selectionMode === SelectionMode.multiple
								? CheckboxVisibility.always
								: CheckboxVisibility.onHover
						}
						styles={
							props.fixedHeight
								? getFixedHeightListStyles(props.fixedHeight)
								: props.styles
						}
						items={finalItems}
						groups={groups}
						columns={columns}
						onRenderDetailsHeader={onRenderDetailsHeader}
						selectionPreservedOnEmptyClick={true}
						constrainMode={props.constrainMode || ConstrainMode.unconstrained}
						onColumnResize={onColumnResize}
					/>
				</ProviderThatEnablesGettingTooltipsFromContext>
				{sortedFilteredItems.length === 0 && <EmptyResults />}
			</MarqueeSelection>
			<Panel
				type={PanelType.medium}
				isLightDismiss
				isOpen={isOpen}
				onDismiss={dismissPanel}
				isHiddenOnDismiss={filters.length !== 0}
				closeButtonAriaLabel='Close'
				headerText={t('Filters')}
				onRenderFooterContent={onRenderFooterContent}
				isFooterAtBottom={true}
			>
				{filterContent}
			</Panel>
			<FaqPanel />
			<HideColumnsPanel
				isOpen={hideColumnsPanelOpen}
				columns={props.columns}
				onDismiss={onHideColumnsPanelDismiss}
				onSubmit={saveColumnConfiguration}
			/>
		</EntityListContext.Provider>
	);
};

const checkboxStyles: ICheckboxStyles = {
	root: {paddingLeft: '5px'},
};

const getFixedHeightListStyles: (
	height: string | number,
) => Partial<IDetailsListStyles> = height => ({
	root: {
		overflowX: 'scroll',
		selectors: {
			'& [role=grid]': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'start',
				height,
			},
		},
	},
	headerWrapper: {
		flex: '0 0 auto',
	},
	contentWrapper: {
		flex: '1 1 auto',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
});

export const ROW_CLASS = 'ms-DetailsRow';
export const ROW_SELECTOR = `.${ROW_CLASS}`;
export const HEIGHT_OF_LIST_HEADER = 60;
