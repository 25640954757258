import {convertToRef, mapToRef} from 'helpers';
import {
	CreateRequirementInput,
	Requirement,
	RequirementStatus,
	UpdateRequirementInput,
} from 'types';
import {CompatibleFormFields} from './components/CommonFormElementsOfRequirements';
import {GetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {IIdentifiable} from 'components/EntityPage/EntityFormPanels';

/*
 * Inputs
 */
type WithoutId<Info> = Omit<Info, 'id'>;

export type CreateRequirementInputWithoutId = WithoutId<CreateRequirementInput>;

type UpdateRequirementInputWithoutId = WithoutId<UpdateRequirementInput>;

type InputsWithoutIds =
	| UpdateRequirementInputWithoutId
	| CreateRequirementInputWithoutId;

/*
 * Other
 */

/**
 * Implementation notes:
 *
 * We use the type for @see CompatibleFormFields here because we must create a
 * type that represents the fields that the requirement forms have in common.
 * @see CompatibleFormFields is the closest type they have in common because
 * they all use the component associated with that type.
 */
type RequirementFields = CompatibleFormFields &
	Pick<Requirement, 'name' | 'requirementId'>;

type RequirementFieldsThatStayTheSame = 'status';

export type CommonRequirementInputFields = Omit<
	InputsWithoutIds,
	RequirementFieldsThatStayTheSame
> &
	Pick<RequirementFields, RequirementFieldsThatStayTheSame>;

type PossibleRule = GetGdprRuleQuery['gdprRule'];

export class RequirementUtilsService {
	public getCommonInputFields(
		requirement: RequirementFields,
	): CommonRequirementInputFields {
		const documentReferences =
			requirement.documentReferences?.map((r: any) => ({
				referenceDocumentType: r.referenceDocumentType,
				requirementReferences: r.requirementReferences?.map((reqRef: any) => ({
					id: reqRef.id,
				})),
				reference: {id: r.reference.id},
			})) || [];

		return {
			name: requirement.name,
			status: RequirementStatus.Draft,
			category: requirement.category,
			vexClusterRefs: mapToRef(requirement?.vexClusters),
			systemLevelRefs: mapToRef(requirement.systemLevels),
			driveVariantRefs: mapToRef(requirement.driveVariants),
			gearboxVariantRefs: mapToRef(requirement.gearboxVariants),
			bodyworkVariantRefs: mapToRef(requirement.bodyworkVariants),
			engineVariantRefs: mapToRef(requirement.engineVariants),
			activeTypeMarketRefs: mapToRef(requirement.activeTypeMarkets),
			vehicleCategoryRefs: mapToRef(requirement.vehicleCategories),
			tagRefs: mapToRef(requirement.tags),
			/**
			 * This field isn't necessary for creation forms, but it won't have
			 * unforeseen effects even if we include it because its value will be
			 * undefined, so it will be discarded.
			 */
			requirementId: requirement.requirementId,
			definition: requirement.definition,
			documentReferences,
		};
	}

	public createCommonCreationInputFields = (
		requirement: RequirementFields,
		rule: PossibleRule,
	): CreateRequirementInputWithoutId => {
		const commonFields: CommonRequirementInputFields =
			this.getCommonInputFields(requirement);

		const fields: any = {
			...commonFields,
			deletionPeriod: rule?.deletionPeriod ?? 0,
			willBeAnonymized: rule?.anonymize ?? false,
		};

		return fields;
	};

	public createCloneCreationInputFields = (
		requirement: RequirementFields,
		rule: PossibleRule,
		version: string,
		status: RequirementStatus,
	): CreateRequirementInputWithoutId => {
		const fields = this.createCommonCreationInputFields(requirement, rule);

		fields.parentRef = convertToRef(requirement as any);
		const {markets, keywords} = requirement as any;
		if (markets?.length > 0) {
			fields.marketRefs = mapToRef(markets);
		}

		if (keywords?.length > 0) {
			fields.keywordRefs = mapToRef(keywords);
		}

		fields.version = version;
		fields.status = status;
		return fields;
	};

	public isRequirementFinalEntity = (
		entity: IIdentifiable | undefined | null | void,
	): boolean => {
		return (
			(entity as any)?.__typename === 'Requirement' &&
			(entity as any)?.status === 'FINAL'
		);
	};

	public isRequirementDraftEntity = (
		entity: IIdentifiable | undefined | null | void,
	): boolean => {
		return (
			(entity as any)?.__typename === 'Requirement' &&
			(entity as any)?.status === 'DRAFT'
		);
	};
}
