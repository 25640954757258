import {mergeStyleSets, Theme, useTheme, Text, Stack} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityList} from '../../components/ActivityList';
import {FavoritesList} from './components/FavoritesList';
import {VkoParagraphsWorkbenchItems} from './components/VkoParagraphsWorkbenchItems';
import {WorkbenchList} from './components/WorkbenchList';
import {NewsItemsList} from './components';
import {useGetMyKeywordsLazyQuery} from 'authentication/hooks/myKeywords.generated';
import {v4 as uuidv4} from 'uuid';
import {LoadWrapper} from 'components/LoadWrapper';
import {useVkoKeywordsLazyQuery} from './hooks/vkoKeywords.generated';

const DashboardPage = () => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});
	const theme = useTheme();
	const classNames = dashboardClassNames(theme);
	const {isVex, isVko, isAdmin, isVkoExternal, isServiceProvider, isReader} =
		useUserContext();

	const [getVexKeywords, {loading: loadingVexKeywords, data: vexKeywords}] =
		useGetMyKeywordsLazyQuery();

	const [getVkoKeywords, {loading: loadingVkoKeywords, data: vkoKeywords}] =
		useVkoKeywordsLazyQuery();

	React.useEffect(() => {
		if (isVex) {
			getVexKeywords().catch(error => {
				console.error('Error fetching Vex keywords:', error);
			});
		} else if (isVko) {
			getVkoKeywords().catch(error => {
				console.error('Error fetching Vko keywords:', error);
			});
		}
	}, [isVex, isVko, getVexKeywords, getVkoKeywords]);

	const renderKeywords = () => {
		if (isVex) {
			return vexKeywords?.myKeywords?.map(k => (
				<Text key={uuidv4()} className={classNames.keyword}>
					{k.name}
				</Text>
			));
		}

		if (isVko) {
			return vkoKeywords?.myVKOKeywords?.map(k => (
				<Text key={uuidv4()} className={classNames.keyword}>
					{k.name}
				</Text>
			));
		}

		return <></>;
	};

	const renderKeywordHeader = () => {
		if (isVex) {
			return <Text variant='large'>{t('AssignedKeywords')}</Text>;
		}

		if (isVko) {
			return <Text variant='large'>{t('AssignedMainKeywords')}</Text>;
		}

		return undefined;
	};

	return (
		<div style={{background: theme.palette.neutralLight}}>
			{(isVex || isVko) && (
				<Stack className={classNames.activityContainer}>
					<Stack horizontal horizontalAlign='start'>
						<Stack
							grow={1}
							styles={{
								root: {
									padding: 10,
								},
							}}
						>
							<Stack.Item>{renderKeywordHeader()}</Stack.Item>
							<Stack.Item
								styles={{
									root: {
										paddingTop: 10,
									},
								}}
							>
								<LoadWrapper loading={loadingVexKeywords || loadingVkoKeywords}>
									<div className={classNames.keywordWrapper}>
										{renderKeywords()}
									</div>
								</LoadWrapper>
							</Stack.Item>
						</Stack>
					</Stack>
				</Stack>
			)}

			<Stack horizontal horizontalAlign='start'>
				{!(isAdmin || isReader) && (
					<Stack styles={stackItemStyles} grow={1}>
						{isVex && (
							<Stack.Item grow={1}>
								<WorkbenchList halfHeight />
							</Stack.Item>
						)}
						<Stack.Item grow={1}>
							<WorkbenchList halfHeight inProcess={true} />
						</Stack.Item>
						{isVko && (
							<Stack.Item grow={1}>
								<VkoParagraphsWorkbenchItems
									title={t('VkoWorkbenchParagraphs')}
									halfHeight
								/>
							</Stack.Item>
						)}
					</Stack>
				)}
				<Stack grow={1} styles={stackItemStyles}>
					{!isVkoExternal && !isServiceProvider && (
						<Stack.Item grow={1}>
							<FavoritesList halfHeight />
						</Stack.Item>
					)}
					<Stack.Item grow={1}>
						<NewsItemsList halfHeight title={'News TR:ACE'} />
					</Stack.Item>
				</Stack>

				<Stack grow={1} styles={stackItemStyles}>
					<Stack.Item grow={1}>
						<ActivityList
							showOpenInNewWindow
							title={t('ActivityLog')}
							halfHeight
						/>
					</Stack.Item>
					<Stack.Item grow={1}>
						<ActivityList
							showOpenInNewWindow
							title={t('RecentActivity')}
							halfHeight
						/>
					</Stack.Item>
				</Stack>
			</Stack>
		</div>
	);
};

const dashboardClassNames = (theme: Theme) =>
	mergeStyleSets({
		header: {
			marginLeft: 20,
			marginTop: 20,
		},
		headerText: {
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		container: {
			overflow: 'auto',
			height: '100%',
		},
		text: {
			margin: 20,
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		activityContainer: {
			background: theme.palette.white,
			paddingTop: 10,
			margin: 10,
			borderRadius: 15,
		},
		workbench: {
			margin: 20,
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		keyword: {
			borderRadius: '10px',
			color: theme.palette.neutralSecondary,
			background: theme.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
		},
		keywordWrapper: {
			display: 'flex !important',
			flexWrap: 'wrap',
		},
	});

const stackItemStyles = {
	root: {
		width: '33%',
	},
};

export default DashboardPage;
