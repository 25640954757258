import {IStackTokens, Separator} from '@fluentui/react';
import {AutoStack} from 'components/AutoStack';
import {
	ControlledSpinButton,
	ControlledTagPicker,
	ControlledTextField,
	EnumDropdown,
} from 'components/hookForms';
import {NamingTemplateField} from 'components/hookForms/NamingTemplateField';
import React from 'react';
import {Control} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Market} from 'types';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	GetMarketFormDataQuery,
	useGetMarketFormDataQuery,
} from '../hooks/useGetMarketFormData.generated';

const tokens: IStackTokens = {childrenGap: 5};

export const MarketFormElements: React.FC<{control: Control<Market>}> = ({
	control,
}) => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminMarketsPage',
	});

	const {data, loading} = useGetMarketFormDataQuery({});
	const {countriesData} = React.useMemo(() => mapFormEdgeNodes(data), [data]);
	return (
		<LoadWrapper loading={loading}>
			<ControlledTagPicker
				control={control}
				name={'countries'}
				label={t('CountriesTagPickerLabel')}
				selectableItems={countriesData}
				getKey={item => item.id}
				getName={item => item.name}
			/>
			<Separator />
			<NamingTemplateField
				control={control}
				name={'regulationNamingTemplates'}
				label={t('RegulationNamingTemplatesLabel')}
			/>
			<Separator />
			<NamingTemplateField
				control={control}
				name={'regulatoryDocumentNamingTemplates'}
				label={t('RegulatoryDocumentNamingTemplatesLabel')}
			/>
			<Separator />
			<ControlledTextField
				control={control}
				name={'sortPriority'}
				label={t('sortPriority')}
				type='number'
			/>
		</LoadWrapper>
	);
};

const mapFormEdgeNodes = (data?: GetMarketFormDataQuery) => ({
	countriesData: data?.countries ?? [],
});
