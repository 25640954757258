import {PAGE_PATHS} from 'pages';
import {Requirement} from 'types';

export const createRequirementHref = (id: Requirement['id']): string => {
	return `${PAGE_PATHS.requirements}/${id}`;
};

export const createHrefFromRequirement = (requirement: {
	id: string;
}): string => {
	return createRequirementHref(requirement.id);
};
