import {TFunction} from 'i18next';
import {KeywordStatus} from 'types';

export class ParagraphsListHelper {
	static getKeywordAssignmentTooltipContent = (
		status: KeywordStatus,
		t: TFunction,
	) => {
		switch (status) {
			case KeywordStatus.New:
				return t('StatusNew');
			case KeywordStatus.Declined:
			case KeywordStatus.NoImpact:
				return t('StatusDeclined');
			case KeywordStatus.Accepted:
				return t('StatusAccepted');
			case KeywordStatus.Finalized:
				return t('StatusFinalized');
			case KeywordStatus.NoRequirements:
			case KeywordStatus.RequirementsDerived:
			default:
				return '';
		}
	};
}
