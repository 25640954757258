import {IContextualMenuItem} from '@fluentui/react';
import {
	CheckmarkRegular,
	DismissRegular,
	InfoRegular,
} from '@fluentui/react-icons';
import {KEYWORD_ASSIGNMENT_LINK} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.constants';
import {commandIconStyle} from 'hooks';
import React from 'react';
import './doubleIconCommandButton.css';

export const useDoubleIconCommandButton = () => {
	/** Commands */
	const onRenderAssignmentButton = (item: any, leftIcon: string) => {
		// To be extended with additional icons if needed
		const leftIconElement =
			leftIcon === 'check' ? (
				<CheckmarkRegular
					className='hoverable'
					aria-label='check'
					style={commandIconStyle}
				/>
			) : (
				<DismissRegular
					className='hoverable'
					aria-label='dismiss'
					style={commandIconStyle}
				/>
			);
		return (
			<div className='double-icon-container'>
				<div className='double-icon-left-container hoverable'>
					<div className='left-icon hoverable'>{leftIconElement}</div>
					<div className='button-text hoverable'>{item?.item?.text}</div>
				</div>
				<div className='double-icon-right-container hoverable'>
					<a
						className='hoverable'
						href={KEYWORD_ASSIGNMENT_LINK}
						target='_blank'
						rel='noreferrer'
						onClick={e => e.stopPropagation()}
					>
						<InfoRegular
							className='hoverable'
							aria-label='Info'
							style={{
								color: '#B12525',
							}}
						/>
					</a>
				</div>
			</div>
		);
	};

	const createDoubleIconCommandButton = (
		inputMenuItem: IContextualMenuItem,
		leftIcon: 'check' | 'dismiss',
	): IContextualMenuItem => {
		return {
			...inputMenuItem,
			className: 'double-icon-button-container',
			onRenderContent: (item: any) => onRenderAssignmentButton(item, leftIcon),
		};
	};

	return {
		createDoubleIconCommandButton,
	};
};
