import {
	DefaultButton,
	Dialog,
	IDialogProps,
	PrimaryButton,
	Stack,
} from '@fluentui/react';
import React from 'react';
import {useEditParagraphsFormTranslations} from './editParagraphsFormTranslationHooks';

export type ConfirmModifiedVersionCreationDialogProps = Omit<
	IDialogProps,
	'modalProps'
> & {
	handleSaveClick: () => void;

	setShowConfirmationDialog: (state: boolean) => void;
	showConfirmationDialog: boolean;
};

export const ConfirmModifiedVersionCreation: React.FC<
	ConfirmModifiedVersionCreationDialogProps
> = ({showConfirmationDialog, setShowConfirmationDialog, handleSaveClick}) => {
	const {t} = useEditParagraphsFormTranslations();
	return (
		<Dialog
			hidden={!showConfirmationDialog}
			modalProps={{
				isBlocking: true,
				isDarkOverlay: true,
				styles: {main: {maxWidth: 650}},
			}}
			onDismiss={() => setShowConfirmationDialog(false)}
		>
			{t('ModifiedVersionConfirmDialog')}
			<br />
			<Stack
				horizontal
				horizontalAlign='end'
				tokens={{childrenGap: 5, padding: '20px 0 0 0'}}
			>
				<Stack.Item>
					<PrimaryButton
						onClick={() => {
							setShowConfirmationDialog(false);
							handleSaveClick();
						}}
						ariaLabel={'ConfirmDialogButton'}
					>
						{t('Confirm')}
					</PrimaryButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton
						onClick={() => {
							setShowConfirmationDialog(false);
						}}
						ariaLabel={'CancelDialogButton'}
					>
						{t('Cancel')}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
