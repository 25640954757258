import {SelectionMode} from '@fluentui/react';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {
	ArrayItemWithName,
	FieldsForAttachmentColumn,
	renderArrayField,
	renderAttachments,
	renderFaqs,
	RenderFn,
} from 'components/EntityList/ColumnRenderers';
import {useSelection} from 'hooks';

import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Regulation, Maybe} from 'types';
import {RegulationsContextProps, useRegulationsContext} from '../../context';
import {RegulationsTooltipTranslationProvider} from '../RegulationsTooltipTranslationProvider';
import {getRenderRowWithContextualMenu} from '../../../../components/EntityList/RowWithContextualMenu/RowWithContextualMenu';
import {createRegulationHref} from '../regulationLinks.utils';
import {createHrefFromRegulation} from './regulationsListLinks.utils';
import {RegulationNumberCell} from './RegulationNumberCell';
import {renderKeywordsCell} from 'components/Keywords/Keywords';
import {KeywordFields} from 'components/Keywords/keywordItem.types';
import {parseTooltipNewlines} from 'helpers/tooltips';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {RegulationsCollectionName} from 'components/Faq/EntityTypeMapper';
import {useQuestionsData} from 'components/Faq/hooks/useQuestionsData';

export interface RegOfRegulationsList
	extends Pick<Regulation, 'id' | 'regulationNumber' | 'name'> {
	attachments?: Maybe<FieldsForAttachmentColumn[]>;
	markets: ArrayItemWithName[];
	mainKeywords: ArrayItemWithName[];
	keywords: KeywordFields[];
	standardPlusPcmsClusters: ArrayItemWithName[];
}

type RegulationsListProps = Omit<
	EntityListProps,
	'items' | 'columns' | 'onRenderRow' | 'selection'
> & {
	regulations: RegOfRegulationsList[];
	selectionMode?: SelectionMode;
};

export const RegulationsList: React.FC<RegulationsListProps> = ({
	regulations,
	selectionMode = SelectionMode.single,
	...props
}) => {
	const {t} = useTranslation('features/regulations', {
		keyPrefix: 'RegulationsList',
	});
	const {t: tt} = useTranslation('features/regulations', {
		keyPrefix: 'TooltipsText',
	});

	const regulationsData = useQuestionsData({
		data: regulations,
		entityType: RegulationsCollectionName,
	});

	const regulationsContext = useRegulationsContext();

	const getIfCanSetSelectedReg = (): boolean => {
		return Object.hasOwn(regulationsContext, 'setSelectedRegulation');
	};

	type RegDocInSelection = Partial<RegOfRegulationsList>;

	const setSelectedRegToSelectedItem = (
		selectedItems: RegDocInSelection[],
	): void => {
		const {setSelectedRegulation} =
			regulationsContext as RegulationsContextProps;
		setSelectedRegulation(selectedItems[0]);
	};

	const [selection] = useSelection<RegDocInSelection>({
		onSelectionChanged(selectedItems) {
			const canSetReg: boolean = getIfCanSetSelectedReg();
			if (canSetReg) setSelectedRegToSelectedItem(selectedItems);
		},
	});

	const navigate = useNavigate();

	const onItemInvoked = React.useCallback(
		(item: RegOfRegulationsList) => {
			const href: string = createRegulationHref(item.id);
			navigate(href);
		},
		[navigate],
	);

	const columns = React.useMemo((): EntityListColumn[] => {
		const baseColumns = [
			{
				name: 'attachments',
				key: 'attachments',
				isIconOnly: true,
				iconName: 'Attach',
				fieldName: 'attachments',
				minWidth: 16,
				maxWidth: 16,
				onRender: renderAttachments(),
			},
			{
				key: 'regulationNumber',
				name: t('RegulationNumber'),
				fieldName: 'regulationNumber',
				isMultiline: true,
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: RegulationNumberCell,
			},
			{
				key: 'name',
				name: t('Name'),
				fieldName: 'name',
				isMultiline: true,
				minWidth: 500,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'markets',
				name: t('Markets'),
				fieldName: 'markets',
				minWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'mainkeywords',
				name: t('MainKeywords'),
				fieldName: 'mainKeywords',
				isMultiline: true,
				minWidth: 400,
				isResizable: true,
				filterable: true,
				onRender: renderArrayField(),
				filterOnFilter: true,
			},
			{
				key: 'keywords',
				name: t('KeyWords'),
				fieldName: 'keywords',
				isMultiline: true,
				minWidth: 400,
				isResizable: true,
				filterable: true,
				onRender: renderKeywordsCell,
				filterOnFilter: true,
			},
			{
				key: 'standardPlusPcmsClusters',
				name: t('StandardPlusPcmsClusters'),
				fieldName: 'standardPlusPcmsClusters',
				isMultiline: true,
				minWidth: 400,
				isResizable: true,
				filterable: true,
				onRender: renderArrayField(),
				tooltipHostProps: {
					content: parseTooltipNewlines(tt('standardPlusPcmsClusters')),
				},
			},
		];
		if (isDevEnvironment() || isTestEnvironment())
			return baseColumns.concat({
				key: 'faq',
				name: 'FAQs',
				fieldName: 'faq',
				isMultiline: true,
				minWidth: 400,
				isResizable: true,
				filterable: true,
				onRender: renderFaqs(),
			});
		return baseColumns;
	}, [t]);
	return (
		<RegulationsTooltipTranslationProvider>
			<EntityContextProvider>
				<EntityList
					{...props}
					items={regulationsData || []}
					columns={columns}
					aria-rowcount
					selectionMode={selectionMode}
					selection={selection}
					onItemInvoked={onItemInvoked}
					onRenderRow={getRenderRowWithContextualMenu(createHrefFromRegulation)}
				/>
			</EntityContextProvider>
		</RegulationsTooltipTranslationProvider>
	);
};
