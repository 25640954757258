import {RegulatoryDocument, Requirement, VexCluster} from 'types';
import {TFunction} from 'react-i18next';
import {DocumentSourceEnum} from 'features/Regulations/RegulationDetail/RegulationDetail.types';
import {IArrayRoles} from './ExcelExportCommon';

interface ILocationParams {
	regulationId: string | undefined;
	regulatoryDocumentId: string | undefined;
	paragraphId: string | undefined;
	requirementId: string | undefined;
	modelSeries: string | undefined;
	generation: string | undefined;
	baselineId: string | undefined;
	id: string | undefined;
}

interface IExceButtonDisableProps {
	(
		intNumRecords: number,
		arrRoles: IArrayRoles,
		myVexClusters: VexCluster[],
		strLocationType: string,
		requirement: Requirement | undefined,
	): boolean;
}

interface IExceButtonHideProps {
	(entityListItems: any[]): boolean;
}

interface IExcelButtonLocation {
	(pathname: string, params: ILocationParams): string;
}

interface ITooltipXL {
	(strLocationType: string, t: TFunction): string;
}

export const excelButtonLocation: IExcelButtonLocation = (pathname, params) => {
	const strLocationTrue = location.pathname
		.split('/')
		.filter(x => x !== '')
		.join('/');

	if (strLocationTrue === 'regulations') return 'regulations_page';

	if (strLocationTrue === 'regulations/' + params.regulationId)
		return 'regulation_detail_page';

	if (
		strLocationTrue ===
			'regulations/' +
				params.regulationId +
				'/' +
				params.regulatoryDocumentId +
				'/paragraphs' ||
		strLocationTrue === 'regulatoryDocuments/' + params.regulatoryDocumentId
	)
		return 'regulation_version_detail_page';

	if (strLocationTrue === 'requirements') return 'requirements_page';

	if (strLocationTrue === 'requirements/' + params.requirementId)
		return 'requirements_detail_page';

	if (strLocationTrue === 'vehicleProjects') return 'vehicle_projects_page';

	if (
		strLocationTrue ===
		'vehicleProjects/' +
			encodeURIComponent(params.modelSeries ?? '') +
			'/' +
			encodeURIComponent(params.generation ?? '')
	)
		return 'vehicle_project_detail_page';

	if (
		strLocationTrue ===
		'vehicleProjects/' +
			encodeURIComponent(params.modelSeries ?? '') +
			'/' +
			encodeURIComponent(params.generation ?? '') +
			'/baselines'
	)
		return 'baselines_page';

	if (
		strLocationTrue ===
		'vehicleProjects/' +
			encodeURIComponent(params.modelSeries ?? '') +
			'/' +
			encodeURIComponent(params.generation ?? '') +
			'/baselines/' +
			params.id
	)
		return 'baseline_detail_page';

	if (strLocationTrue === 'simulations') return 'simulations_page';

	if (strLocationTrue === 'simulations/' + params.id)
		return 'simulation_detail_page';

	if (strLocationTrue === 'reports/regulatory-documents')
		return 'report_reg_doc_page';

	if (strLocationTrue === 'pogisArchive') return 'pogis_archiv_page';

	if (strLocationTrue === 'VkoVexList') return 'vko_vex_page';

	if (pathname.startsWith('/admin')) {
		return 'admin_page';
	}

	return '';
};

export const excelButtonDisable: IExceButtonDisableProps = (
	intNumRecords,
	arrRoles,
	myVexClusters,
	strLocationType,
	requirement,
) => {
	if (
		[
			'regulations_page',
			'regulation_detail_page',
			'regulation_version_detail_page',
		].includes(strLocationType)
	) {
		return !(
			arrRoles.isAdmin ||
			arrRoles.isVko ||
			arrRoles.isVex ||
			arrRoles.isReader
		);
	}

	if (strLocationType === 'requirements_detail_page') {
		if (arrRoles.isAdmin) {
			return false;
		}

		if (arrRoles.isVex) {
			if (requirement && requirement.vexClusters && myVexClusters) {
				return !requirement.vexClusters.some(vc =>
					myVexClusters.some(mvc => mvc.id === vc.id),
				);
			}
			return true;
		}
	}

	if (
		[
			'requirements_page',
			'vehicle_projects_page',
			'vehicle_project_detail_page',
			'simulation_detail_page',
			'baselines_page',
			'baseline_detail_page',
			'simulations_page',
		].includes(strLocationType)
	) {
		return !(arrRoles.isAdmin || arrRoles.isVex);
	}

	if (
		['pogis_archiv_page', 'vko_vex_page', 'report_reg_doc_page'].includes(
			strLocationType,
		)
	) {
		return !(arrRoles.isAdmin || arrRoles.isVko || arrRoles.isVex);
	}

	if (strLocationType === 'admin_page') {
		return !arrRoles.isAdmin || intNumRecords === 0;
	}

	return true;
};

export const excelButtonHide: IExceButtonHideProps = entityListItems => {
	if (
		entityListItems.length > 0 &&
		entityListItems[0].__typename === 'RegulatoryDocument'
	) {
		const regDoc = entityListItems[0] as RegulatoryDocument;
		return (
			regDoc.documentSource?.name?.toLowerCase() ===
			DocumentSourceEnum.Pogis.toLowerCase()
		);
	}

	return false;
};

export const tooltipXL: ITooltipXL = (strLocationType, t) => {
	const strTrNS = 'components/EntityList/ExcelExport';
	const strTrPrefix = 'TooltipsText';
	const tOptions = {
		ns: strTrNS,
		keyPrefix: strTrPrefix,
	};
	switch (strLocationType) {
		case 'regulations_page':
			return t('Regulations', tOptions);

		case 'regulation_detail_page':
			return t('RegulationDetail', tOptions);

		case 'regulation_version_detail_page':
			return t('RegulationVersionDetail', tOptions);

		case 'requirements_page':
			return t('Requirements', tOptions);

		case 'requirements_detail_page':
			return t('RequirementDetail', tOptions);

		case 'vehicle_projects_page':
			return t('VehicleProjects', tOptions);

		case 'vehicle_project_detail_page':
			return t('VehicleProjectDetail', tOptions);

		case 'baselines_page':
			return t('Baselines', tOptions);

		case 'baseline_detail_page':
			return t('BaselineDetail', tOptions);

		case 'simulations_page':
			return t('Simulations', tOptions);

		case 'simulation_detail_page':
			return t('SimulationDetail', tOptions);

		case 'report_reg_doc_page':
			return t('VoPro', tOptions);

		case 'pogis_archiv_page':
			return t('POGIS', tOptions);

		case 'vko_vex_page':
			return t('VkoVex', tOptions);

		case 'admin_page':
			return t('Admin', tOptions);

		default:
			return 'Excel Export';
	}
};
